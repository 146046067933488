import React from 'react'
import { Button, Card, Image, Popconfirm, Space, Table, notification, Form, Input } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { MODULE_TITLES } from '../../../../config/moduleConfig'
import BreadcrumbComponent from '../../../common/Breadcrumb'
import { useBrandSectionPaginationListLazyQuery, useDeleteBrandSectionMutation } from '../../../../graphql/generated/index.tsx';
import { useParams } from 'react-router'
import { LOCATIONS } from '../../../../config/routeConfig'
import { default_s } from '../../../../helper/importHelper/image'
import { PAGINATION, PERMISSION_SLUG } from '../../../../config/constants';
import { UNIQUE_MESSAGES } from '../../../../helper/MessagesHelper';
import { Link } from 'react-router-dom';
import { onImageLoadError } from '../../../../helper/imageHelper';
import { getTableRowKey } from '../../../../helper/GeneralHelper';
import { ConditionalComponent } from "../../../common/GeneralComponents";
import { itemRender } from '../../../../helper/Paginationfunction';
import { usePagination } from '../../../../context/PaginationContext';


const BrandSelection = (props) => {
    const { search, pageValues, handlePageNoChange, handlePageSizeChange, handleSearchChange
    } = usePagination();
    const params = useParams()
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [BrandListByProduct, setBrandListByProduct] = React.useState([]);
    const [deleteBrandSectionMutation, { loading, refetch }] = useDeleteBrandSectionMutation()

    const breadcrumb_items = [
        { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
        { label: MODULE_TITLES.BRAND_SECTIONS.ROOT, to: LOCATIONS.BRAND_SECTIONS_ROUTES.ROOT },
        { label: MODULE_TITLES.BRAND_SECTIONS.LIST, }
    ];

    const [getBrandSectionPaginationListLazyQuery, { loading: brandSectionPaginationListoading }] = useBrandSectionPaginationListLazyQuery()
    const getBrandSelectionList = React.useCallback((searchArg, currentPageArg, limitArg) => {
        let variables = {
            categoryId: params?.categoryId
        };
        searchArg && (variables.search = searchArg);
        currentPageArg && (variables.page = currentPageArg - 1);
        limitArg && (variables.limit = limitArg)

        getBrandSectionPaginationListLazyQuery({
            variables: variables,
            onCompleted(data) {
                setBrandListByProduct(data?.brandSectionPaginationList?.data)
                setTotalRecords(data?.brandListByProductCategory?.totalRecords)
            },
            onError(error) {
                notification.error({
                    message: error.message,
                });
            },
            fetchPolicy: "network-only"
        })
    }, [setBrandListByProduct, setTotalRecords, getBrandSectionPaginationListLazyQuery]);

    React.useEffect(() => {
        getBrandSelectionList(search, pageValues.currentPage, pageValues.pageSize);
    }, [pageValues, deleteBrandSectionMutation]);


    const deleteBrandSelection = React.useCallback((id) => () => {
        deleteBrandSectionMutation(
            {
                variables: { id: id },
                onCompleted(data) {
                    notification.success({
                        message: data.deleteBrandSection.message,
                    });
                },
                refetchQueries(result) {
                    if (result.data.deleteBrandSection.code) {
                        return ['BrandSectionPaginationList']
                    }
                },
                onError(error) {
                    notification.error({
                        message: error.message,
                    });
                },
            }
        )
    }, [deleteBrandSectionMutation, refetch])


    const tableColumns = [
        {
            title: "Brand Name",
            dataIndex: "brandName",
        },
        {
            title: "Brand Image",
            dataIndex: "brandImage",
            render: (_text, record) => {
                return <Image alt=" " onError={onImageLoadError} src={record?.brandImage ? record?.brandImage : default_s} style={{ maxHeight: 100, maxWidth: 100 }} />
            }
        },
        {
            title: "Actions",
            dataIndex: "actions",
            render: (_text, record) => {
                return (
                    <Space size={10}>
                        <ConditionalComponent shouldShow={props?.permission?.[PERMISSION_SLUG.DELETE]}>
                            <Popconfirm
                                placement="bottomRight"
                                title={UNIQUE_MESSAGES.DELETE_POPUP("Brand")}
                                onConfirm={deleteBrandSelection(record._id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="link" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </ConditionalComponent>
                    </Space>
                )
            }
        },
    ];
    const tableData = BrandListByProduct;
    return (
        <Content className='content'>
            <div className='page-header'>
                <h2 className='page-title'>{MODULE_TITLES.BRAND_SECTIONS.ROOT}</h2>
                <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
            </div>
            <div className='content-wrapper'>
                <Card title={MODULE_TITLES.BRAND_SECTIONS.LIST} extra={
                    <ConditionalComponent shouldShow={props.permission[PERMISSION_SLUG.ADD]}>
                        <Space>
                            <Link
                                to={LOCATIONS.BRAND_SECTIONS_ROUTES.ADD.replace(':categoryId', params.categoryId)}
                            ><Button
                            >Add Brand</Button></Link>
                        </Space>
                    </ConditionalComponent>
                } >
                    <Form name="horizontal_login" className="justify-content-end mb-20" layout="inline" labelWrap={true}>

                        <Form.Item name="search">
                            <Input
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                type="text"
                                placeholder="Search"
                                allowClear={true}
                                onChange={(e) => handleSearchChange(e.target.value)}
                            />
                        </Form.Item>
                    </Form>
                    <Table
                        className="table-striped"
                        rowClassName={"custom-row-brand-list"}
                        style={{ overflow: "auto" }}
                        columns={tableColumns}
                        dataSource={tableData}
                        ascend={true}
                        rowKey={getTableRowKey}
                        showSizeChanger={true}
                        loading={brandSectionPaginationListoading || loading}
                        pagination={{
                            total: totalRecords,
                            pageSize: pageValues.pageSize,
                            current: pageValues.currentPage,
                            showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            onChange: handlePageNoChange,
                            showSizeChanger: true,
                            pageSizeOptions: PAGINATION.DEFAULT_SIZE_OPTIONS,
                            onShowSizeChange: handlePageSizeChange,
                            itemRender: itemRender,
                        }}
                    />
                </Card>
            </div>
        </Content>
    )
}

// function DocumentPreview({ record }) {
//     console.log("🚀 ~ DocumentPreview ~ record:", record)

//     const isPDF = record?.brandDocumentType === KYC_DOCUMENT_TYPES.PDF;
//     if (!isPDF) {
//         return (
//             <Image
//                 onError={onImageLoadError}
//                 src={record?.brandDocument ? record?.brandDocument : default_s}
//                 style={{ maxHeight: 100, maxWidth: 100 }}
//             />
//         );
//     } else {
//         return <Button type="default" onClick={() => window.open(record?.brandDocument, "_blank")} >View Document</Button>
//     }
// }

export default BrandSelection