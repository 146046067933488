import React from "react";
import * as Yup from "yup";
import { Content } from "antd/lib/layout/layout";
import { Card, Col, Row, notification, Upload, Alert,Modal } from "antd";
import { Form, Input, SubmitButton, Select } from "formik-antd";
import { FormikProvider, useFormik } from "formik";
import _ from "lodash";
import {
  commonValidationMessage,
  UNIQUE_MESSAGES,
} from "../../../../helper/MessagesHelper";
import { useNavigate, useParams } from "react-router";
import {
  useSubCategoryListAdvertisementQuery,
  useAdvertisementSellerListQuery,
  useAdvertisementBrandListQuery,
  useAddUpdateUserPromotedAdvMutation,
} from "../../../../graphql/generated/index.tsx";
import {
  ADVERTISEMENT_TYPES,
  BANNER_TYPES_FOR_ADMIN,
  IMAGE_TYPE,
  UPLOAD_PATH,
} from "../../../../config/constants";
import { LOCATIONS } from "../../../../config/routeConfig";
import BreadcrumbComponent from "../../../common/Breadcrumb";
import { MODULE_TITLES } from "../../../../config/moduleConfig";
import { PlusOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import { AWSHelper } from "../../../../helper/S3helper";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ConditionalComponent } from "../../../common/GeneralComponents";
import { DIMENSIONS_LIST, ImageNote, getImageNameFromObject, getImageObject, validateImageDimensions } from "../../../../helper/imageHelper";
import ImageCrop from "../../../common/ImageCrop/ImageCrop";
import { imageFileResizer } from "../../../../helper/GeneralHelper";
import moment from "moment"

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const dateFormatList = ['DD/MM/YYYY'];
const disabledDate = (current) => {
  return current && current < dayjs().endOf("day") ;
};

const adminBannerImageValidationSchema = Yup.object().shape({
  bannerImageWeb: Yup.array()
    .min(1, "Web Banner Image is Must")
    .required(commonValidationMessage("Banner Image For Web")),
  bannerImageMobile: Yup.array()
    .min(1, "Mobile Banner Image is Must")
    .required(commonValidationMessage("Banner Image For Mobile")),

  bannerTitle: Yup.string().required(commonValidationMessage("Banner Title")),
  bannerDescription: Yup.string().required(commonValidationMessage("Description")),
  bannerType: Yup.string().oneOf(Object.values(BANNER_TYPES_FOR_ADMIN)).required(commonValidationMessage("Banner Type")),
  sellerId: Yup.string().when("bannerType", (bannerType) => {
    if (bannerType === BANNER_TYPES_FOR_ADMIN.SELLER_STORE) {
      return Yup.string().required(commonValidationMessage("Seller"));
    }
    return Yup.string().optional().nullable();
  }),
  brandId: Yup.string().when("bannerType", (bannerType) => {
    if (bannerType === BANNER_TYPES_FOR_ADMIN.BRAND) {
      return Yup.string().required(commonValidationMessage("Brand"));
    }
    return Yup.string().optional().nullable();
  }),
  subCategoryIds: Yup.array().when("bannerType", (bannerType) => {
    if (bannerType === BANNER_TYPES_FOR_ADMIN.PRODUCTS) {
      return Yup.array()
        .min(1, "Select atleast 1 Product")
        .required(commonValidationMessage("Product"));
    }
    return Yup.array().optional().nullable();
  }),
  // date: Yup.array()
  // .of(
  //   Yup.date()
  //     .transform((value, originalValue) => {
  //       const date = dayjs(originalValue, 'DD/MM/YYYY', true);
  //       return date.isValid() ? date.toDate() : null;
  //     })
  //     .nullable()
  // )
  // .test('date', 'Please select a date range', (value) => {
  //   return value && value.length === 2 && value[0] && value[1];
  // })
  // .required('Date is required'),
  date: Yup.array().min(2, 'Please select start and end date').required(commonValidationMessage("Date")),

});

const BANNER_TYPES_OPTIONS = [
  { label: "Brand", value: BANNER_TYPES_FOR_ADMIN.BRAND },
  { label: "Products", value: BANNER_TYPES_FOR_ADMIN.PRODUCTS },
  { label: "Seller Store", value: BANNER_TYPES_FOR_ADMIN.SELLER_STORE },
];

const IMAGE_RATIO_FOR_WEB = 27 / 9
const IMAGE_RATIO_FOR_MOBILE = 16 / 9;


const AddBannerByAdmin = (props) => {
  const navigate = useNavigate();
  // const { operationType } = props;
  // const isEditOperation = operationType === OPERATIONS.EDIT;
  const params = useParams();
  const advertisementType = props.advertisementType ?? ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT;
  const [numberOfDays] = React.useState();

  const [isModalVisible, setModalVisiblity] = React.useState({web: false, mobile: false});

  const [croppedImage, setCropImage] = React.useState();
  const [croppedImageForMobile,setCroppedImageForMobile] = React.useState()

  const [isWebCroppedImageValid, setIsWebCroppedImageValid] = React.useState(false);
  const [isMobileCroppedImageValid, setIsMobileCroppedImageValid] = React.useState(false);

  const [rawAdvertisementImageWeb, setRawAdvertisementImageWeb] = React.useState();
  const [rawAdvertisementImageForMobile, setRawAdvertisementImageForMobile] = React.useState();




  /*GraphQl  */
  const {
    data: SellerListQueryData,
    loading: SellerListQueryLoading,
    error: SellerListQueryError,
  } = useAdvertisementSellerListQuery();

  const {
    data: SubCategoryListQueryData,
    loading: SubCategoryListQueryLoading,
    error: SubCategoryListQueryError,
  } = useSubCategoryListAdvertisementQuery({
    variables: {
      categoryId: params.categoryId,
    },
    onError(error) {
      notification.error({ message: error.message });
      
    },
  });

  const {
    data: AdvertisementBrandListData,
    loading: AdvertisementBrandListLoading,
    error: AdvertisementBrandListError,
  } = useAdvertisementBrandListQuery();

  const [addUpdateUserPromotedAdvMutation] = useAddUpdateUserPromotedAdvMutation();

  const breadcrumb_items = [
    { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
    {
      label: MODULE_TITLES.PROMOTE_ADVERTISEMENT.ROOT,
      to: LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.ROOT,
    },
    {
      label: MODULE_TITLES.PROMOTE_ADVERTISEMENT.LIST,
      to: LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.LIST[advertisementType].replace(
        ":categoryId",
        params?.categoryId
      ),
    },
    {
      label: MODULE_TITLES.PROMOTE_ADVERTISEMENT.VIEW,
      to: LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.VIEW[advertisementType].replace(
        ":categoryId",
        params?.categoryId
      ).replace(":promoteaddId", params?.promoteaddId),
    },
    { label: "Add Banner By Admin" },
  ];
  const formik = useFormik({
    initialValues: {
      categoryId: params.categoryId,
      sectionTypeId: params.promoteaddId,
      addedBy: "ADMIN",
      advertisementType: "BANNER_ADVERTISEMENT",// NOTE: DEFAULT MIGHT CHANGE IN FUTURE
      perDayPrice: 1,
      totalPayableAmount: 1,
      bannerImageWeb: [],
      bannerImageMobile:[],
      // bannerTitle: "",
      bannerDescription: "",
      date: undefined,
      bannerType: "",
      subCategoryIds: [],
      sellerId: "",
      brandId: "",
      totalDays: 0,
    },
    validationSchema: adminBannerImageValidationSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);
      let variables = {
        ...values,
      };
      switch (variables.bannerType) {
        case BANNER_TYPES_FOR_ADMIN.BRAND: {
          delete variables.sellerId;
          delete variables.subCategoryIds;
          break;
        }
        case BANNER_TYPES_FOR_ADMIN.PRODUCTS: {
          delete variables.sellerId;
          delete variables.brandId;
          break;
        }
        default: {
          delete variables.brandId;
          delete variables.subCategoryIds;
        }
      }

      const webImageList = [{image: getImageObject(values.bannerImageWeb), name: getImageNameFromObject(values.bannerImageWeb), path: UPLOAD_PATH.PROMOTE_ADVERTISEMENT_WEB, variableKey : 'bannerImageWeb'}]
      const mobileImageList =  [{image: getImageObject(values.bannerImageMobile), name: getImageNameFromObject(values.bannerImageMobile), path: UPLOAD_PATH.PROMOTE_ADVERTISEMENT_MOBILE, variableKey : 'bannerImageMobile'}]
    
    const imageList = webImageList.concat(mobileImageList)
      if (imageList.length > 0) {
      try {
          for (let item = 0; item < imageList.length; item++) {
            const imageItem = imageList[item];            
            if(imageItem.image){
              await AWSHelper.upload(
                imageItem.image,
                imageItem.name,
                imageItem.path
              );
            }
            variables[imageItem.variableKey] = imageItem.name;
          }
        } catch (error) {
          actions.setSubmitting(false);
          return notification.error({ message: error.message });
        }
      }      //  No. of days Caluculated onSubmitt

      const startDateUTC=moment.utc(values.date[0],"DD/MM/YYYY").unix()*1000
      const endDateUTC = moment.utc(values.date[1],"DD/MM/YYYY").unix()*1000;
      const daysBetween = moment.duration(endDateUTC - startDateUTC).asDays();
      const exactDaysBetween = daysBetween + 1;
      delete variables.date; 
      variables.startDate = startDateUTC.valueOf();
      variables.endDate = endDateUTC.valueOf();
      variables.totalDays = exactDaysBetween;
      addUpdateUserPromotedAdvMutation({
        variables: {
          input: {
            ...variables,
          },
        },
        onCompleted(data) {
          notification.success({ message: data.addUpdateUserPromotedAdv.message });
          actions.setSubmitting(false);
          navigate(
            LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.VIEW[advertisementType].replace(
              ":categoryId",
              params.categoryId
            ).replace(":promoteaddId", params.promoteaddId)
          );
        },
        onError(error) {
          notification.error({ message: error.message });
          actions.setSubmitting(false);
        },
      });
    },
  });

  const handleFileChange = (forWeb) =>  async ({ fileList: newFileList }) => {
    let currentFile = newFileList[0];
    let fileType = currentFile.type.split("/").pop();
    if (
      !(
        fileType === IMAGE_TYPE.JPEG ||
        fileType === IMAGE_TYPE.JPEG_2 ||
        fileType === IMAGE_TYPE.JPG_2 ||
        fileType === IMAGE_TYPE.JPG ||
        fileType === IMAGE_TYPE.PNG ||
        fileType === IMAGE_TYPE.PNG_2
      )
    ) {
      return notification.error({
        message: UNIQUE_MESSAGES.IMAGE.ERROR.FILE_TYPE,
      });
    }
    if (currentFile.size >= 1024 * 1024 * 5) {
      return notification.error({
        message: UNIQUE_MESSAGES.IMAGE.ERROR.SIZE("5 MB"),
      });
    }
    // verify image dimensions
    await validateImageDimensions(currentFile, (imageHeight, imageWidth) => {
      //   if(forWeb){
      //   const { MIN_HEIGHT, MIN_WIDTH } = DIMENSIONS_LIST.BANNER_FOR_WEB;

      //   if(imageHeight < MIN_HEIGHT   || imageWidth < MIN_HEIGHT ){
      //     return notification.error({ message: `Width and height should be atleast ${MIN_WIDTH}x${MIN_HEIGHT} but found ${imageWidth}x${imageHeight}.` })
      //   }
      //   if(MIN_HEIGHT === imageHeight && MIN_HEIGHT === imageWidth){
      //       formik.setFieldValue('bannerImageWeb', newFileList )
      //       return
      //   }

      //   setRawAdvertisementImageWeb(URL.createObjectURL(currentFile.originFileObj))
      //   setModalVisiblity(() => ({mobile: false, web:true}))
      // }else{
      //   const { MIN_HEIGHT, MIN_WIDTH } = DIMENSIONS_LIST.BANNER_FOR_MOBILE;

      //   if(imageHeight < MIN_HEIGHT   || imageWidth < MIN_HEIGHT ){
      //     return notification.error({ message: `Width and height should be atleast ${MIN_WIDTH}x${MIN_HEIGHT} but found ${imageWidth}x${imageHeight}.` })
      //   }
      //   if(MIN_HEIGHT === imageHeight && MIN_HEIGHT === imageWidth){
      //       formik.setFieldValue('bannerImageWeb', newFileList )
      //       return
      //   }

      //   setRawAdvertisementImageForMobile(URL.createObjectURL(currentFile.originFileObj))
      //   setModalVisiblity(() => ({mobile: true, web: false}))
      // }
      // Inside the validateImageDimensions function callback
      if (forWeb) {
        const { MIN_HEIGHT, MIN_WIDTH } = DIMENSIONS_LIST.BANNER_FOR_WEB;
        if (imageHeight === MIN_HEIGHT && imageWidth === MIN_WIDTH) {
          formik.setFieldValue("bannerImageWeb", newFileList);
          return; // Skip showing the cropper
        } else if (imageHeight < MIN_HEIGHT || imageWidth < MIN_WIDTH) {
          return notification.error({
            message: `Width and height should be at least ${MIN_WIDTH}x${MIN_HEIGHT} but found ${imageWidth}x${imageHeight}.`,
          });
        }

        // Show the cropper for non-matching dimensions
        setRawAdvertisementImageWeb(
          URL.createObjectURL(currentFile.originFileObj)
        );
        setModalVisiblity(() => ({ mobile: false, web: true }));
      } else {
        const { MIN_HEIGHT, MIN_WIDTH } = DIMENSIONS_LIST.BANNER_FOR_MOBILE;

        if (imageHeight === MIN_HEIGHT && imageWidth === MIN_WIDTH) {
          formik.setFieldValue("bannerImageMobile", newFileList);
          return; // Skip showing the cropper
        } else if (imageHeight < MIN_HEIGHT || imageWidth < MIN_WIDTH) {
          return notification.error({
            message: `Width and height should be at least ${MIN_WIDTH}x${MIN_HEIGHT} but found ${imageWidth}x${imageHeight}.`,
          });
        }

        // Show the cropper for non-matching dimensions
        setRawAdvertisementImageForMobile(
          URL.createObjectURL(currentFile.originFileObj)
        );
        setModalVisiblity(() => ({ mobile: true, web: false }));
      }
    });
  }

  /**image Cropping  */
  async function handleValidateAfterCrop(blobImage) {
    const result = await validateImageDimensions(
      { originFileObj: blobImage },
      DIMENSIONS_LIST.BANNER_FOR_WEB.validator,     
    );
    if (!result.isValid) {
      setIsWebCroppedImageValid(false);
      setCropImage(null);
      notification.error({ message: result.message });
      return;
    }
    setIsWebCroppedImageValid(true);
    blobImage.name = 'croppedImage';
    imageFileResizer(blobImage, DIMENSIONS_LIST.BANNER_FOR_WEB.MIN_WIDTH, DIMENSIONS_LIST.BANNER_FOR_WEB.MIN_HEIGHT).then(res =>{
      setCropImage(res);
    })
    
  }


  async function handleValidateAfterCropForMobile(blobImage) {
    const result = await validateImageDimensions(
      { originFileObj: blobImage },
      DIMENSIONS_LIST.BANNER_FOR_MOBILE.validator,
    );
    if (!result.isValid) {
      setIsMobileCroppedImageValid(false);
      setCroppedImageForMobile(null)
      notification.error({ message: result.message });
      return;
    }
    setIsMobileCroppedImageValid(true);
    blobImage.name = 'croppedImageForMobile';
    imageFileResizer(blobImage, DIMENSIONS_LIST.BANNER_FOR_MOBILE.MIN_WIDTH, DIMENSIONS_LIST.BANNER_FOR_MOBILE.MIN_HEIGHT).then(res =>{
      setCroppedImageForMobile(res)
    })
    
  }
  
  function handleCropComplete() {
    if (!croppedImage ) return;
    let file = new File([croppedImage], 'bannerImageForWeb', { type: croppedImage.type })

    formik.setFieldValue("bannerImageWeb", [{ ...file, originFileObj: file }]);
    // setBannerImageForWeb([{ ...file, originFileObj: file }]);
    setIsWebCroppedImageValid(false);
    setModalVisiblity(() => ({mobile: false, web: false}));
  }

  function handleCropCompleteForMobile() {
    if (!croppedImageForMobile) return;
    let file = new File([croppedImageForMobile], 'bannerImageForMobile', { type: croppedImageForMobile.type })
    // setBannerImageForMobile([{ ...file, originFileObj: file }]);
    formik.setFieldValue("bannerImageMobile", [{ ...file, originFileObj: file }]);
    setIsMobileCroppedImageValid(false);
    setModalVisiblity(() => ({mobile: false, web: false}));
  }

  function handleCancelCrop() {
    if(isModalVisible.mobile){
      setRawAdvertisementImageWeb(null);
    }else{
      setRawAdvertisementImageForMobile(null)
    }
    setModalVisiblity(() => ({mobile: false, web: false}));
  }
  return (
    <Content className="content">
      <div className="page-header">
        <h2 className="page-title"> {"Banner By Admin"} </h2>
        <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
      </div>
      <div className="content-wrapper">
        <Card title="Banner By Admin">
          <FormikProvider value={formik}>
            <Form
              labelAlign="left"
              labelWrap={true}
              name="add-edit-category-page-form"
              layout="ver"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 10 }}
            >
              <Form.Item
                label="Banner Image For Web"
                id="bannerImageWeb"
                name="bannerImageWeb"
                required
              >
                <Upload
                  accept=".png, .jpg, .jpeg"
                  id="bannerImageWeb"
                  name="bannerImageWeb"
                  action="/upload.do"
                  fileList={formik.values.bannerImageWeb}
                  // fileList={bannerImageForWeb}
                  listType="picture-card"
                  maxCount={1}
                  beforeUpload={() => false}
                  onChange={handleFileChange(true)}
                  onPreview={() => null}
                  onRemove={() => false}
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>
                      {MODULE_TITLES.COMMON.UPLOAD}
                    </div>
                  </div>
                </Upload>
                <ImageNote
                  width={DIMENSIONS_LIST.BANNER_FOR_WEB.MIN_WIDTH}
                  height={DIMENSIONS_LIST.BANNER_FOR_WEB.MIN_HEIGHT}
                  fixedDimension={true}
/>
              </Form.Item>

              <Form.Item
                label="Banner Image For Mobile"
                id="bannerImageMobile"
                name="bannerImageMobile"
                required
              >
                <Upload
                  accept=".png, .jpg, .jpeg"
                  id="bannerImageMobile"
                  name="bannerImageMobile"
                  action="/upload.do"
                  fileList={formik.values.bannerImageMobile}
                  // fileList={bannerImageForMobile}
                  listType="picture-card"
                  maxCount={1}
                  beforeUpload={() => false}
                  onChange={handleFileChange(false)}
                  onPreview={() => null}
                  onRemove={() => false}
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>
                      {MODULE_TITLES.COMMON.UPLOAD}
                    </div>
                  </div>
                </Upload>
                <ImageNote
                  width={DIMENSIONS_LIST.BANNER_FOR_MOBILE.MIN_WIDTH}
                  height={DIMENSIONS_LIST.BANNER_FOR_MOBILE.MIN_HEIGHT}
                  fixedDimension={true}
                />
              </Form.Item>

              <Form.Item label="Banner Title" name="bannerTitle" required>
                <Input
                  type="text"
                  id="bannerTitle"
                  name="bannerTitle"
                  placeholder="Banner Title"
                  suffix={<span />}
                />
              </Form.Item>
              <Form.Item label="Description" name="bannerDescription" required>
                <Input
                  type="text"
                  id="bannerDescription"
                  name="bannerDescription"
                  placeholder="Description"
                  suffix={<span />}
                />
              </Form.Item>
              <Form.Item label="Start-End Date" name="date" id="date" required>
                <RangePicker
                  onChange={(data, dateString) => {
                    formik.setFieldValue("date", dateString);
                  }}
                  name="date"
                  onBlur={() => formik.handleBlur({target:{name: 'date'},currentTarget:{name:'date'}})}
                  disabledDate={disabledDate}
                  format={dateFormatList}
                  inputReadOnly
                  clearIcon={false}
                />

                <Row>
                  <Col span={24}>
                    {numberOfDays && (
                      <Alert
                        message={`Advertisment Banner  will be available for : ${numberOfDays} days.`}
                        type="warning"
                        className="mt-10"
                      />
                    )}
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="Banner Type" name="bannerType" required>
                <Select
                  placeholder="Banner Type"
                  name="bannerType"
                  options={BANNER_TYPES_OPTIONS}
                  style={{ minWidth: "300px" }}
                  allowClear={true}
                />
              </Form.Item>
              <ConditionalComponent
                shouldShow={
                  ![null, undefined].includes(formik.values.bannerType)
                }
              >
                <ConditionalComponent
                  shouldShow={
                    formik.values.bannerType ===
                    BANNER_TYPES_FOR_ADMIN.SELLER_STORE
                  }
                >
                  <Form.Item label="Select seller" name="sellerId" required>
                    <Select
                      placeholder="Seller"
                      name="sellerId"
                      showSearch
                      filterOption={(input, option) => {
                        if (option.label && option.label.props) {
                          const { children, title } = option.label.props;
                          if (children && title) {
                            return (
                              option.label.props.title
                                .toLowerCase()
                                .includes(input.toLowerCase()) ||
                              option.label.props.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            );
                          }
                        }
                        return false;
                      }}
                      loading={SellerListQueryLoading}
                      options={
                        SellerListQueryData &&
                        SellerListQueryData.advertisementSellerList.map(
                          (item) => ({
                            label: (
                              <span title={item.email}>
                                {item.businessName}
                              </span>
                            ),
                            value: item._id,
                          })
                        )
                      }
                      id="sellerId"
                      style={{ minWidth: "300px" }}
                    />
                  </Form.Item>
                </ConditionalComponent>

                <ConditionalComponent
                  shouldShow={
                    formik.values.bannerType === BANNER_TYPES_FOR_ADMIN.PRODUCTS
                  }
                >
                  <Form.Item
                    label="Select Category"
                    name="subCategoryIds"
                    required
                  >
                    <Select
                      placeholder="Select Category"
                      name="subCategoryIds"
                      mode="multiple"
                      allowClear={true}
                      loading={SubCategoryListQueryLoading}
                      options={
                        SubCategoryListQueryData &&
                        SubCategoryListQueryData.subCategoryListAdvertisement.map(
                          (v, i) => ({
                            label: v.parentSubCategoryName,
                            value: v._id,
                          })
                        )
                      }
                    />
                  </Form.Item>
                </ConditionalComponent>
                <ConditionalComponent
                  shouldShow={
                    formik.values.bannerType === BANNER_TYPES_FOR_ADMIN.BRAND
                  }
                >
                  <Form.Item label="Select Brand" name="brandId" required>
                    <Select
                      placeholder="Select Brand"
                      name="brandId"
                      loading={AdvertisementBrandListLoading}
                      options={
                        AdvertisementBrandListData &&
                        _.uniqBy(
                          AdvertisementBrandListData.advertisementBrandList.map(
                            (v, i) => ({
                              label: v.brandName,
                              value: v._id,
                            })
                          ),
                          (i) => i.value
                        )
                      }
                    />
                  </Form.Item>
                </ConditionalComponent>
              </ConditionalComponent>
              <Col sm={24} md={12} className="text-right">
                <SubmitButton loading={formik.isSubmitting} type="primary">
                  {MODULE_TITLES.COMMON.SAVE}
                </SubmitButton>
              </Col>
            </Form>
          </FormikProvider>
        </Card>
      </div>
      <Modal
        title="Crop Image"
        centered
        visible={isModalVisible.mobile || isModalVisible.web}
        maskClosable={false}
        mask={true}
        onOk={() =>{ 
          if(isModalVisible.mobile){ 
            handleCropCompleteForMobile()
          }else{
            handleCropComplete()
          }
        }}
        onCancel={handleCancelCrop}
        width="100%"
        okButtonProps={{
          disabled: !isWebCroppedImageValid && !isMobileCroppedImageValid,
        }}
      >
        <ConditionalComponent shouldShow={isModalVisible.web}>
        <ImageCrop
          imgSrc={rawAdvertisementImageWeb}
          onValidate={handleValidateAfterCrop}
          aspectRatio={IMAGE_RATIO_FOR_WEB}
          locked
          isImageForWeb={true}
          isCroppedWithDimension={true}
        />
        </ConditionalComponent>
        <ConditionalComponent shouldShow={isModalVisible.mobile}>
        <ImageCrop
          imgSrc={rawAdvertisementImageForMobile}
          onValidate={handleValidateAfterCropForMobile}
          aspectRatio={IMAGE_RATIO_FOR_MOBILE}
          locked
          isImageForWeb={false}
          isCroppedWithDimension={true}
        />
        </ConditionalComponent>
      </Modal>
    </Content>
  );
};

export default AddBannerByAdmin;

/**Mobile: 1024*576 Aspect Ratio: 16:9
Web: 1440*470 Aspect Ratio: 27:9 */
