import React, { useState } from 'react';
import { Button, Form, Input, InputNumber, Popconfirm, Select, Table } from 'antd';
const { Option } = Select;
const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
const AdditionalChargeTable = ({ data, onChangeUpdateRow, setSelectedAdditionalChargesRow, viewOnly = false }) => {
    const [form] = Form.useForm();
    const [additionalChargeList, setAdditionalChargeList] = React.useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [chargeType, setChargeType] = useState(null);


    React.useEffect(() => {
        const getAdditionalCharges = () => {
            let chargesList = data.map((data, index) => {
                return {
                    ...data,
                    key: data._id,
                    srNo: index + 1
                }
            })
            setAdditionalChargeList(chargesList)
        }
        getAdditionalCharges();
    }, [data]);

    const isEditing = (record) => record.key === editingKey;
    const edit = (record) => {
        form.setFieldsValue({
            title: '',
            amount: '',
            ...record,
        });
        setEditingKey(record.key);
    };
    const cancel = () => {
        setEditingKey('');
    };

    // const save = async (key) => {
    //     try {
    //         const row = await form.validateFields();
    //         const newData = [...additionalChargeList];
    //         const index = newData.findIndex((item) => key === item.key);
    //         if (index > -1) {
    //             const item = newData[index];
    //             let updatedRow = {
    //                 ...item,
    //                 ...row,
    //             }
    //             if(chargeType !== null){
    //                 updatedRow.type = chargeType 
    //             }
    //             newData.splice(index, 1, updatedRow);
    //             setAdditionalChargeList(newData);
    //             setEditingKey('');
    //             onChangeUpdateRow(updatedRow)
    //         }
    //     } catch (errInfo) {
    //     }
    // };

    const save = async (key) => {
        const row = await form.validateFields().catch(errInfo => ({ error: true }));
    
        if (row && !row.error) {
            const newData = [...additionalChargeList];
            const index = newData.findIndex((item) => key === item.key);
    
            if (index > -1) {
                const item = newData[index];
                let updatedRow = {
                    ...item,
                    ...row,
                }
                if (chargeType !== null) {
                    updatedRow.type = chargeType;
                }
                newData.splice(index, 1, updatedRow);
                setAdditionalChargeList(newData);
                setEditingKey('');
                onChangeUpdateRow(updatedRow);
            }
        }
    };    

    const columns = [
        {
            title: "Sr No.",
            dataIndex: "srNo",
            render: (_text, record) => record?.srNo ? record?.srNo : "-",
            align: 'center'
        },
        {
            title: "Title",
            dataIndex: "title",
            render: (_text, record) => record?.title ? record?.title : "-",
            align: 'center',
            editable: true,
        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (_text, record) => record?.amount ? record?.amount : "-",
            align: 'right',
            editable: true,
        },
        {
            title: "Type",
            dataIndex: "type",
            align: 'right',
            render: (_text, record) => {

                const editable = isEditing(record);
                return editable ? (
                    <Select
                        defaultValue={record?.type ? record.type : "-"}
                        style={{ width: 80 }}
                    onChange={(value) => setChargeType(value)}
                    >
                        <Option value="ADD">ADD</Option>
                        <Option value="LESS">LESS</Option>
                    </Select>
                ) :
                    <span>
                        {record?.type ? record.type : "-"}
                    </span>
            },
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            align: 'center',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Button
                            onClick={() => save(record.key)}
                            type="primary mr-5" >
                            Save
                        </Button>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <Button type="primary" >
                                Cancel
                            </Button>
                        </Popconfirm>
                    </span>
                ) : (
                    <Button disabled={editingKey !== ''} onClick={() => edit(record)} type="primary">
                        Edit
                    </Button>

                );
            },
        },
    ];
    const viewOnlyColumn = [
        {
            title: "Sr No.",
            dataIndex: "srNo",
            render: (_text, record) => record?.srNo ? record?.srNo : "-",
            align: 'center'
        },
        {
            title: "Title",
            dataIndex: "title",
            render: (_text, record) => record?.title ? record?.title : "-",
            align: 'center',
            editable: true,
        },
        {
            title: "Type",
            dataIndex: "type",
            render: (_text, record) => record?.type ? record?.type : "-",
            align: 'center',
        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (_text, record) => {
                if(record?.type==="LESS"){
                    return `- ${record?.amount }` 
                }
                if (record?.type === "ADD") {
                    return `+ ${record?.amount}`
                }
            },
            align: 'right',
            editable: true,
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'amount' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedAdditionalChargesRow(newSelectedRowKeys);
    };

    const rowSelection = {
        // selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <Form form={form} component={false}>
            {
                viewOnly ?
                    <Table
                        className='mt-20'
                        bordered
                        dataSource={additionalChargeList}
                        columns={viewOnlyColumn}
                        pagination={false}
                    />
                    :
                    <Table
                        className='mt-20'
                        rowSelection={rowSelection}
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        bordered
                        dataSource={additionalChargeList}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={false}
                    />
            }
        </Form>
    );
};
export default AdditionalChargeTable;