import React, { useEffect } from 'react';
import { PAGINATION } from '../config/constants';
import { useLocation } from 'react-router';
const {DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE} = PAGINATION;
const PaginationContext = React.createContext();
export const usePagination = () => React.useContext(PaginationContext);

export const DEFAULT_PAGE_VALUES = {currentPage: DEFAULT_PAGE_NO, pageSize: DEFAULT_PAGE_SIZE};

export const PaginationProvider = ({children}) => {
    const [pageValues, setPageValues] = React.useState({...DEFAULT_PAGE_VALUES});
    const [search, setSearch] = React.useState('');
    const [cmsModule, setModule] = React.useState(null);
    const [timeoutId, setTimeoutId] = React.useState(null)
    const location = useLocation();

    const handleLocationChangeEffect = React.useCallback(() => {
        let currentPath = getModuleFromPath(location.pathname);
        if(currentPath !== '' && wasModuleSwitched(currentPath,cmsModule)){
            setModule(currentPath)    
        }
    },[cmsModule,location.pathname])

    useEffect(handleLocationChangeEffect,[location.pathname])

    useEffect(()=>{
        resetPageValues();
    },[search])
    
    useEffect(()=>{
        setSearch('')
        setTimeoutId(null);
    },[cmsModule])
    
    const resetSearch = React.useCallback(() => {
        setSearch('')
    },[setSearch])    

    const resetPageValues = React.useCallback(() => {
        setPageValues({...DEFAULT_PAGE_VALUES})
    },[setPageValues]);

    const handlePageSizeChange = React.useCallback((_,pageSize) => {
        setPageValues((state) => ({...state,pageSize}))
    }, [setPageValues]);

    const handlePageNoChange = React.useCallback((currentPage) => {
        setPageValues((state) => ({...state,currentPage}))
    }, [setPageValues]);
    
    const handleSearchChange = React.useCallback((value) => {
        if(timeoutId){
            clearTimeout(timeoutId);
        }
        setTimeoutId(setTimeout(() => setSearch(value), 500));
    }, [setSearch, timeoutId]);

    return <PaginationContext.Provider value={{
        pageValues,
        search,
        resetSearch,
        resetPageValues,
        handlePageNoChange,
        handlePageSizeChange,
        handleSearchChange
    }}>{children}</PaginationContext.Provider>

}

function wasModuleSwitched(pathname = '', previousModule = '') {
    let newModule = getModuleFromPath(pathname);
    return newModule !== previousModule
}
function getModuleFromPath(pathname = '') {
    let paths = pathname.split('/');
    let currentModule = paths[1] || '';
    return currentModule;  
} 
