import { Card, Col, Divider, Row, Typography } from "antd";
import React from "react";
import { ListItem } from "../Product/DetailSections/common";


const ShippingDetails = ({ sourceAddress, destinationAddress ,returnAddress=false}) => {
    return (
        <>

            <Row  gutter={6} wrap>
                <Col sm={24} md={12}>

                    <SampleDetails address={sourceAddress?.fullAddress
                    } cityName={sourceAddress?.cityName} phoneNumber={sourceAddress?.phoneNumber} pinCode={sourceAddress?.pinCode} stateName
                        ={sourceAddress?.stateName
                        } label="Shipping From" />
                </Col>
                <Col sm={24} md={12}>

                    <SampleDetails address={destinationAddress?.fullAddress
                    } cityName={destinationAddress?.cityName} phoneNumber={destinationAddress?.phoneNumber} pinCode={destinationAddress?.pinCode} stateName
                        ={destinationAddress?.stateName
                        } label="Shipping To" />
                </Col>
            </Row>
           {sourceAddress?.fullAddress &&
                     <Col sm={24} md={24}>
                        <SampleDetails address={sourceAddress?.fullAddress
                        } cityName={sourceAddress?.cityName} phoneNumber={sourceAddress?.phoneNumber} pinCode={sourceAddress?.pinCode} stateName
                            ={sourceAddress?.stateName
                            } label="Return Address" />
                </Col>
            }
        </>
    )
}

export default ShippingDetails

const SampleDetails = ({ address, cityName, phoneNumber, pinCode, stateName, label }) => {
    return (
        <>
            <Col sm={24} md={24}>
                <Typography.Text strong>
                    <Divider orientation="left">{label}</Divider>
                </Typography.Text>
            </Col>
            <Col sm={label==="Return Address" ? 12: 24}>
                <Card>
                    <ListItem
                        item={{
                            label: "Full Address",
                            value: address
                        }}
                        labelStrong={true}
                    />
                    <ListItem
                        item={{
                            label: "City Name",
                            value: cityName
                        }}
                        labelStrong={true}
                    />
                    <ListItem
                        item={{
                            label: "PhoneNumber",
                            value: phoneNumber
                        }}
                        labelStrong={true}
                    />
                    <ListItem
                        item={{
                            label: "PinCode",
                            value: pinCode,
                        }}
                        labelStrong={true}
                    />
                    <ListItem
                        item={{
                            label: "State Name",
                            value: stateName

                        }}
                        labelStrong={true}
                    />
                </Card>
            </Col>

        </>

    )
}