import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context';
import { store } from "../../redux/store/store";
import { userActions } from "../../redux/actions/user.actions";
import { GenerateNewAccessTokenDocument } from '../generated/index.tsx'
import { notification } from "antd";

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from redux store if it exists
    const token = store.getState()?.authentication?.userData?.token;
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            token: token ? `${token}` : "",
        }
    }
});

const resetToken = onError((error) => {
    const { graphQLErrors, networkError} = error;
    if (graphQLErrors) {
        graphQLErrors.forEach((errorInstance) => {
            const { extensions } = errorInstance
            if (extensions.code === "UNAUTHENTICATED") {
                handleTokenRefresh();
            }else if(extensions.code === 203){
                store.dispatch(userActions.logout());
            }
        })
    }
    if (networkError) {
        //do something
        // notification.error({message:networkError.message})
    }
});

const handleTokenRefresh = async () => {
    const accessToken = store.getState()?.authentication?.userData?.token;
    const refreshToken = store.getState()?.authentication?.userData?.refreshToken;
    if(!(accessToken && refreshToken)) store.dispatch(userActions.logout());

    try{
        const result = await client.mutate({ mutation: GenerateNewAccessTokenDocument,
            variables:{accessToken,refreshToken}
        });
        const token = result?.data?.generateNewAccessToken?.data?.accessToken;

        store.dispatch(userActions.refresAccessToken(token))
        notification.success({message:result.data.generateNewAccessToken.message})
    }catch(error){
        notification.error({message: error.message || "unable to refetch token" })
        store.dispatch(userActions.logout())
    }
} 

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(resetToken.concat(httpLink))
});

export default client;