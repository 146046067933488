import React, { useEffect } from "react";
import { DEFAULT_PAGE_VALUES } from "../../../context/PaginationContext"
import _ from "lodash";


const usePagination = () => {
    const [pageValues, setPageValues] = React.useState({ ...DEFAULT_PAGE_VALUES });
    const [search, setSearch] = React.useState('');
    const [timeoutId, setTimeoutId] = React.useState(null)
    const [filters, setFilters] = React.useState({});
    const filterRef = React.useRef(filters);

    useEffect(() => {
        if (!_.isEqual(filterRef.current, filters)) {
            resetPageValues();
            filterRef.current = filters;
        }
    }, [filters])

    const resetSearch = React.useCallback(() => {
        setSearch('')
    }, [])

    const resetPageValues = React.useCallback(() => {
        setPageValues({ ...DEFAULT_PAGE_VALUES })
    }, [setPageValues]);

    const handlePageSizeChange = React.useCallback((_, pageSize) => {
        setPageValues((state) => ({ ...state, pageSize }))
    }, []);

    const handlePageNoChange = React.useCallback((currentPage) => {
        setPageValues((state) => ({ ...state, currentPage }))
    }, []);
    const handleSearchChange = React.useCallback((value) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        setTimeoutId(setTimeout(() => setSearch(value), 500));
    }, [timeoutId]);
    const handleFilterChange = React.useCallback((key, value) => {
        setFilters(state => {
            const newFilters = { ...state, [key]: value };
            return newFilters
        })
    }, [])
    return {
        handlers: {
            handlePageNoChange,
            handlePageSizeChange,
            handleSearchChange,
            handleFilterChange,
            resetPageValues,
            resetSearch,
        },
        values: {
            pageValues,
            search,
            filters,
            previousFilters: filterRef
        }
    }
}

export default usePagination;