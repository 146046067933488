import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Row,
  Table,
  notification,
  Checkbox,
  Modal,
  Typography,
  Space,
  Popconfirm,
  Spin,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { MODULE_TITLES } from "../../../config/moduleConfig";
import { LOCATIONS } from "../../../config/routeConfig";
import BreadcrumbComponent from "../../common/Breadcrumb";
import { FormikProvider, useFormik } from "formik";
import { Select, FormItem, Form, SubmitButton, Input } from "formik-antd";
import { Radio } from "antd";
import {
  useAddUpdateManualQuotationOrderMutation,
  useCategoryListQuery,
  useGetUserBalanceLazyQuery,
  useHsnCodeListLazyQuery,
  useManualQuotationDetailLazyQuery,
  useManualQuotationGstDetailsLazyQuery,
  useUpdateManualQuotationConfirmationStatusMutation,
  useUserAddressesListForQuotationLazyQuery,
  useUserListForQuotationLazyQuery,
} from "../../../graphql/generated/index.tsx";
import { ConditionalComponent } from "../../common/GeneralComponents";
import * as Yup from "yup";
import {
  UNIQUE_MESSAGES,
  commonValidationMessage,
} from "../../../helper/MessagesHelper";
import { useNavigate, useParams } from "react-router";
import { OPERATIONS, USER_TYPES } from "../../../config/constants.js";
import AddEditProductQuotation from "./AddEditProductQuotation.jsx";
import AddEditAdditionalCharges from "./AddEditAdditionalCharges.jsx";
import AddNewBillingAddress from "./AddNewBillingAddress.jsx";
import { getTableRowKey } from "../../../helper/GeneralHelper.js";
import AddNewShippingAddress from "./AddNewShippingAddress.jsx";
import PaymentTerms from "./PaymentTermsForQuotation/PaymentTerms.jsx";
import AddNewBuyer from "./AddNewBuyer.jsx";
import { DeleteOutlined } from "@ant-design/icons";


const DELIVERY_TERMS_FOR_QUOTATION = [
  { label: "TO PAY", value: "TO_PAY" },
  { label: "PAID", value: "PAID" },
];

const RADIO_BUTTON_FOR_ADDRESS = {
  Select_From_Existing: "Select From Existing",
  Add_New: "Add New ",
};

const AddNewEditQuotation = ({ operationType }) => {
  const isAddOperation = operationType === OPERATIONS.ADD;
  const breadcrumb_items = [
    { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
    {
      label: MODULE_TITLES.QUOTE_GENERATION_NAME.ROOT,
      to: LOCATIONS.QUOTE_GENERATION_ROUTES.ROOT,
    },
    {
      label: isAddOperation
        ? MODULE_TITLES.QUOTE_GENERATION_NAME.CREATE
        : MODULE_TITLES.QUOTE_GENERATION_NAME.EDIT,
    },
  ];

  const columnsForAdditionalCharges = [
    {
      title: "S.No.",
      key: "sno",
      render: (a, v, index) => index + 1,
    },
    {
      title: "Type of Charge",
      dataIndex: "type",
      editable: true,
    },
    {
      title: "Title",
      dataIndex: "title",
      editable: true,
    },
    {
      title: "Amount",
      align: "end",
      render: (_text, record) =>
        record?.type === "ADD"
          ? ` + ${record?.amount}`
          : ` - ${record?.amount}`,
    },
    {
      title: "Actions",
      align: "center",
      render: (_text, record, index) => {
        return (
          <>
            <Popconfirm
              placement="bottomRight"
              title={UNIQUE_MESSAGES.DELETE_POPUP("Additional Charge")}
              onConfirm={() => confirmAdditionalChargesDeleted(index)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="link"
                shape="circle"
                icon={<DeleteOutlined />}
                loading={false}
                disabled={viewProductData?.isQuotationConfirmed}

                // onClick={() => navigate(viewManualQuotationURL, { state: { recordId: record?._id } })}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const columns = [
    {
      title: "S.No.",
      key: "sno",
      render: (a, v, index) => index + 1,
    },
    {
      title: "Product Name/Description",
      dataIndex: "productName",
      editable: true,
    },
    {
      title: "HSN Code",
      dataIndex: "hsnCode",
      render: (_text, record) => {
        if (record && record.hsnCode && !record.hsnCode?.value) {
          return record && record.hsnCode;
        } else return record?.hsnCode?.label && record?.hsnCode?.label;
      },

      editable: true,
    },
    {
      title: "Qty.",
      dataIndex: "quantity",
      editable: true,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      editable: true,
    },
    {
      title: "Price ₹",
      dataIndex: "unitPrice",
      editable: true,
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
      editable: true,
      align: "end",
    },
    {
      title: "Actions",
      align: "center",
      render: (_text, record, index) => {
        return (
          <>
            <Popconfirm
              placement="bottomRight"
              title={UNIQUE_MESSAGES.DELETE_POPUP("Product")}
              onConfirm={() => confirmDeleted(index)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="link"
                shape="circle"
                icon={<DeleteOutlined />}
                loading={false}
                disabled={viewProductData?.isQuotationConfirmed}
                // onClick={() => navigate(viewManualQuotationURL, { state: { recordId: record?._id } })}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const navigate = useNavigate();
  const params = useParams();
  const [selectedBuyer, setSelectedBuyer] = useState("");
  const [newBuyer, setNewBuyer] = useState({});
  const [isNewBuyerAddedModalVisible, setNewBuyerAddedModalVisible] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState("");
  const [selectedShippingAddressfromExisting,setSelectedShippingAddressfromExisting] = useState("");
  const [ slectedBillingAddressFromExisting, setSlectedBillingAddressFromExisting] = useState("");
  const [newShippingAddress, setNewShippingAddress] = useState({});
  const [newBillingAddress, setNewBillingAddress] = useState({});
  const [viewProductData, setViewProductData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [additionalChargesData, setAdditionalChargesData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAdditionalChargeModalVisible, setIsAdditionalChargeModalVisible] = useState(false);
  const [isNewShippingAddressModalVisible, setNewShippingAddressModalVisible] = useState(false);
  const [isNewBillingAddressModalVisible, setNewBillingAddressModalVisible] = useState(false);
  const [paymentTermRadioValue, setPaymentTermRadioValue] = useState("");
  const [manualQuotationGSTDetails, setManualQuotationGSTDetails] = useState(null);
  const [creditDebitDetail, setCreditDebitDetail] = useState(0);
  const [shouldResetForm, setShouldResetForm] = useState(false);
  const [hsnCodeOptions, setHsnCodeOption] = useState([])
  
  const [quotation, setQuotation] = useState(["1. Goods once sold will not be taken back.",
   "2. Interest @ 18% p.a. will be charged if the payment is not Made within the stipulated time",
    "3. Subject to 'Maharashtra' Jurisdiction only"]);

  useEffect(() => {
    if (!isAddOperation) {
      setQuotation([viewProductData?.termsAndConditions] || [quotation]);
    }
  }, [isAddOperation, viewProductData?.termsAndConditions]);

  const QuotationSchema = Yup.object().shape({
    selectedCategory: Yup.string().required(commonValidationMessage("Category")),
    selectedBuyer: Yup.string().required(commonValidationMessage("Buyer")),
    selectedSeller: Yup.string().required(commonValidationMessage("Seller")),
    quotationDescription: Yup.string().required(
      commonValidationMessage("Declaration")
    ),
    // termsAndConditions:Yup.string().required(commonValidationMessage("Terms & Conditions")),
    selectedShippingAddressfromExisting: Yup.string().when(
      "shippingAddressFromRadioBtn",
      {
        is: (shippingAddressFromRadioBtn) =>
          shippingAddressFromRadioBtn !== true,
        then: Yup.string().required(
          commonValidationMessage("Shipping Address")
        ),
        otherwise: Yup.string().optional(),
      }
    ),
    billingAddressFromRadioBtn: Yup.boolean().required(),
    selectBillingAddressfromExisting: Yup.string().when(
      ["!billingAddressFromRadioBtn"],
      {
        is: (billingAddressFromRadioBtn) => billingAddressFromRadioBtn == true,
        then: Yup.string().required(commonValidationMessage("Billing Address")),
        otherwise: Yup.string().optional(),
      }
    ),
    deliveryType: Yup.string()
      .required("Delivery Type is required")
      .oneOf(["TO_PAY", "PAID"], "Delivery type."),

    // Payment term validation
    creditLimitDays: Yup.number().when("paymentTermRadioValue", {
      is: "isCreditPayment",
      then: Yup.number()
        .typeError("Credit Limit must be a number")
        .required("Credit Limit is required")
        .positive("Credit Limit must be a positive number")
        .integer("Credit Limit must be an integer")
        .min(1, "Credit Limit must be minimum 1")
        .max(90, "Can't be more than 90"),
      otherwise: Yup.number().notRequired(), // If not 'isCreditPayment', no validation needed for this field
    }),

    onArrivalPaymentPercentage: Yup.number().when("paymentTermRadioValue", {
      is: "isSplitPayment",
      then: Yup.number()
        .typeError("Payment must be a number")
        .required("Payment is required")
        .positive("Must be a positive number")
        .max(100, "Can't be more than 100"),
      otherwise: Yup.number().notRequired(), // If not 'isSplitPayment', no validation needed for this field
    }),

    onDeliveryPaymentPercentage: Yup.number().when(
      ["paymentTermRadioValue", "onArrivalPaymentPercentage"],
      {
        is: (payment, onArrival) => payment === "isSplitPayment" && onArrival,
        then: Yup.number()
          .typeError("Payment must be a number")
          .required("Payment is required")
          .positive("Payment must be a positive number")
          .max(100, "Can't be more than 100")
          .test(
            "sum-validation",
            "Sum of both must be 100%",
            function (value) {
              const onArrival = this.resolve(
                Yup.ref("onArrivalPaymentPercentage")
              );
              // Ensure that the sum of onArrival and onDelivery is equal to 100
              return value + onArrival === 100;
            }
          ),
        otherwise: Yup.number(),
      }
    ),
  });
  
  const formik = useFormik({
    initialValues: {
      selectedCategory: "",
      selectedBuyer: "",
      selectedSeller: "",
      brokerName: null,
      brokerContactNumber: null,
      transportName: null,
      vehicleNumber: null,
      selectedShippingAddressfromExisting: "",
      quotationDescription: "",
      shippingAddressFromRadioBtn: false,
      selectedBuyerRadioBtn: false,
      billingAddresCheckBox: true,
      billingAddressFromRadioBtn: false,
      deliveryType: "",
      paymentTermRadioValue: "",
      isAdvancePayment: false,
      isPaymentAgainstDelivery: false,
      isCreditPayment: false,
      creditLimitDays: "",
      isSplitPayment: false,
      onArrivalPaymentPercentage: "",
      onDeliveryPaymentPercentage: "",
      termsAndConditions: "",
    },
    validationSchema: QuotationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values, actions) => {
      let variables = {
        input: {
          shippingAddress: {},
          billingAddress: {},
          products: isAddOperation ? removeHSNAtAddTime : removeTypeNameWhileEditingProduct && removeTypeNameWhileEditingProduct,
          quotationDescription: values.quotationDescription,
          additionalCharges: isAddOperation ? additionalChargesData : removeTypeNameWhileEditingAdditionalCharges && removeTypeNameWhileEditingAdditionalCharges,
          totalOrderAmount: manualQuotationGSTDetails?.totalOrderAmount && manualQuotationGSTDetails?.totalOrderAmount.toString(),
          paymentTerms: {},
          deliveryType: values?.deliveryType && values?.deliveryType,
          termsAndConditions: values?.termsAndConditions ? Array.isArray(values.termsAndConditions)
              ? values.termsAndConditions.join("\n") // Convert array to string using newline as separator
              : values.termsAndConditions
            : Array.isArray(quotation)
              ? quotation.join("\n") // Convert array to string using newline as separator
              : quotation,
          transportDetails: {},
        },
      };

      if (variables.input.transportDetails && values.transportName) {
        variables.input.transportDetails.transportName = values.transportName;
      }
      if (variables.input.transportDetails && values.vehicleNumber) {
        variables.input.transportDetails.vehicleNumber = values.vehicleNumber;
      }

      if (manualQuotationGSTDetails) {
        variables.input.cgstAmount = manualQuotationGSTDetails && manualQuotationGSTDetails?.cgstAmount;
        variables.input.cgstPercentage = manualQuotationGSTDetails && manualQuotationGSTDetails?.cgstPercentage;
        variables.input.gstAmount = manualQuotationGSTDetails && manualQuotationGSTDetails?.gstAmount;
        variables.input.gstPercentage = manualQuotationGSTDetails && manualQuotationGSTDetails?.gstPercentage;
        variables.input.igstAmount = manualQuotationGSTDetails && manualQuotationGSTDetails?.igstAmount;
        variables.input.igstPercentage = manualQuotationGSTDetails && manualQuotationGSTDetails?.igstPercentage;
        variables.input.roundOffAmount = manualQuotationGSTDetails && manualQuotationGSTDetails?.roundOffAmount;
        variables.input.roundOffType = manualQuotationGSTDetails && manualQuotationGSTDetails?.roundOffType;
        variables.input.sgstAmount = manualQuotationGSTDetails && manualQuotationGSTDetails?.sgstAmount;
        variables.input.sgstPercentage = manualQuotationGSTDetails && manualQuotationGSTDetails?.sgstPercentage;
      }
      if (variables.input) {
        if ( values.brokerName !== undefined && values.brokerName !== null && values.brokerName !== "") {
          variables.input.brokerName = values.brokerName;
        } else {
          delete variables.input.brokerName;
        }
      }

      if (variables.input) {
        if (
          values.brokerContactNumber !== undefined &&
          values.brokerContactNumber !== null &&
          values.brokerContactNumber !== ""
        ) {
          variables.input.brokerContactNumber = Number(
            values.brokerContactNumber
          );
        } else {
          delete variables.input.brokerContactNumber;
        }
      }

      if (values && variables && variables.input) {
        variables.input.categoryId = values.selectedCategory;
        variables.input.buyerId = values.selectedBuyer;
      }
      if (values && variables && variables.input) {
        variables.input.categoryId = values.selectedCategory;
        variables.input.sellerId = values.selectedSeller;
      }
      if (
        !formik.values.shippingAddressFromRadioBtn &&
        formik.values.billingAddresCheckBox
      ) {
        variables.input.shippingAddress = shippingExistingAddressObj;
        variables.input.billingAddress = shippingExistingAddressObj;
      } else if (
        formik.values.shippingAddressFromRadioBtn &&
        formik.values.billingAddresCheckBox
      ) {
        variables.input.shippingAddress = addNewShippingAddress;
        variables.input.billingAddress = addNewShippingAddress;
      }
      if (
        !formik.values.billingAddresCheckBox &&
        !formik.billingAddressFromRadioBtn &&
        !formik.values.shippingAddressFromRadioBtn
      ) {
        variables.input.shippingAddress = shippingExistingAddressObj;
        variables.input.billingAddress = BillingExistingAddressObj;
      } else if (
        !formik.values.billingAddresCheckBox &&
        formik.values.shippingAddressFromRadioBtn &&
        formik.values.billingAddressFromRadioBtn
      ) {
        variables.input.shippingAddress = addNewShippingAddress;
        variables.input.billingAddress = addNewBillingAddress;
      }
      if (
        !formik.values.shippingAddressFromRadioBtn &&
        !formik.values.billingAddresCheckBox &&
        formik.values.billingAddressFromRadioBtn
      ) {
        variables.input.shippingAddress = shippingExistingAddressObj;
        variables.input.billingAddress = addNewBillingAddress;
      } else if (
        formik.values.shippingAddressFromRadioBtn &&
        !formik.values.billingAddresCheckBox &&
        !formik.values.billingAddressFromRadioBtn
      ) {
        variables.input.shippingAddress = addNewShippingAddress;
        variables.input.billingAddress = BillingExistingAddressObj;
      }
// =====//===

      // if (formik.values.billingAddresCheckBox) {
      //   variables.input.shippingAddress = formik.values.shippingAddressFromRadioBtn ? addNewShippingAddress : shippingExistingAddressObj;
      //   variables.input.billingAddress = formik.values.billingAddressFromRadioBtn ? addNewBillingAddress : BillingExistingAddressObj;
      // } else {
      //   variables.input.shippingAddress = shippingExistingAddressObj;
      //   variables.input.billingAddress = BillingExistingAddressObj;
      // }

      // if (!isAddOperation) {
      //   variables.input.manualQuotationId = params?.quotegenerationId;
      // }
      // if (!isAddOperation) {
      //   variables.input.shippingAddress = editShippingAddress;
      //   variables.input.billingAddress = editBillingAddress;
      // }
      // if (paymentTermRadioValue === "isAdvancePayment") {
      //   if (variables.input.paymentTerms) {
      //     variables.input.paymentTerms.isAdvancePayment =
      //       formik.values.isAdvancePayment;
      //   }
      // }
      // if (paymentTermRadioValue === "isPaymentAgainstDelivery") {
      //   if (variables.input.paymentTerms) {
      //     variables.input.paymentTerms.isPaymentAgainstDelivery =
      //       formik.values.isPaymentAgainstDelivery;
      //   }
      // }
      // if (paymentTermRadioValue === "isCreditPayment") {
      //   if (variables.input.paymentTerms) {
      //     variables.input.paymentTerms.isCreditPayment =
      //       formik.values.isCreditPayment;
      //     variables.input.paymentTerms.creditLimitDays = Number(
      //       formik.values.creditLimitDays
      //     );
      //   }
      // }
      // if (paymentTermRadioValue === "isSplitPayment") {
      //   if (variables.input.paymentTerms) {
      //     variables.input.paymentTerms.isSplitPayment =
      //       formik.values.isSplitPayment;
      //     variables.input.paymentTerms.onArrivalPaymentPercentage = Number(
      //       formik.values.onArrivalPaymentPercentage
      //     );
      //     variables.input.paymentTerms.onDeliveryPaymentPercentage = Number(
      //       formik.values.onDeliveryPaymentPercentage
      //     );
      //   }
      // }
      if (!isAddOperation) {
        variables.input.manualQuotationId = params?.quotegenerationId;
        variables.input.shippingAddress = editShippingAddress;
        variables.input.billingAddress = editBillingAddress;
      }
      if (variables.input.paymentTerms) {
        switch (paymentTermRadioValue) {
          case "isAdvancePayment":
            variables.input.paymentTerms.isAdvancePayment = formik.values.isAdvancePayment;
            break;
          case "isPaymentAgainstDelivery":
            variables.input.paymentTerms.isPaymentAgainstDelivery = formik.values.isPaymentAgainstDelivery;
            break;
          case "isCreditPayment":
            variables.input.paymentTerms.isCreditPayment = formik.values.isCreditPayment;
            variables.input.paymentTerms.creditLimitDays = Number(formik.values.creditLimitDays);
            break;
          case "isSplitPayment":
            variables.input.paymentTerms.isSplitPayment = formik.values.isSplitPayment;
            variables.input.paymentTerms.onArrivalPaymentPercentage = Number(formik.values.onArrivalPaymentPercentage);
            variables.input.paymentTerms.onDeliveryPaymentPercentage = Number(formik.values.onDeliveryPaymentPercentage);
            break;
        }
      }
      if (
        variables.input.shippingAddress && variables.input.shippingAddress.fullAddress
      ) {
        delete variables.input.shippingAddress.fullAddress;
      }
      if (
        variables.input.billingAddress && variables.input.billingAddress.fullAddress
      ) {
        delete variables.input.billingAddress.fullAddress;
      }
      addUpdateManualQuotationOrderMutation({
        variables: variables,
        onCompleted(data) {
          notification.success({
            message: data.addUpdateManualQuotationOrder.message,
          });
          navigate(LOCATIONS.QUOTE_GENERATION_ROUTES.ROOT);
          actions.setSubmitting(false);
        },
        onError(error) {
          notification.error({
            message: error.message,
          });
          actions.setSubmitting(false);
          actions.validateForm();
        },
      });
    },
  });

  useEffect(()=>{
    setCreditDebitDetail(0)
  },[formik.values.selectedBuyer])

  const handleTextAreaChange = (e) => {
    const lines = e.target.value.split('\n');
    setQuotation(lines);
  };

  const handleRadioChange = (e) => {
    setPaymentTermRadioValue(e.target.value);
    formik.setFieldValue("paymentTermRadioValue", e.target.value, true);
  };

  const handleAdditionalChargesUpdate = (values) => {
    setAdditionalChargesData((prevData) => [...prevData, values]);
  };

  useEffect(() => {
    if (isAddOperation) {
      formik.setFieldValue("isAdvancePayment", false, true);
      formik.setFieldValue("isPaymentAgainstDelivery", false, true);
      formik.setFieldValue("isCreditPayment", false, true);
      formik.setFieldValue("creditLimitDays", "", true);
      formik.setFieldValue("isSplitPayment", false, true);
      formik.setFieldValue("onArrivalPaymentPercentage", "", true);
      formik.setFieldValue("onDeliveryPaymentPercentage", "", true);
    }
  }, [paymentTermRadioValue]);

  const handleAddProductClick = () => {
    setIsModalVisible(true);
  };

  const handleAdddditionalChargesClick = () => {
    setIsAdditionalChargeModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const confirmDeleted = (id) => {
    let updatedList = [];
    if (!isAddOperation) {
      updatedList = viewProductData.products.filter((_, index) => index !== id);
    } else {
      updatedList = productData?.filter((_, index) => index !== id);
    }
    renderUpdatedList(updatedList);
  };

  const renderUpdatedList = (updatedList) => {
    if (!isAddOperation) {
      setViewProductData((prevData) => ({
        ...prevData,
        products: updatedList,
      }));
    } else {
      setProductData(updatedList);
    }
  };

  const confirmAdditionalChargesDeleted = (id) => {
    let updatedList = [];
    if (!isAddOperation) {
      updatedList = viewProductData?.additionalCharges?.filter(
        (_, index) => index !== id
      );
    } else {
      updatedList = additionalChargesData?.filter((_, index) => index !== id);
    }
    renderAddUpdatedList(updatedList);
  };

  const renderAddUpdatedList = (updatedList) => {
    if (!isAddOperation) {
      setViewProductData((prevData) => ({
        ...prevData,
        additionalCharges: updatedList,
      }));
    } else {
      setAdditionalChargesData(updatedList);
    }
  };

  const handleAdditionalChargesModalCancel = () => {
    setIsAdditionalChargeModalVisible(false);
  };

  const { data: categoryListData, loading: categoryListLoading } = useCategoryListQuery();
  const [getUserListForQuotation,{ data: userListForQuotationData, loading: userListForQuotationLoading }] = useUserListForQuotationLazyQuery({
    variables: {
      filterBy: USER_TYPES.BUYER,
      categoryId:formik.values.selectedCategory
    },
    fetchPolicy:"cache-and-network"
  });

  const [getUserSellerListForQuotation, { data: userSellerListForQuotationData, loading: userSellerListForQuotationLoading }] = useUserListForQuotationLazyQuery({
    variables: {
      filterBy: USER_TYPES.SELLER,
      categoryId:formik.values.selectedCategory
    },
    fetchPolicy:"cache-and-network"
  });

  useEffect(() => {
    if(formik.values.selectedCategory){
      getUserListForQuotation();
      getUserSellerListForQuotation();
    }
  }, [formik.values.selectedCategory]);


  const foundSelectedSellerStateId = userSellerListForQuotationData?.userListForQuotation.filter(
    (item) => {
      if (item?._id == formik.values.selectedSeller) {
        return item;
      }
    }
  );
  const sellerStateId = foundSelectedSellerStateId && foundSelectedSellerStateId[0]?.sellerBusinessAddressDetails?.stateId

  const [getUserAddressesListForQuotation,{data: userAddressesListForQuotationData,loading: userAddressesListForQuotationLoading},
  ] = useUserAddressesListForQuotationLazyQuery({
    variables: {
      userId: formik.values.selectedBuyer,
    },
  });
  
  const [ getManualQuotationDetailsLazyQuery, { loading: manualQuotationDetailLoading }] = useManualQuotationDetailLazyQuery();
  const [addUpdateManualQuotationOrderMutation] = useAddUpdateManualQuotationOrderMutation();
  // on Edit set address

  let editShippingAddress;
  if (!isAddOperation && viewProductData?.shippingAddress) {
    const shippingAddress =
      viewProductData?.shippingAddress ?? viewProductData?.shippingAddress;
    if (shippingAddress) {
      const { __typename, ...res } = shippingAddress;
      if (shippingAddress?.addressType !== "Other") {
        delete res.otherAddressTypeName;
      }
      editShippingAddress = { ...res };
    }
  }

  let editBillingAddress;
  if (!isAddOperation && viewProductData?.billingAddress) {
    const billingAddress =
      viewProductData?.billingAddress ?? viewProductData?.billingAddress;
    if (billingAddress) {
      const { __typename, ...res } = billingAddress;
      if (billingAddress?.addressType !== "Other") {
        delete res.otherAddressTypeName;
      }
      editBillingAddress = { ...res };
    }
  }

  //While Adding new product remove HSCCode as it's not required
  let removeHSNAtAddTime;
  if (isAddOperation) {
    removeHSNAtAddTime = productData?.map((items) => {
      const { hsnCode, availableQuantity, ...res } = items;
      return res;
    });
  }

  let removeTypeNameWhileEditingProduct;
  if (!isAddOperation) {
    removeTypeNameWhileEditingProduct = viewProductData?.products?.map(
      (items) => {
        const { __typename, hsnCode, _id, availableQuantity, ...res } = items;
        return res;
      }
    );
  }

  let removeTypeNameWhileEditingAdditionalCharges;
  if (!isAddOperation) {
    removeTypeNameWhileEditingAdditionalCharges =
      viewProductData?.additionalCharges?.map((items) => {
        const { __typename, _id, ...res } = items;
        return res;
      });
  }
  let sumOfProductAmount;
  if (isAddOperation) {
    sumOfProductAmount = productData?.reduce((accumulator, item) => {
      let productTotalAmount = parseFloat(String(accumulator)) + parseFloat(String(item?.totalAmount));
      return productTotalAmount;
    }, "0");
  } else {
    sumOfProductAmount = viewProductData?.products?.reduce(
      (accumulator, item) => {
        let productTotalAmount = parseFloat(String(accumulator)) + parseFloat(String(item?.totalAmount));
        return productTotalAmount;
      },
      "0"
    );
  }

  let QuotationAdditionalAmount;
  if (isAddOperation) {
    QuotationAdditionalAmount = additionalChargesData?.reduce(
      (accumulator, item) => {
        if (item?.type === "ADD") {
          return accumulator + item?.amount;
        }
        return accumulator - item?.amount;
      },
      0
    );
  } else {
    QuotationAdditionalAmount = viewProductData?.additionalCharges?.reduce(
      (accumulator, item) => {
        if (item?.type === "ADD") {
          return accumulator + item?.amount;
        }
        return accumulator - item?.amount;
      },
      0
    );
  }

  const totalAmount =
    Number(sumOfProductAmount) + Number(QuotationAdditionalAmount);

  let createShippingFullAddress;
  if (
    newShippingAddress?.addressLine1 &&
    newShippingAddress?.landMark &&
    newShippingAddress?.cityId?.label &&
    newShippingAddress?.stateId?.label &&
    newShippingAddress?.pinCode
  ) {
    createShippingFullAddress = `${newShippingAddress?.addressLine1},${newShippingAddress?.landMark} - ${newShippingAddress?.cityId?.label} ${newShippingAddress?.stateId?.label},${newShippingAddress?.pinCode}`;
  }

  let createBillingFullAddress;
  if (
    newBillingAddress?.addressLine1 &&
    newBillingAddress?.landMark &&
    newBillingAddress?.cityId?.label &&
    newBillingAddress?.stateId?.label &&
    newBillingAddress?.pinCode
  ) {
    createBillingFullAddress = `${newBillingAddress?.addressLine1},${newBillingAddress?.landMark} - ${newBillingAddress?.cityId?.label} ${newBillingAddress?.stateId?.label},${newBillingAddress?.pinCode}`;
  }

  const handleBuyerChange = (value) => {
    setSelectedBuyer(value);
  };

  const handleCategoryChange = (value) => {
    formik.setFieldValue("selectedCategory", value, true);
  };

  const foundShippingAddressFromExisting = userAddressesListForQuotationData?.userAddressesListForQuotation?.filter(
      (item) => item._id === selectedShippingAddressfromExisting
    );

  let shippingExistingAddressObj = {};

  if ( foundShippingAddressFromExisting && foundShippingAddressFromExisting.length > 0) {
    shippingExistingAddressObj = {
      stateId: foundShippingAddressFromExisting[0]?.stateId && foundShippingAddressFromExisting[0]?.stateId,
      stateName: foundShippingAddressFromExisting[0]?.stateName && foundShippingAddressFromExisting[0]?.stateName,
      pinCode: Number(foundShippingAddressFromExisting[0]?.pinCode) && Number(foundShippingAddressFromExisting[0]?.pinCode),
      phoneNumber: Number(foundShippingAddressFromExisting[0]?.phoneNumber) && Number(foundShippingAddressFromExisting[0]?.phoneNumber),
      gstNumber: foundShippingAddressFromExisting[0]?.gstNumber && foundShippingAddressFromExisting[0]?.gstNumber,
      cityId: foundShippingAddressFromExisting[0]?.cityId && foundShippingAddressFromExisting[0]?.cityId,
      cityName: foundShippingAddressFromExisting[0]?.cityName && foundShippingAddressFromExisting[0]?.cityName,
      addressType: foundShippingAddressFromExisting[0]?.addressType && foundShippingAddressFromExisting[0]?.addressType,
      addressLine1: foundShippingAddressFromExisting[0]?.addressLine1 && foundShippingAddressFromExisting[0]?.addressLine1,
      landMark: foundShippingAddressFromExisting[0]?.landMark && foundShippingAddressFromExisting[0]?.landMark,
      ...(foundShippingAddressFromExisting[0]?._id && {
        _id: foundShippingAddressFromExisting[0]._id,
      }),
    };
    if (foundShippingAddressFromExisting[0]?.addressType === "Other") {
      shippingExistingAddressObj.otherAddressTypeName =
        foundShippingAddressFromExisting[0]?.otherAddressTypeName;
    }
  }

  const shipplingAddressStateId = shippingExistingAddressObj && shippingExistingAddressObj?.stateId

  let addNewShippingAddress = {};
  if (Object.keys(newShippingAddress).length !== 0) {
    addNewShippingAddress = {
      stateId:
        newShippingAddress?.stateId?.value &&
        newShippingAddress?.stateId?.value,
      stateName:
        newShippingAddress?.stateId?.label &&
        newShippingAddress?.stateId?.label,
      pinCode:
        Number(newShippingAddress?.pinCode) &&
        Number(newShippingAddress?.pinCode),
      phoneNumber:
        Number(newShippingAddress?.phoneNumber) &&
        Number(newShippingAddress?.phoneNumber),
      gstNumber: newShippingAddress?.gstNumber && newShippingAddress?.gstNumber,
      cityId:
        newShippingAddress?.cityId?.value && newShippingAddress?.cityId?.value,
      cityName:
        newShippingAddress?.cityId?.label && newShippingAddress?.cityId?.label,
      addressType:
        newShippingAddress?.addressType && newShippingAddress?.addressType,
      addressLine1:
        newShippingAddress?.addressLine1 && newShippingAddress?.addressLine1,
      landMark: newShippingAddress?.landMark && newShippingAddress?.landMark,
    };
    if (newShippingAddress?.addressType === "Other") {
      addNewShippingAddress.otherAddressTypeName =
        newShippingAddress?.otherAddressTypeName;
    }
    delete newShippingAddress?.cityName;
  }

  // Handle Billing Address
  const foundBillingAddressFromExisting =
    userAddressesListForQuotationData?.userAddressesListForQuotation.filter(
      (item) => {
        if (item?._id == slectedBillingAddressFromExisting) {
          return item;
        }
      }
    );


  let BillingExistingAddressObj = {};
  if (
    foundBillingAddressFromExisting &&
    foundBillingAddressFromExisting.length > 0
  ) {
    BillingExistingAddressObj = {
      stateId: foundBillingAddressFromExisting[0]?.stateId && foundBillingAddressFromExisting[0]?.stateId,
      stateName: foundBillingAddressFromExisting[0]?.stateName && foundBillingAddressFromExisting[0]?.stateName,
      pinCode: Number(foundBillingAddressFromExisting[0]?.pinCode) && Number(foundBillingAddressFromExisting[0]?.pinCode),
      phoneNumber: Number(foundBillingAddressFromExisting[0]?.phoneNumber) && Number(foundBillingAddressFromExisting[0]?.phoneNumber),
      gstNumber: foundBillingAddressFromExisting[0]?.gstNumber && foundBillingAddressFromExisting[0]?.gstNumber,
      cityId: foundBillingAddressFromExisting[0]?.cityId && foundBillingAddressFromExisting[0]?.cityId,
      cityName: foundBillingAddressFromExisting[0]?.cityName && foundBillingAddressFromExisting[0]?.cityName,
      addressType: foundBillingAddressFromExisting[0]?.addressType && foundBillingAddressFromExisting[0]?.addressType,
      addressLine1: foundBillingAddressFromExisting[0]?.addressLine1 && foundBillingAddressFromExisting[0]?.addressLine1,
      landMark: foundBillingAddressFromExisting[0]?.landMark && foundBillingAddressFromExisting[0]?.landMark,
      ...(foundBillingAddressFromExisting[0]?._id && {
        _id: foundBillingAddressFromExisting[0]._id,
      }),
    };
    if (foundBillingAddressFromExisting[0]?.addressType === "Other") {
      BillingExistingAddressObj.otherAddressTypeName =
        foundBillingAddressFromExisting[0]?.otherAddressTypeName;
    }
  }
  const BillingAddressStateId = BillingExistingAddressObj && BillingExistingAddressObj?.stateId

  //=>Add New
  let addNewBillingAddress = {};
  if (Object.keys(newBillingAddress).length !== 0) {
    addNewBillingAddress = {
      stateId : newBillingAddress?.stateId?.value && newBillingAddress?.stateId?.value,
      stateName : newBillingAddress?.stateId?.label && newBillingAddress?.stateId?.label,
      pinCode : Number(newBillingAddress?.pinCode) && Number(newBillingAddress?.pinCode),
      phoneNumber : Number(newBillingAddress?.phoneNumber) && Number(newBillingAddress?.phoneNumber),
      gstNumber : newBillingAddress?.gstNumber && newBillingAddress?.gstNumber,
      cityId : newBillingAddress?.cityId?.value && newBillingAddress?.cityId?.value,
      cityName : newBillingAddress?.cityId?.label && newBillingAddress?.cityId?.label,
      addressType : newBillingAddress?.addressType && newBillingAddress?.addressType,
      addressLine1 : newBillingAddress?.addressLine1 && newBillingAddress?.addressLine1,
      landMark: newBillingAddress?.landMark && newBillingAddress?.landMark,
    };
    if (newBillingAddress?.addressType === "Other") {
      addNewBillingAddress.otherAddressTypeName = newBillingAddress?.otherAddressTypeName;
    }
    delete newBillingAddress?.cityName;
  }

  const handleBrokerNameChange = (e) => {
    const updatedBrokerName = e.target.value;
    formik.setFieldValue("brokerName", updatedBrokerName, true);
  };

  const handleBrokerContactNumberChange = (e) => {
    const updatedBrokerContactName = e.target.value.slice(0, 10);
    formik.setFieldValue("brokerContactNumber", updatedBrokerContactName, true);
  };

  useEffect(() => {
    if (formik.values.selectedBuyer) {
      getUserAddressesListForQuotation();
    }
  }, [formik.values.selectedBuyer,isAddOperation]);

  useEffect(() => {
    if (!isAddOperation) {
      getManualQuotationDetails(params?.quotegenerationId);
    }
    if (operationType === OPERATIONS.ADD) {
      formik.resetForm();
    }
  }, [!isAddOperation]);

  const getManualQuotationDetails = useCallback(
    (id) => {
      getManualQuotationDetailsLazyQuery({
        variables: { id: id },
        onCompleted(data) {
          if (!isAddOperation) {
            formik.setFieldValue("selectedCategory",data?.manualQuotationDetail?.categoryId,true);
            formik.setFieldValue("selectedBuyer",data?.manualQuotationDetail?.buyerId,true);
            formik.setFieldValue("selectedSeller",data?.manualQuotationDetail?.sellerId,true);
            formik.setFieldValue( "selectedShippingAddressfromExisting", data?.manualQuotationDetail?.shippingAddress._id || data?.manualQuotationDetail?.shippingAddress.fullAddress,  true);
            formik.setFieldValue( "selectBillingAddressfromExisting", data?.manualQuotationDetail?.billingAddress._id || data?.manualQuotationDetail?.billingAddress.fullAddress, true);
            formik.setFieldValue( "quotationDescription", data?.manualQuotationDetail?.quotationDescription, true);
            formik.setFieldValue( "brokerName", data?.manualQuotationDetail?.brokerName, true);
            formik.setFieldValue( "transportName", data?.manualQuotationDetail?.transportDetails?.transportName, true);
            formik.setFieldValue( "vehicleNumber", data?.manualQuotationDetail?.transportDetails?.vehicleNumber, true);
            formik.setFieldValue( "brokerContactNumber", data?.manualQuotationDetail?.brokerContactNumber, true);
            formik.setFieldValue( "deliveryType", data?.manualQuotationDetail?.deliveryType, true);
            formik.setFieldValue( "termsAndConditions", data?.manualQuotationDetail?.termsAndConditions, true);
            if (data?.manualQuotationDetail?.paymentTerms?.isAdvancePayment) {
              setPaymentTermRadioValue("isAdvancePayment");
              formik.setFieldValue("isAdvancePayment", true, true);
              formik.setFieldValue(
                "paymentTermRadioValue",
                "isAdvancePayment",
                true
              );
            }
            if (
              data?.manualQuotationDetail?.isSellerBuyerAddressSame === false
            ) {
              formik.setFieldValue("billingAddresCheckBox", false, true);
            }
            if (
              data?.manualQuotationDetail?.paymentTerms
                ?.isPaymentAgainstDelivery
            ) {
              setPaymentTermRadioValue("isPaymentAgainstDelivery");
              formik.setFieldValue("isPaymentAgainstDelivery", true, true);
              formik.setFieldValue(
                "paymentTermRadioValue",
                "isCreditPayment",
                true
              );
            }
            if (data?.manualQuotationDetail?.paymentTerms?.isCreditPayment) {
              setPaymentTermRadioValue("isCreditPayment");
              formik.setFieldValue("isCreditPayment", true, true);
              formik.setFieldValue(
                "creditLimitDays",
                data?.manualQuotationDetail?.paymentTerms?.creditLimitDays,
                true
              );
              formik.setFieldValue(
                "paymentTermRadioValue",
                "isCreditPayment",
                true
              );
            }
            if (data?.manualQuotationDetail?.paymentTerms?.isSplitPayment) {
              formik.setFieldValue("isSplitPayment", true, true);
              formik.setFieldValue(
                "onArrivalPaymentPercentage",
                data?.manualQuotationDetail?.paymentTerms
                  ?.onArrivalPaymentPercentage ?? null,
                true
              );
              formik.setFieldValue(
                "onDeliveryPaymentPercentage",
                data?.manualQuotationDetail?.paymentTerms
                  ?.onDeliveryPaymentPercentage ?? null,
                true
              );
              formik.setFieldValue(
                "paymentTermRadioValue",
                "isSplitPayment",
                true
              );
              setPaymentTermRadioValue("isSplitPayment");
            }
            setViewProductData(data?.manualQuotationDetail);
          }
        },
        onError(error) {
          notification.error({ message: error.message });
        },
        fetchPolicy: "network-only",
      });
    },
    [!isAddOperation]
  );

  useEffect(() => {
    formik.setFieldValue("selectedShippingAddressfromExisting", "", true);
  }, [formik.values.shippingAddressFromRadioBtn]);

  useEffect(() => {
    if(isAddOperation){
      formik.setFieldValue("selectedSeller", "", true);
    }
  }, [formik.values.selectedCategory]);

  useEffect(() => {
    switch (paymentTermRadioValue) {
      case "isAdvancePayment":
        formik.setFieldValue("isAdvancePayment", true, true);
        formik.setFieldValue("isPaymentAgainstDelivery", false, true);
        formik.setFieldValue("isCreditPayment", false, true);
        formik.setFieldValue("isSplitPayment", false, true);
        break;

      case "isPaymentAgainstDelivery":
        formik.setFieldValue("isAdvancePayment", false, true);
        formik.setFieldValue("isPaymentAgainstDelivery", true, true);
        formik.setFieldValue("isCreditPayment", false, true);
        formik.setFieldValue("isSplitPayment", false, true);
        break;

      case "isCreditPayment":
        formik.setFieldValue("isAdvancePayment", false, true);
        formik.setFieldValue("isPaymentAgainstDelivery", false, true);
        formik.setFieldValue("isCreditPayment", true, true);
        formik.setFieldValue("isSplitPayment", false, true);
        // formik.setFieldValue("isCreditPayment", formik.values.creditLimitDays, true); // Uncomment this line if needed
        break;

      case "isSplitPayment":
        formik.setFieldValue("isAdvancePayment", false, true);
        formik.setFieldValue("isPaymentAgainstDelivery", false, true);
        formik.setFieldValue("isCreditPayment", false, true);
        formik.setFieldValue("isSplitPayment", true, true);
        // formik.setFieldValue("isSplitPayment", formik.values.onArrivalPaymentPercentage, true);
        // formik.setFieldValue("isSplitPayment", formik.values.onDeliveryPaymentPercentage, true); // Uncomment this line if needed
        break;

      default:
        formik.setFieldValue("isAdvancePayment", false, true);
        formik.setFieldValue("isPaymentAgainstDelivery", false, true);
        formik.setFieldValue("isCreditPayment", false, true);
        formik.setFieldValue("isSplitPayment", false, true);
        break;
    }
  }, [paymentTermRadioValue, isAddOperation]);

  useEffect(() => {
    if (
      productData &&
      typeof productData === "object" &&
      !Array.isArray(productData)
    ) {
      const updatedProducts = [
        ...(viewProductData?.products || []),
        productData,
      ];
      setViewProductData((prevData) => ({
        ...prevData,
        products: updatedProducts,
      }));
    }
  }, [productData]);

  useEffect(() => {
    if (
      additionalChargesData &&
      typeof additionalChargesData === "object" &&
      !Array.isArray(additionalChargesData)
    ) {
      const updatedAdditionalCharges = [
        ...(viewProductData?.additionalCharges || []),
        additionalChargesData,
      ];
      setViewProductData((prevData) => ({
        ...prevData,
        additionalCharges: updatedAdditionalCharges,
      }));
    }
  }, [additionalChargesData]);

  const [getManualQuotationGSTDetails,{ data: manualQuotationGSTDetailsData, loading: manualQuotationGSTDetailsLoading, refetch}] = useManualQuotationGstDetailsLazyQuery();
  const [ getUpdateManualQuotationConfirmationStatus,{ data: updateManualQuotationConfirmationStatusData, loading: updateManualQuotationConfirmationStatusLoading }] = useUpdateManualQuotationConfirmationStatusMutation();

  useEffect(() => {
    if (
      totalAmount !== undefined &&
      (isAddOperation || removeTypeNameWhileEditingProduct !== undefined)
    ) {
      let productId = isAddOperation
        ? removeHSNAtAddTime[0]?.productId
        : removeTypeNameWhileEditingProduct[0]?.productId;
      let variables = {
        productId: productId,
        totalAmount: Number(totalAmount),
      };
      
      let isSellerBuyerAddressSame = false; // Default value
      if (sellerStateId !== undefined && shipplingAddressStateId !== undefined && sellerStateId === shipplingAddressStateId && formik.values.billingAddresCheckBox) {
        isSellerBuyerAddressSame = true;
      }

      if (sellerStateId !== undefined && BillingAddressStateId !== undefined && sellerStateId === BillingAddressStateId && !formik.values.billingAddresCheckBox) {
        isSellerBuyerAddressSame = true;
      }

      if (!isAddOperation && sellerStateId !== undefined && viewProductData?.billingAddress?.stateId !== undefined && sellerStateId === viewProductData?.billingAddress?.stateId && formik.values.billingAddresCheckBox) {
        isSellerBuyerAddressSame = true;
      }

      if (sellerStateId !== undefined && addNewShippingAddress?.stateId !== undefined && addNewShippingAddress?.stateId && formik.values.shippingAddressFromRadioBtn && sellerStateId === addNewShippingAddress?.stateId
      ) {
        isSellerBuyerAddressSame = true;
      }

      if (sellerStateId !== undefined && addNewShippingAddress?.stateId !== undefined && addNewShippingAddress?.stateId && formik.values.billingAddresCheckBox && formik.values.shippingAddressFromRadioBtn && sellerStateId === addNewShippingAddress?.stateId
      ) {
        isSellerBuyerAddressSame = true;
      }

      if (sellerStateId !== undefined && addNewBillingAddress?.stateId !== undefined && addNewBillingAddress?.stateId && !formik.values.billingAddresCheckBox && formik.values.billingAddressFromRadioBtn && sellerStateId === addNewBillingAddress?.stateId
      ) {
        isSellerBuyerAddressSame = true;
      }
      variables.isSellerBuyerAddressSame = isSellerBuyerAddressSame;

      if (variables?.productId && variables?.totalAmount) {
        getManualQuotationGSTDetails({
          variables: variables,
          onCompleted: (data) => {
            setManualQuotationGSTDetails((prevValue) => ({
              ...prevValue,
              ...data?.manualQuotationGSTDetails,
            }));
          },
          fetchPolicy: "network-only",
        });
      }
    }
  }, [
    getUserSellerListForQuotation,
    totalAmount,
    getManualQuotationGSTDetails,
    formik.values.billingAddresCheckBox,
    formik.values.billingAddressFromRadioBtn,
    formik.values.selectedShippingAddressfromExisting
  ]);

  const confirmQuotationHandler = () => {
    getUpdateManualQuotationConfirmationStatus({
      variables: {
        id: params?.quotegenerationId,
      },
      onCompleted(data) {
        notification.success({
          message: data.updateManualQuotationConfirmationStatus.message,
        });
        navigate(LOCATIONS.QUOTE_GENERATION_ROUTES.ROOT);
        refetch();
      },
      onError(error) {
        notification.error({
          message: error.message,
        });
      },
    });
  };

  const [getUserBalance, { loading: getUserBalanceLoading }] = useGetUserBalanceLazyQuery();
  const [getHSNCodeListLazyQuery, { loading: hsnCodeListLoading }] = useHsnCodeListLazyQuery();

  const getUserBalanceList = useCallback((userIdArg, pageArg, limitArg) => {
      let variables = {};
      userIdArg && ( variables.userId = userIdArg || formik.values.selectedBuyer) ;
      pageArg && (variables.page = pageArg - 1);
      limitArg && (variables.limit = limitArg);
      getUserBalance({
        variables: variables,
        onCompleted(data) {
          setCreditDebitDetail(data?.getUserBalance?.totalAmount);
        },
        async update() {
          refetch();
        },
        fetchPolicy: "network-only",
      });
    },
    [selectedBuyer, getUserBalance]
  );

  const getHSNCodeList = React.useCallback(() => {
    getHSNCodeListLazyQuery({
      onCompleted(data) {
        setHsnCodeOption(data?.hsnCodeList)
      },  
      onError(error) {
        notification.error({
          message: error.message,
        });
      },
      fetchPolicy: "network-only"
    })
  }, [getHSNCodeListLazyQuery,setHsnCodeOption]);

  const handleNewShippingModalCancel = () => {
    if(Object.keys(newShippingAddress).length === 0){
      formik.setFieldValue("shippingAddressFromRadioBtn",false,true)
    }
    setNewShippingAddressModalVisible(false);
    setShouldResetForm(true);
  };

  const handleNewBillingModalCancel = () => {
    if(Object.keys(newBillingAddress).length === 0){
      formik.setFieldValue("billingAddressFromRadioBtn",false,true)
    }
    setNewBillingAddressModalVisible(false);
    setShouldResetForm(true);
  };

  const handleNewBuyerModalCancel = () => {
    if(Object.keys(newBuyer).length === 0){
      formik.setFieldValue("selectedBuyerRadioBtn",false,true)
    }
    setNewBuyerAddedModalVisible(false);
    setShouldResetForm(true);
  };

  useEffect(() => {
    getHSNCodeList();
  }, []);

  useEffect(() => {
    setNewBuyer({})
  }, [formik.values.selectedBuyerRadioBtn])

  useEffect(() => {
    setNewBillingAddress({})
  }, [formik.values.billingAddressFromRadioBtn])

  useEffect(() => {
    setNewShippingAddress({})
  }, [formik.values.shippingAddressFromRadioBtn])

  useEffect(() => {
    if (formik.values.selectedBuyer) {
      getUserBalanceList(formik.values.selectedBuyer, 1, 20);
    }
  }, [formik.values.selectedBuyer,isAddOperation]);

  useEffect(() => {
    if (manualQuotationGSTDetails?.totalOrderAmount < 0) {
      notification.error({
        message: "Final amount must be positive",
      });
    }
  }, [manualQuotationGSTDetails?.totalOrderAmount]);

  return (
    <Content className="content">
      <div className="page-header">
        <h2 className="page-title">
          {MODULE_TITLES.QUOTE_GENERATION_NAME.CREATE}
        </h2>
        <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
      </div>
      <div className="content-wrapper">
        <Card
          title={
            isAddOperation
              ? MODULE_TITLES.QUOTE_GENERATION_NAME.CREATE
              : MODULE_TITLES.QUOTE_GENERATION_NAME.EDIT
          }
          loading={
            manualQuotationDetailLoading ||
            updateManualQuotationConfirmationStatusLoading
          }
          extra={
            <Space size={16} align="end">
              <ConditionalComponent shouldShow={formik.values.selectedBuyer}>
                {viewProductData?.buyerCreditBalance}
                <Typography.Title level={5} >
                  Availablle Credit Balance : {getUserBalanceLoading ? <Spin spinning={getUserBalanceLoading} size="small"/> : `${creditDebitDetail} Rs/-`}
                </Typography.Title>
              </ConditionalComponent>
            </Space>
          }
        >
          <FormikProvider value={formik}>
            <Form
              labelAlign="left"
              layout="horizontal"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 12 }}
              labelWrap={true}>
              <FormItem
                id="selectedCategory"
                name="selectedCategory"
                label="Select Category"
                required
              >
                <Select
                  name="selectedCategory"
                  allowClear
                  placeholder="Select Category"
                  loading={categoryListLoading}
                  onChange={(value) => {
                    handleCategoryChange(value);
                  }}
                  disabled={!isAddOperation}
                >
                  {categoryListData?.categoryList
                    ?.filter(
                      (item) =>
                        item?.categoryName == "Yarn" ||
                        item?.categoryName === "Fabric"
                    )
                    .map((item) => (
                      <Select.Option value={item._id} key={item._id}>
                        {item.categoryName}
                      </Select.Option>
                    ))}
                </Select>
              </FormItem>

              <FormItem name="selectedSeller" label="Select Seller" required>
                <Select
                  name="selectedSeller"
                  allowClear
                  placeholder="Select Seller..."
                  onChange={(value) => {
                    setSelectedSeller(value);
                  }}
                  loading={userSellerListForQuotationLoading}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {userSellerListForQuotationData?.userListForQuotation.map(
                    (item) => (
                      <Select.Option value={item._id} key={item._id}>
                        {item?.businessName
                          ? `${item.name} [ ${item.businessName} ]`
                          : item.name}
                      </Select.Option>
                    )
                  )}
                </Select>
              </FormItem>

              <FormItem
                name="selectedBuyerRadioBtn"
                label="Select Buyer"
                required
              >
                <Radio.Group
                  name="selectedBuyerRadioBtn"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "selectedBuyerRadioBtn",
                      e.target.value
                    );
                    if (e.target.value && Object.keys(newBuyer).length === 0) {
                      setNewBuyerAddedModalVisible(true);
                    }
                  }}
                  value={formik.values.selectedBuyerRadioBtn}
                >
                  <Radio value={false}>
                    {RADIO_BUTTON_FOR_ADDRESS.Select_From_Existing}
                  </Radio>
                  <Radio value={true}>{RADIO_BUTTON_FOR_ADDRESS.Add_New}</Radio>
                </Radio.Group>
              </FormItem>
              <ConditionalComponent
                shouldShow={!formik.values.selectedBuyerRadioBtn}
              >
                <FormItem name="selectedBuyer" label="Select Buyer" required>
                  <Select
                    name="selectedBuyer"
                    allowClear
                    placeholder="Select Buyer..."
                    onChange={(value) => {
                      handleBuyerChange(value);
                    }}
                    loading={userListForQuotationLoading}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      const optionValue = option.children
                        ? String(option.children).toLowerCase()
                        : "";
                      const inputValue = input.toLowerCase();
                      return (
                        optionValue.indexOf(inputValue) >= 0 ||
                        String(option.value).indexOf(inputValue) >= 0
                      );
                    }}
                    disabled={!isAddOperation}
                  >
                    {userListForQuotationData?.userListForQuotation.map(
                      (item) => (
                        <Select.Option value={item._id} key={item._id}>
                          {item?.businessName
                            ? `${item.name} [${item.businessName}]`
                            : item.name}{" "}
                          {item.phoneNumber && (
                            <span>[{item.phoneNumber}]</span>
                          )}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </FormItem>
              </ConditionalComponent>

              <ConditionalComponent
                shouldShow={formik?.values?.selectedBuyerRadioBtn && newBuyer}
              >
                <Form.Item label="New Buyer" name="newBuyer">
                  <Typography>
                    {newBuyer?.createUser?.data?.name &&
                      newBuyer?.createUser?.data?.name}{" "}
                    Added Successfully
                  </Typography>
                </Form.Item>
              </ConditionalComponent>

              {/* Here Address Component Start */}
              <FormItem
                label="Select Shipping Address"
                name="shippingAddressFromRadioBtn"
                required
              >
                <Radio.Group
                  name="shippingAddressFromRadioBtn"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "shippingAddressFromRadioBtn",
                      e.target.value
                    );
                    if (
                      e.target.value &&
                      Object.keys(newShippingAddress).length === 0
                    ) {
                      setNewShippingAddressModalVisible(true);
                    }
                  }}
                  value={formik.values.shippingAddressFromRadioBtn}
                >
                  <Radio value={false}>
                    {RADIO_BUTTON_FOR_ADDRESS.Select_From_Existing}{" "}
                  </Radio>
                  <Radio value={true}>{RADIO_BUTTON_FOR_ADDRESS.Add_New}</Radio>
                </Radio.Group>
              </FormItem>

              <ConditionalComponent
                shouldShow={!formik.values.shippingAddressFromRadioBtn}>
                <FormItem
                  label="Select Shipping Address"
                  name="selectedShippingAddressfromExisting"
                  required
                >
                  <Select
                    name="selectedShippingAddressfromExisting"
                    allowClear
                    placeholder="Select Address..."
                    loading={userAddressesListForQuotationLoading}
                    onChange={(value) =>
                      setSelectedShippingAddressfromExisting(value)
                    }
                  >
                    {userAddressesListForQuotationData?.userAddressesListForQuotation.map(
                      (item) => (
                        <Select.Option value={item._id} key={item._id}>
                          {item?.fullAddress}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </FormItem>
              </ConditionalComponent>

              <ConditionalComponent
                shouldShow={
                  createShippingFullAddress &&
                  formik.values.shippingAddressFromRadioBtn
                }
              >
                <Form.Item
                  label="New Shipping Address"
                  name="newAddedShippingAddress"
                >
                  <Typography>
                    {createShippingFullAddress && createShippingFullAddress}
                  </Typography>
                </Form.Item>
              </ConditionalComponent>

              <Form.Item
                label="Billing Address"
                name="billingAddresCheckBox"
                required
              >
                <Checkbox
                  name="billingAddresCheckBox"
                  checked={formik.values.billingAddresCheckBox}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "billingAddresCheckBox",
                      e.target.checked
                    );
                  }}
                  defaultChecked={true}
                >
                  Same As Shipping Adddress
                </Checkbox>
              </Form.Item>

              <ConditionalComponent
                shouldShow={!formik.values.billingAddresCheckBox}>
                <FormItem
                  label="Select Billing Address"
                  name="billingAddressFromRadioBtn"
                >
                  <Radio.Group
                    name="billingAddressFromRadioBtn"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "billingAddressFromRadioBtn",
                        e.target.value
                      );
                      if (
                        e.target.value &&
                        Object.keys(newBillingAddress).length === 0
                      ) {
                        setNewBillingAddressModalVisible(true);
                      }
                    }}
                    value={formik.values.billingAddressFromRadioBtn}
                  >
                    <Radio value={false}>
                      {RADIO_BUTTON_FOR_ADDRESS.Select_From_Existing}
                    </Radio>
                    <Radio value={true}>
                      {RADIO_BUTTON_FOR_ADDRESS.Add_New}
                    </Radio>
                  </Radio.Group>
                </FormItem>
              </ConditionalComponent>

              <ConditionalComponent
                shouldShow={
                  !formik.values.billingAddressFromRadioBtn &&
                  !formik.values.billingAddresCheckBox
                }
>
                <FormItem
                  label="Select Billing Address"
                  name="selectBillingAddressfromExisting"
                  required
                >
                  <Select
                    name="selectBillingAddressfromExisting"
                    allowClear
                    placeholder="Select Billing Address..."
                    loading={userAddressesListForQuotationLoading}
                    onChange={(value) =>
                      setSlectedBillingAddressFromExisting(value)
                    }
                  >
                    {userAddressesListForQuotationData?.userAddressesListForQuotation.map(
                      (item) => (
                        <Select.Option value={item._id} key={item._id}>
                          {item?.fullAddress}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </FormItem>
              </ConditionalComponent>

              <ConditionalComponent
                shouldShow={
                  createBillingFullAddress &&
                  formik.values.billingAddressFromRadioBtn
                }
              >
                <Form.Item
                  label="New Billing Address"
                  name="newAddedBillingAddress"
                >
                  {" "}
                  <Typography>
                    {createBillingFullAddress && createBillingFullAddress}
                  </Typography>
                </Form.Item>
              </ConditionalComponent>

              <Form.Item label="Broker Name" name="brokerName">
                <Input
                  type="text"
                  id="brokerName"
                  name="brokerName"
                  placeholder="Broker Name..."
                  suffix={<span />}
                  onChange={handleBrokerNameChange}
                />
              </Form.Item>

              <Form.Item
                label="Broker Contact Number"
                name="brokerContactNumber"
              >
                <Input
                  type="number"
                  id="brokerContactNumber"
                  name="brokerContactNumber"
                  placeholder="Broker Contact Number..."
                  suffix={<span />}
                  onChange={handleBrokerContactNumberChange}
                />
              </Form.Item>

              <Form.Item label="Transport Name" name="transportName">
                <Input
                  type="text"
                  id="transportName"
                  name="transportName"
                  placeholder="Transport Name..."
                  suffix={<span />}
                  onChange={(e) =>
                    formik.setFieldValue("transportName", e.target.value, true)
                  }
                />
              </Form.Item>

              <Form.Item label="Vehicle Number" name="vehicleNumber">
                <Input
                  type="text"
                  id="vehicleNumber"
                  name="vehicleNumber"
                  placeholder="Vehicle Number..."
                  suffix={<span />}
                  onChange={(e) =>
                    formik.setFieldValue("vehicleNumber", e.target.value, true)
                  }
                />
              </Form.Item>

              <Form.Item label="Terms Of Delivery" name="deliveryType" required>
                <Select
                  name="deliveryType"
                  style={{ minWidth: 200 }}
                  allowClear
                  placeholder="Delivery Terms..."
                  options={DELIVERY_TERMS_FOR_QUOTATION}
                />
              </Form.Item>
            
              <PaymentTerms
                  formik={formik}
                  paymentTermRadioValue={paymentTermRadioValue}
                  handleRadioChange={handleRadioChange}
              />

              <ConditionalComponent
                shouldShow={
                  (formik.values.selectedCategory &&
                    formik.values.selectedSeller && formik.values.selectedBuyer && 
                    formik.values.deliveryType) 
                }
              >
                <Button
                  onClick={handleAddProductClick}
                  type="primary"
                  style={{ marginBottom: 16 }}
                  disabled={viewProductData?.isQuotationConfirmed }
                >
                  Add Product
                </Button>

                {/* <Table
                  dataSource={
                    !isAddOperation ? viewProductData?.products : productData
                  }
                  columns={columns}
                  rowClassName="editable-row"
                  pagination={false}
                  // rowKey={getTableRowKey}
                /> */}
                <Table
                  dataSource={
                    (!isAddOperation
                      ? viewProductData?.products
                      : productData
                    )?.map((item, index) => ({ ...item, key: `${index}_${index+1}` }))
                  }
                  columns={columns}
                  rowClassName="editable-row"
                  pagination={false}
                />

                <Col align="end" style={{ margin: "12px 15px 0px 0px" }}>
                  <strong>
                    Gross Amount : {sumOfProductAmount ? Number(sumOfProductAmount) : 0}{" "}
                    RS
                  </strong>
                </Col>
                <Divider />
              </ConditionalComponent>

              <ConditionalComponent
                shouldShow={
                  viewProductData?.products?.length > 0 ||
                  productData?.length > 0
                }
              >
                <Button
                  onClick={handleAdddditionalChargesClick}
                  type="primary"
                  className="mt-27 mb-27"
                  style={{
                    marginBottom: 16,
                  }}
                  disabled={viewProductData?.isQuotationConfirmed}
                >
                  Additional Charges
                </Button>

                {/* <Table
                  dataSource={
                    !isAddOperation
                      ? viewProductData?.additionalCharges
                      : additionalChargesData
                  }
                  columns={columnsForAdditionalCharges}
                  rowClassName="editable-row"
                  pagination={false}
                  key={Math.random().toString(16).slice(2)}
                /> */}
                <Table
                  dataSource={
                    (!isAddOperation
                      ? viewProductData?.additionalCharges
                      : additionalChargesData
                    )?.map((item, index) => ({ ...item, key: `${index}_${index+1}` }))
                  }
                  columns={columnsForAdditionalCharges}
                  rowClassName="editable-row"
                  pagination={false}
                />


                <Col align="end" style={{ margin: "12px 15px 12px 0px" }}>
                  <strong>
                    Additional Amount :{" "}
                    {QuotationAdditionalAmount && QuotationAdditionalAmount?.toFixed(2)} RS
                  </strong>
                </Col>

                <Divider />

                <ConditionalComponent
                  shouldShow={manualQuotationGSTDetails?.igstPercentage > 0}
                >
                  <Col align="end" style={{ margin: "12px 15px 0px 0px" }}>
                    <strong>
                      {`IGST ${manualQuotationGSTDetails?.igstPercentage}% : ${manualQuotationGSTDetails?.igstAmount} RS`}
                    </strong>
                  </Col>
                </ConditionalComponent>

                <ConditionalComponent
                  shouldShow={manualQuotationGSTDetails?.roundOffAmount > 0}
                >
                  <Col align="end" style={{ margin: "12px 15px 0px 0px" }}>
                    <strong>
                      {`Round Off${" "}: ${
                        manualQuotationGSTDetails?.roundOffType === "LESS"
                          ? "(-)"
                          : "(+)"
                      }
                        ${manualQuotationGSTDetails?.roundOffAmount}
                      `}
                    </strong>
                  </Col>
                </ConditionalComponent>

                <ConditionalComponent
                  shouldShow={manualQuotationGSTDetails?.cgstPercentage > 0}
                >
                  <Col align="end" style={{ margin: "12px 15px 0px 0px" }}>
                    <strong>
                      {`CGST ${manualQuotationGSTDetails?.cgstPercentage}% : ${manualQuotationGSTDetails?.cgstAmount} RS`}
                    </strong>
                  </Col>
                </ConditionalComponent>

                <ConditionalComponent
                  shouldShow={manualQuotationGSTDetails?.sgstAmount > 0}
                >
                  <Col align="end" style={{ margin: "12px 15px 0px 0px" }}>
                    <strong>
                      {`SGST ${manualQuotationGSTDetails?.sgstPercentage}% : ${manualQuotationGSTDetails?.sgstAmount} RS`}
                    </strong>
                  </Col>
                </ConditionalComponent>

                <Col align="end" style={{ margin: "12px 15px 12px 0px" }}>
                  <strong>
                    Total Amount :{" "}
                    {manualQuotationGSTDetails?.totalOrderAmount
                      ? manualQuotationGSTDetails?.totalOrderAmount
                      : viewProductData?.totalOrderAmount}{" "}
                    RS
                  </strong>
                </Col>

                <Divider />
              </ConditionalComponent>

              <Form.Item
                label="Quotation Declaration"
                name="quotationDescription"
                required
              >
                <Input.TextArea
                  autoSize={{ minRows: 5, maxRows: 10 }}
                  type="text"
                  id="quotationDescription"
                  name="quotationDescription"
                  maxLength={400}
                  placeholder="Quotation Declaration..."
                  showCount
                />
              </Form.Item>

              <Form.Item label="Terms & Conditions" name="termsAndConditions">
                <Input.TextArea
                  name="termsAndConditions"
                  autoSize={{ minRows: 5, maxRows: 10 }}
                  value={(quotation || [])?.join("\n")}
                  onChange={handleTextAreaChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setQuotation([...quotation, ""]); // Add an empty line when Enter is pressed
                    }
                  }}
                  maxLength={400}
                  placeholder="Terms & Conditions..."
                  showCount
                />
              </Form.Item>

              {isModalVisible && (
                <Modal
                  title="Add Product"
                  visible={isModalVisible}
                  footer={null}
                  centered
                  onCancel={handleModalCancel}
                >
                  <AddEditProductQuotation
                    categoryId={formik.values.selectedCategory}
                    userId={
                      // formik.values.selectedBuyer ||
                      // newBuyer?.createUser?.data?._id
                      formik.values.selectedSeller
                    }
                    onCancel={handleModalCancel}
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    productData={productData}
                    setProductData={setProductData}
                    isAddOperation={isAddOperation}
                    setAdditionalChargesData={handleAdditionalChargesUpdate}
                    hsnCodeOptions={hsnCodeOptions}
                    hsnCodeListLoading={hsnCodeListLoading}
                  />
                </Modal>
              )}

              <Modal
                title="Additional Charges"
                visible={isAdditionalChargeModalVisible}
                footer={null}
                centered
                onCancel={handleAdditionalChargesModalCancel}
              >
                {isAdditionalChargeModalVisible && (
                  <AddEditAdditionalCharges
                    onCancel={handleAdditionalChargesModalCancel}
                    setIsAdditionalChargeModalVisible={
                      setIsAdditionalChargeModalVisible
                    }
                    additionalChargesData={additionalChargesData}
                    setAdditionalChargesData={setAdditionalChargesData}
                    isAddOperation={isAddOperation}
                  />
                )}
              </Modal>

              <Modal
                title="Add New Shipping Address"
                visible={isNewShippingAddressModalVisible}
                footer={null}
                centered
                onCancel={handleNewShippingModalCancel}
              >
                {isNewShippingAddressModalVisible && (
                  <AddNewShippingAddress
                    shouldResetForm={shouldResetForm}
                    setShouldResetForm={setShouldResetForm}
                    isNewShippingAddressModalVisible={
                      isNewShippingAddressModalVisible
                    }
                    setNewShippingAddressModalVisible={
                      setNewShippingAddressModalVisible
                    }
                    newShippingAddress={newShippingAddress}
                    setNewShippingAddress={setNewShippingAddress}
                  />
                )}
              </Modal>

              <Modal
                title="Add New Billing Address"
                visible={isNewBillingAddressModalVisible}
                footer={null}
                centered
                onCancel={handleNewBillingModalCancel}
              >
                {isNewBillingAddressModalVisible && (
                  <AddNewBillingAddress
                    shouldResetForm={shouldResetForm}
                    setShouldResetForm={setShouldResetForm}
                    setNewBillingAddressModalVisible={
                      setNewBillingAddressModalVisible
                    }
                    newBillingAddress={newBillingAddress}
                    setNewBillingAddress={setNewBillingAddress}
                  />
                )}
              </Modal>

              <Modal
                title="Add New Buyer"
                visible={isNewBuyerAddedModalVisible}
                footer={null}
                centered
                onCancel={handleNewBuyerModalCancel}
              >
                {isNewBuyerAddedModalVisible && (
                  <AddNewBuyer
                    shouldResetForm={shouldResetForm}
                    setShouldResetForm={setShouldResetForm}
                    isNewBuyerAddedModalVisible={isNewBuyerAddedModalVisible}
                    setNewBuyerAddedModalVisible={setNewBuyerAddedModalVisible}
                    newBuyer={newBuyer}
                    setNewBuyer={setNewBuyer}
                  />
                )}
              </Modal>

              <Row
                className="text-right mt-18"
                style={!isAddOperation ? { gap: "12px" } : {}}
              >
                <Col md={12}>
                  <SubmitButton
                    loading={formik.isSubmitting}
                    type="primary"
                    htmlType="submit"
                    disabled={isAddOperation ? productData.length <= 0 : viewProductData?.products?.length <= 0   
                      || viewProductData?.isQuotationConfirmed || manualQuotationGSTDetailsLoading }
                  > 
                    {MODULE_TITLES.COMMON.SAVE}
                  </SubmitButton>
                </Col>
                <ConditionalComponent shouldShow={!isAddOperation}>
                  <Col>
                    <Popconfirm
                      placement="bottomRight"
                      title={UNIQUE_MESSAGES.CONFIRM_POPUP("Quotation")}
                      onConfirm={() => confirmQuotationHandler()}
                      okText="Yes"
                      cancelText="No"
                      disabled={viewProductData?.isQuotationConfirmed}
                    >
                      <Button
                        loading={false}
                        disabled={
                          viewProductData?.products?.length <= 0 ||
                          viewProductData?.isQuotationConfirmed ||
                          manualQuotationGSTDetails?.totalOrderAmount <= 0 || manualQuotationGSTDetailsLoading
                        }
                      >
                        {viewProductData?.isQuotationConfirmed
                          ? "Quotation Confirmed"
                          : "Confirm Quotation "}
                      </Button>
                    </Popconfirm>
                  </Col>
                </ConditionalComponent>
              </Row>

            </Form>
          </FormikProvider>
        </Card>
      </div>
    </Content>
  );
};
export default AddNewEditQuotation;
