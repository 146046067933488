import * as Yup from "yup";

export const BANNER_PROMOTION_TYPES = [
    { label: "TOP BANNER", value: "TOP_BANNER" },
    { label: "COLLECTION BANNER", value: "COLLECTION_BANNER" },
    { label: "DEAL OF THE DAY BANNER", value: "DEAL_OF_THE_DAY_BANNER" },
    { label: "POPULAR BRANDS BANNER", value: "POPULAR_BRANDS_BANNER" },
];

export const PRODUCT_PROMOTION_TYPES = [
    { label: "TRENDING PRODUCTS", value: "TRENDING_PRODUCTS" },
    { label: "SIMILAR PRODUCTS", value: "SIMILAR_PRODUCTS" },
    { label: "PRODUCT GRID", value: "PRODUCT_GRID" },
    { label: "RECOMMENDED PRODUCTS", value: "RECOMMENDED_PRODUCTS" },
];

export const BulkPriceSectionSchema = Yup.object().shape({
    price: Yup.number()
        .min(1, `Price must be greater than 1`).required(),
    days: Yup.number()
        .min(1, `Days must be greater than 1`).required()
})

export const BulkPriceSchema = Yup.object().shape({
    price: Yup.number()
        .min(1, `Price must be greater than 1`)
        .test(
            "uniquePrices",
            "Bulk Price should not have duplicate prices",
            preventDuplicate
        ).test(
            "nonEmptyCheck",
            "Bulk Price must have some value",
            nonEmptyCheck
        ),
    days: Yup.number()
        .min(1, `Days must be greater than 1`)
        .test(
            "uniqueDays",
            "Days should not have duplicate Days",
            preventDuplicate)
        .test(
            "nonEmptyCheck",
            "Days must have some value",
            nonEmptyCheck
        )
});

/**
 * 
 * @param {number | undefined} _value 
 * @param {Yup.TestContext} context 
 * @returns 
 */
export function notlessThanBulkPrice(_value, context) {
    const items = getItemsFromContext(context, 'bulkPrice', 0);
    const perDayPrice = getItemsFromContext(context, 'perDayPrice', 0);
    if (perDayPrice && items && items.length > 0) {
        const valid = items.every((v) => typeof v.price === "undefined" || perDayPrice > v.price);
        if (!valid) {
            return context.createError({
                path: 'perDayPrice',
                message: 'Per day Price must be greater than Bulk Price options'
            })
        }
    }

    return true
}

/**
 * 
 * @param {number | undefined} _value 
 * @param {Yup.TestContext} context 
 * @returns 
 */
function preventDuplicate(_value, context) {
    const items = getItemsFromContext(context, 'bulkPrice', 1);
    const field = context.path.split('.')[1]
    if (_value && items) {
        const valueSet = new Set();
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (valueSet.has(item[field]) && _value === item[field]) {
                return context.createError({
                    path: context.path,
                    message: "Duplicate value",
                });
            }
            valueSet.add(item[field]);
        }
    }
    return true;
};

/**
 * 
 * @param {number | undefined} _value 
 * @param {Yup.TestContext} context 
 * @returns  
 */
// ! issue here . shows error in both fields 
function nonEmptyCheck(_value, context) {
    const items = getItemsFromContext(context, 'bulkPrice', 1);

    if (Array.isArray(items)) {
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (i === 0) {
                let hasOneValue = [typeof (item.price), typeof (item.days)].filter(i => i === 'undefined').length === 1;
                if (hasOneValue && !_value) {
                    return context.createError({
                        path: context.path,
                        message: "Cannot be empty",
                    });
                }
            } else {
                if (typeof _value !== "number") {
                    return context.createError({
                        path: context.path,
                        message: "Cannot be empty",
                    });
                }
            }
        }
    }
    return true;
};

/**
 * 
 * @param {Yup.TestContext} context 
 * @param {string} key
 * @returns 
 */
function getItemsFromContext(context, key, contextIndex) {
    if (context.from && context.from.length) {
        const values = context.from?.[contextIndex]?.value;
        return values?.[key];
    }
    return null;
}

/**
 * 
 * @param {string} advertisementType 
 * @returns 
 */
export function sectionTypeWhenValidation(advertisementType) {
    if (advertisementType === "PRODUCT_ADVERTISEMENT") {
        return Yup.string().oneOf(
            PRODUCT_PROMOTION_TYPES.map((v) => v.value),
            "Select Proper Advertisment Type"
        )
            .required();
    } else if (advertisementType === "BANNER_ADVERTISEMENT") {
        return Yup.string().oneOf(
            BANNER_PROMOTION_TYPES.map((v) => v.value),
            "Select Proper Advertisment Type"
        ).required();
    } else Yup.string().required();
}

/**
 * 
 * @param {React.ChangeEvent<HTMLInputElement>} e 
 */
export function handleOnChange(e, shouldValidate = false) {
    const { name, value } = e.target;
    this.setFieldValue(name, +value, shouldValidate)
}

