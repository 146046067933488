import { AUTH_TYPES } from "../../config/actionTypes";
import { getProcessedRoles } from "../../helper/AuthHelper";

function login(userAllData) {
  return (dispatch) => {
    let userData = userAllData;
    // userData.processedRoles = getRoles(userAllData);
    userData.processedRoles = getProcessedRoles(userAllData);

    dispatch({
      type: AUTH_TYPES.LOGIN,
      payload: { userData: userData },
    });
  };
}

function updateProfile(userData) {
  return (dispatch) => {
    dispatch({
      type: AUTH_TYPES.EDIT,
      payload: { userData: userData }
    });
  };
}

function logout() {
  return (dispatch) => {
    dispatch({
      type: AUTH_TYPES.LOGOUT,
      payload: { userData: null },
    });
  };
}

function refresAccessToken(token){
  return (dispatch) =>{
    dispatch({
      type: AUTH_TYPES.REFRESH_TOKEN,
      payload: {
        token
      }
    })
  }
}

 function updateSellerRegistration (isAllowed){
  return (dispatch)=>{
    dispatch({
  type: AUTH_TYPES.UPDATE_SELLER_REGISTRATION,
  payload: {
    isSellerRegistrationAllowed: isAllowed,
  },
    })
  }
};

export const userActions = {
  login,
  updateProfile,
  logout,
  updateSellerRegistration,
};
