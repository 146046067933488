export function commonValidationMessage(fieldName) {
  return `${fieldName} is required`;
}

export const showErrorInField = (error) => {
  return error ? { validateStatus: "error" } : null;
};

export function generateMessage(message = "", params = {}) {
  const keys = Object.keys(params);
  let messageWithParams = message;
  for (let key of keys) {
    messageWithParams = messageWithParams.replace(`{{${key}}}`, params[key]);
  }
  return messageWithParams;
}

export const UNIQUE_MESSAGES = {
  ADMIN: {
    confirmNewPassword: "Password and Confirm Password must match",
    forgotPassword: "Password sent successfully on your email",
  },
  IMAGE: {
    ERROR: {
      SIZE: (limit) => `Please upload an image smaller than ${limit}`,
      FILE_TYPE: `Please upload an image only`,
    },
  },
  DELETE_POPUP: (fieldName) =>
    `Are you sure want to delete this ${fieldName} ?`,
  CONFIRM_POPUP: (fieldName) =>
    `Are you sure want to confirm this ${fieldName} ?`,
  COMPLETE_POPUP: (fieldName) =>
    `Are you sure want to complete this ${fieldName} ?`,
  DOWNLOAD_POPUP: (fieldName) => `Do you want to download this ${fieldName} ?`,
  CANCLE_POPUP: (fieldName) =>
    `Are you sure want to cancle this ${fieldName} ?`,
  APPROVE_MESSAGE: (fieldName) =>
    `Are you sure want to approve this ${fieldName} ?`,
  BLOCK_POPUP: (fieldName) => `Are you sure want to block this ${fieldName} ?`,
  REJECT_POPUP: (fieldName) =>
    `Are you sure want to reject this ${fieldName} ?`,
  NO_SUBCATEGORY: "No sub category data available",
  APPROVE_POPUP: "Please Confirm Approval of Document",
  APPROVE_PRODUCT: "Please Confirm Approval of Product",
  RESOLVE_POPUP: "Please Confirm that you want to Mark this issue as Resolved",
  ERP_ACCESS_POPUP: (fieldName, isAllowErpAccess) =>
    `${
      isAllowErpAccess === false
        ? "Are you sure want to allow"
        : "Are you sure want to disallow"
    } ${fieldName}?`,
};
