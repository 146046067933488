
import React, { useEffect } from "react";
import { Content } from "antd/lib/layout/layout";
import { MODULE_TITLES } from "../../../config/moduleConfig";
import { FormikProvider, useFormik } from "formik";
import { Card, Col} from "antd";
import * as Yup from "yup";
import { Form, Input, SubmitButton, Select } from "formik-antd";
import { commonValidationMessage } from "../../../helper/MessagesHelper";

const TYPE_OF_CHARGES = [
    { label: "ADD", value: "ADD" },
    { label: "LESS", value: "LESS" },
]
const AddEditAdditionalCharges = ({onCancel,isAddOperation,setAdditionalChargesData,setIsAdditionalChargeModalVisible}) => {
    const quoationAdditionalChargesSchema = Yup.object().shape({
        type: Yup.string()
            .required('Type of charge is required')
            .oneOf(['ADD', 'LESS'], 'Invalid charge type. Please select either Add or Less.'),
        title: Yup.string().required(commonValidationMessage('Title')),
        amount: Yup.number().required(commonValidationMessage('Amount')),
        quotationDescription: Yup.string().optional().nullable()
    });
    const formik = useFormik({
        initialValues: {
            type: "",
            title: "",
            amount: "",
        },
        validateOnChange: true,
        validateOnBlur: true,
        validationSchema: quoationAdditionalChargesSchema,
        onSubmit: (values, actions) => {
            if (isAddOperation) {
                setAdditionalChargesData(prevData => [...prevData, values]); 
              }else{
                setAdditionalChargesData(values)
              }
            actions.setSubmitting(false);
            setIsAdditionalChargeModalVisible(false) 
            formik.resetForm();
        }
    });
    useEffect(()=>{
        formik.setFieldValue("type",'',true)
    },[onCancel])

    return (
        <Card>
        <Content className="content">
            <div className="content-wrapper">
                    <FormikProvider value={formik}>
                        <Form
                            labelAlign="left"
                            labelWrap={true}
                            name="add-edit-category-page-form"
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 18 }}
                            onFinish={formik.handleSubmit}
                        >
                            <Form.Item label="Type [Add/Less]"
                                name="type"
                                required>
                                <Select
                                    name="type"
                                    style={{ minWidth: 200 }}
                                    allowClear
                                    placeholder="Type of charge..."
                                    options={TYPE_OF_CHARGES}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Title"
                                name="title"
                                required
                            >
                                <Input
                                    type="text"
                                    id="title"
                                    name="title"
                                    onChange={formik.handleChange}
                                    value={formik.values.title}
                                    placeholder="Title..."
                                    suffix={<span />}

                                />
                            </Form.Item>
                            <Form.Item
                                label="Amount"
                                name="amount"
                                required
                            >
                                <Input
                                    type="number"
                                    id="amount"
                                    name="amount"
                                    onChange={(e) => formik.setFieldValue('amount', Number(e.target.value.slice(0, 6)))}
                                    value={formik.values.amount}
                                    placeholder="Amount..."
                                    suffix={<span />}
                                    maxLength={7}
                                />
                            </Form.Item>
                            <Col sm={12} md={14} className="text-right mt-12px" >
                                <SubmitButton
                                    loading={formik.isSubmitting}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {MODULE_TITLES.COMMON.SAVE}
                                </SubmitButton>
                            </Col>
                        </Form>
                    </FormikProvider>
            </div>
        </Content>
        </Card>
    );
};

export default AddEditAdditionalCharges;
