import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import ApolloProvider from './graphql/ApolloProvider/index';

import App from './App';
import { store } from './redux/store/store';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <React.StrictMode>
      <ApolloProvider>
         <Provider store={store}>
            <App />
         </Provider>
      </ApolloProvider>
   </React.StrictMode>
);