import React from 'react';
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import { getSideBarItemsByRole } from '../../helper/SideBarHelper';
import { connect } from 'react-redux';
import { sidebarLocations, side_menu_items } from './sidebarData';

const { Sider } = Layout;


const Sidebar = (props) => {
    const [selectedKey, setSelectedKey] = React.useState([]);
    const [openKeys, setOpenKeys] = React.useState();
    const navigate = useNavigate();
    const location = useLocation();
    const side_menu_items_by_role = React.useMemo(() => getSideBarItemsByRole(props.userData.processedRoles, side_menu_items)
        , [props.userData.processedRoles]);

    const handleMenuChangeEffect = React.useCallback(() => {
        let currentModule;
        Object.keys(sidebarLocations).forEach(key => {
            if (!currentModule && location.pathname.includes(key)) {
                currentModule = sidebarLocations[key];
            }
        });

        if (currentModule) {
            if (currentModule.length > 1) {
                setOpenKeys(currentModule)
            }
            selectedKey.toString() !== currentModule.toString() && setSelectedKey(currentModule)
        } else {
            setSelectedKey('')
        }
    }, [location, selectedKey, setSelectedKey, setOpenKeys]);

    React.useEffect(handleMenuChangeEffect, [location.pathname])

    const onClick = React.useCallback((e) => {
        setSelectedKey(e.keyPath)
        navigate(e.key)
    }, [navigate, setSelectedKey]);

    // const handleOpenKeyChange = React.useCallback((openKeys) => setOpenKeys(openKeys),[setOpenKeys]);
    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys?.indexOf(key) );
          setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      };

    return (

        <Sider
            className='sidebar'
            breakpoint='lg'
            collapsedWidth='0'
        >

            <Menu
                theme='dark'
                mode='inline'
                onClick={onClick}
                defaultSelectedKeys={['Dashboard']}
                openKeys={openKeys}
                selectedKeys={selectedKey}
                // onOpenChange={handleOpenKeyChange}
                onOpenChange={onOpenChange}
                items={side_menu_items_by_role}
            />

        </Sider>
    );
}


const mapStateToProps = (state) => ({
    loggedIn: state.authentication.loggedIn,
    userData: state.authentication.userData
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
