import React from 'react'
import { ApolloProvider} from '@apollo/client';
import client from './client';

const index = (props) => {

    return (
        <ApolloProvider client={client}>
            {props.children}
        </ApolloProvider>
    )
}

export default index