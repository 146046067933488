import { Content } from "antd/lib/layout/layout";
import { MODULE_TITLES } from "../../../config/moduleConfig";
import { FormikProvider, useFormik } from "formik";
import { Button, Card, Col, Empty, Image, Modal, Row, notification } from "antd";
import * as Yup from "yup";
import { Form, Input, SubmitButton,Select } from "formik-antd";
import { commonValidationMessage } from "../../../helper/MessagesHelper";
import { SearchOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { useProductListForQuotationLazyQuery } from "../../../graphql/generated/index.tsx";
import { default_s } from "../../../helper/importHelper/image";
import { usePagination } from '../../../context/PaginationContext';
import { ConditionalComponent } from "../../common/GeneralComponents.js";

const AddEditProductQuotation = ({ userId, categoryId, setIsModalVisible, isAddOperation, setProductData ,hsnCodeOptions,hsnCodeListLoading}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchProductValue, setSearchProductValue] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productList, setProductList] = useState('')
  const { pageValues, handleSearchChange } = usePagination();
  const [inputValue, setInputValue] = useState('');

  const [getProductListForQuotation, { loading: productListForQuotationLoading }] = useProductListForQuotationLazyQuery();
  const getProductList = React.useCallback((searchProductValue) => {
    let variables = {
      userId: userId,
      categoryId: categoryId,
    };
    searchProductValue && (variables.search = searchProductValue);
    getProductListForQuotation({
      variables: variables,
      onCompleted(data) {
        setProductList(data?.productListForQuotation)
      },
      onError(error) {
        notification.error({
          message: error.message,
        });
      },
      fetchPolicy: "network-only",
    })
  }, [pageValues,searchProductValue]);

  React.useEffect(() => {
      getProductList(searchProductValue);
  }, [pageValues,setSearchProductValue,isModalOpen,searchProductValue]);
  useEffect(()=>{

  },[])


  const createQuoationSchema = Yup.object().shape({
    productName: Yup.string().required(commonValidationMessage("Product Name")),
    // hsnCode:Yup.string().required(commonValidationMessage("HSN")),
    quantity: Yup.number().typeError('Quantity must be a number').required('Quantity is required').integer('Quantity must be an integer').positive('Quantity must be a positive number').max(selectedProduct?.availableQuantity, `Quantity can't be more then ${selectedProduct?.availableQuantity}`),
    // unitPrice: Yup.number().required(commonValidationMessage("Unit Price")).positive('Unit Price must be a positive number'),
    totalAmount: Yup.number().required(commonValidationMessage("totalAmount")),
    unitPrice:Yup.number()
    .required(commonValidationMessage("Unit Price"))
    .positive('Unit Price must be a positive number')
    .test({
      name: 'decimal-precision',
      message: 'Unit Price must be a whole number or have up to 2 decimal places only',
      test: (value) => {
        if (value === undefined || value === null) {
          return true; // Skip validation if value is not provided
        }
        // Check if the value is a whole number or has up to 2 decimal places
        return Number.isInteger(value) || (value.toString().split('.')[1]?.length || 0) <= 2;
      },
    })  
  });

  const formik = useFormik({
    initialValues: {
      productName: "",
      // hsnCode:'',
      quantity: "",
      unit: "",
      unitPrice: "",
      totalAmount: "",
    },
    validationSchema: createQuoationSchema,
    onSubmit: (values, actions) => {
      if (isAddOperation) {
        setProductData(prevData => [...prevData, values]);
      } else {
        setProductData(values)
      }
      actions.setSubmitting(false);
      setIsModalVisible(false)
      formik.resetForm();
      setSelectedProduct()
    },
  });

  const calculateAmount = (quantity, unitPrice) => {
    const parsedQty = parseFloat(quantity);
    const parsedPrice = parseFloat(unitPrice);
    if (!isNaN(parsedQty) && !isNaN(parsedPrice)) {
      return parsedQty * parsedPrice;
    }
    return "";
  };

  useEffect(() => {
    const calculatedAmount = calculateAmount(
      formik.values.quantity,
      formik.values.unitPrice
    );
    if (typeof calculatedAmount === 'number' && !isNaN(calculatedAmount)) {
      const uptoTwoDecimalTotal = calculatedAmount.toFixed(2);
      formik.setFieldValue("totalAmount", uptoTwoDecimalTotal);
    } 
  }, [formik.values.quantity, formik.values.unitPrice]);

  const showModal = () => {
    setSearchProductValue("")
    setIsModalOpen(true);
  };

  const handleOnRowSelection = (clickedItem) => {
    formik.setFieldValue("productId", clickedItem?._id, true);
    formik.setFieldValue("productName", clickedItem?.productName, true);
    formik.setFieldValue("productDescription", clickedItem?.productDescription ? clickedItem?.productDescription : "", true);
    formik.setFieldValue("hsnCode", clickedItem?.hsnCode, true);
    formik.setFieldValue("hsnCodeId", clickedItem?.hsnCodeId, true);
    formik.setFieldValue("availableQuantity", clickedItem?.availableQuantity, true);
    formik.setFieldValue("unit", clickedItem?.unit, true);
    setSelectedProduct(clickedItem);
    setIsModalOpen(false);
  };

  const handlehsnCodeChange = (value, option) => {
    formik.setFieldValue("hsnCode", option?.label)
     formik.setFieldValue("hsnCodeId", option?.value, true);
  };
  
  const handleChange = (e) => {
    // Directly update the state and Formik field value without rounding
    const value = e.target.value;
    setInputValue(value);
    formik.setFieldValue('unitPrice', value);
  };
  
  const handleBlur = () => {
    let value = inputValue;
    if (value.includes('.') && value.split('.')[1].length > 2) {
      value = Number(value).toFixed(2);
    }
    setInputValue(value);
    formik.setFieldValue('unitPrice', value);
  };
  
  const handleCancel = () => {
    setSearchProductValue("");
    setIsModalOpen(false);
  };

  return (
    <Content className="content">
      <div className="content-wrapper">
        <Card loading={productListForQuotationLoading}>
          <FormikProvider value={formik}>
            <Form
              labelAlign="left"
              labelWrap={true}
              name="add-edit-category-page-form"
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 18 }}
              onFinish={formik.handleSubmit}
            >
              <Form.Item label="Product Name" name="productName" required>
                <Button
                  type="default"
                  onClick={showModal}
                  style={{ width: "100%" }}
                >
                  Select Product
                </Button>
              </Form.Item>

              <ConditionalComponent shouldShow={selectedProduct}>
                <Form.Item
                  label="Selected Product"
                  name="productImage"
                  required
                >
                  <Row align="middle">
                    <Col span={4}>
                      <Image
                        alt="Product_Image"
                        preview={false}
                        src={selectedProduct?.productImage}
                        style={{ maxHeight: 50, maxWidth: 50 }}
                      />
                    </Col>
                    <Col span={18}>{selectedProduct?.productName}</Col>
                    <Col
                      span={24}
                      className="mt-2"
                      style={{
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        maxHeight: "3em",
                      }}
                    >
                      <strong>Description : </strong>{" "}
                      {selectedProduct?.productDescription}
                    </Col>
                    <Col
                      span={24}
                      className="mt-2"
                      style={{
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        maxHeight: "3em",
                      }}
                    >
                      <strong>Available Quantity : </strong>{" "}
                      {selectedProduct?.availableQuantity}{" "}
                      {selectedProduct?.unit}
                    </Col>
                  </Row>
                </Form.Item>
              </ConditionalComponent>

              <Form.Item label="HSN Code" name="hsnCode" required>
                <Select
                  type="number"
                  placeholder="Select HSN..."
                  name="hsnCode"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    String(option.label).includes(input)
                  }
                  options={
                    hsnCodeOptions?.map((item) => ({
                      label: String(item?.hsnCode),
                      value: item?._id,
                    })) || []
                  }
                  notFoundContent="Data Not Found"
                  allowClear={true}
                  onChange={handlehsnCodeChange}
                  loading={hsnCodeListLoading}
                  virtual={{
                    listHeight: 300, // Specify the height of the dropdown
                    itemHeight: 30, // Specify the height of each item
                  }}               
                />
              </Form.Item>

              <Form.Item label="Unit" name="unit" required>
                <Input
                  type="text"
                  id="unit"
                  name="unit"
                  style={{ minWidth: 200 }}
                  allowClear
                  placeholder="Select Unit..."
                  // options={UNIT_TYPE_LIST}
                  value={selectedProduct?.unit}
                  disabled
                />
              </Form.Item>

              <Form.Item label="Qty" name="quantity" required>
                <Input
                  type="number"
                  id="quantity"
                  name="quantity"
                  placeholder="Qty..."
                  suffix={<span />}
                  maxLength={7}
                  max={selectedProduct?.availableQuantity}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "quantity",
                      Number(e.target.value.slice(0, 8))
                    )
                  }
                />
              </Form.Item>

              <Form.Item label="Unit Price ₹" name="unitPrice" required>
                <Input
                  type="decimal"
                  id="unitPrice"
                  name="unitPrice"
                  placeholder="Price ₹"
                  suffix={<span />}
                  maxLength={7}
                  min={1}
                  value={inputValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Item>

              <Form.Item label="Total Amount" name="totalAmount" required>
                <Input
                  type="number"
                  id="totalAmount"
                  name="totalAmount"
                  placeholder="totalAmount..."
                  suffix={<span />}
                  maxLength={7}
                  disabled={isAddOperation}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "totalAmount",
                      Number(e.target.value.slice(0, 8))
                    )
                  }
                />
              </Form.Item>

              <Col sm={12} md={14} className="text-right">
                <SubmitButton
                  loading={formik.isSubmitting}
                  type="primary"
                  htmlType="submit"
                >
                  {MODULE_TITLES.COMMON.SAVE}
                </SubmitButton>
              </Col>
            </Form>
          </FormikProvider>
        </Card>
      </div>

      <Modal
        title={MODULE_TITLES.QUOTE_GENERATION_NAME.SELECT_PRODUCT}
        centered
        style={{ height: "500px" }}
        loading={productListForQuotationLoading}
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <Row className="mb-12">
          <Input
            id="searchProduct"
            name="searchProduct"
            prefix={<SearchOutlined />}
            type="text"
            placeholder="Search..."
            allowClear={true}
            value={searchProductValue}
            onChange={(e) => {
              setSearchProductValue(e.target.value);
              handleSearchChange(e.target.value);
            }}
          />
        </Row>

        <Card loading={productListForQuotationLoading}>
          {productList.length > 0 ? (
            productList.map((item) => {
              return (
                <Row
                  align="middle"
                  className="mb-12"
                  key={item._id}
                  onClick={() => handleOnRowSelection(item)}
                  style={{
                    cursor: "pointer",
                    border: "1px solid #f0f0f0",
                    padding: "4px",
                    maxHeight: "110px",
                  }}
                >
                  <Col span={6} style={{ maxWidth: 110 }}>
                    <Image
                      alt="Product_Image"
                      preview={false}
                      src={item?.productImage}
                      style={{
                        maxHeight: 60,
                        maxWidth: 60,
                        borderRadius: "3px",
                      }}
                      placeholder={
                        <Image
                          preview={false}
                          src={default_s}
                          height={60}
                          width={60}
                        />
                      }
                    />
                  </Col>
                  <Col span={12}>{item?.productName}</Col>
                </Row>
              );
            })
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Card>
      </Modal>    
    </Content>
  );
};

export default AddEditProductQuotation;
