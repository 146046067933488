import React from 'react'
import { Button, Card,Space, Table, notification } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { EyeOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';
import { useOrderListForAdminLazyQuery } from '../../../../graphql/generated/index.tsx';
import { itemRender } from '../../../../helper/Paginationfunction.js';
import { getTableRowKey } from '../../../../helper/GeneralHelper.js';
import BreadcrumbComponent from '../../../common/Breadcrumb.jsx';
import { LOCATIONS } from '../../../../config/routeConfig.js';
import { PAGINATION } from '../../../../config/constants.js';
import { MODULE_TITLES } from '../../../../config/moduleConfig.js';
import { usePagination } from '../../../../context/PaginationContext.jsx';


const SalesOrderList = (props) => {
    const { search, pageValues, handlePageNoChange, handlePageSizeChange, handleSearchChange } = usePagination();
    const params = useParams()
    const navigate = useNavigate()
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [salesOrderList, setSalesOrderList] = React.useState([]);

    // graphql Quatation list query call here
    const[getOrderListForAdminLazyQuery,{ loading:orderListForAdminQueryLazyLoading }] = useOrderListForAdminLazyQuery()

    const getOrdersList = React.useCallback((searchArg,currentPageArg, limitArg) => {
        let variables = {
            categoryId: params?.categoryId
        };
        searchArg && (variables.search = searchArg);
        currentPageArg && (variables.page = currentPageArg - 1);
        limitArg && (variables.limit = limitArg)
        variables.orderType = "MANUAL_QUOTATION_ORDER"
        
        getOrderListForAdminLazyQuery({
            variables: variables,
            onCompleted(data) {
                setSalesOrderList(data?.orderListForAdmin.data)
                setTotalRecords(data?.orderListForAdmin.totalRecords)
            },
            onError(error) {
                notification.error({
                    message: error.message,
                });
            },
            fetchPolicy: "network-only"
        })
    }, [setSalesOrderList, setTotalRecords, getOrderListForAdminLazyQuery]);

    React.useEffect(() => {
        getOrdersList(search,pageValues.currentPage, pageValues.pageSize);
    }, [pageValues]);

    const breadcrumb_items = [
        { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
        { label: MODULE_TITLES.QUOTE_GENERATION_NAME.SALES_ORDER, to: LOCATIONS.SALES_ORDER_ROUTES.ROOT }
    ];

    const tableColumns = [
        {
            title: " Id",
            dataIndex: "ID",
            render: (_text, record) => record?.orderNumberId || "-",
            align: 'center'
        },
        {
            title: " Buyer Business Name",
            dataIndex: "buyerBusinessName",
            render: (_text, record) => record?.buyerBusinessName || "-",
            align: 'center'
        },
        {
            title: "Created At",
            dataIndex: "date",
            render: (_text, record) => {
                function formateDate(date) {
                    let formatedDate = moment.unix(date / 1000).format('Do MMM YYYY');
                    return formatedDate
                }
                return record?.createdAt && formateDate(record?.createdAt)
            }
        },
        {
            title: "Updated At",
            dataIndex: "date",
            render: (_text, record) => {
                function formateDate(date) {
                    let formatedDate = moment.unix(date / 1000).format('Do MMM YYYY');
                    return formatedDate
                }
                return record?.updatedAt && formateDate(record?.updatedAt)
            }
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (_text, record) => record?.orderVerificationStatus || "-",
            align: 'center'
        },
        {
            title: "Actions",
            align: 'center',
            render: (_text, record) => {
                let viewManualQuotationURL = LOCATIONS.SALES_ORDER_ROUTES.ORDER_DETAILS.replace(':sellerUserId',record?.sellerUserId).replace(':salesOrderId', record?._id).replace(':manualQuotationOrderId', record?.manualQuotationOrderId).replace(':buyerUserId',record?.buyerUserId);
                return (
                    <Space size={10}>
                        <Button
                            type="link"
                            shape="circle"
                            icon={<EyeOutlined />}
                            loading={false}
                            onClick={() => navigate(viewManualQuotationURL, { state: { record } })}
                        />

                    </Space>
                );
            },
        },
    ];
    const tableData = salesOrderList;
    return (
        <Content className='content'>
            <div className='page-header'>
                <h2 className='page-title'>{MODULE_TITLES.QUOTE_GENERATION_NAME.SALES_ORDER}</h2>
                <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
            </div>
            <div className='content-wrapper'>
                <Card title={"Sales Order List"}>
                    <Table
                        className="table-striped"
                        style={{ overflow: "auto" }}
                        columns={tableColumns}
                        dataSource={tableData}
                        ascend={true}
                        rowKey={getTableRowKey}
                        showSizeChanger={true}
                        loading={orderListForAdminQueryLazyLoading}
                        pagination={{
                            total: totalRecords,
                            pageSize: pageValues.pageSize,
                            current: pageValues.currentPage,
                            showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            onChange: handlePageNoChange,
                            showSizeChanger: true,
                            pageSizeOptions: PAGINATION.DEFAULT_SIZE_OPTIONS,
                            onShowSizeChange: handlePageSizeChange,
                            itemRender: itemRender,
                        }}
                    />
                </Card>
            </div>
        </Content>
    )
}

export default SalesOrderList;
