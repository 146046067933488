import React, { useEffect, useState } from "react";
import { Button, Card, Col, Layout, message, notification } from "antd";
import { Form, Input, SubmitButton } from "formik-antd";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { commonValidationMessage } from "../../../../helper/MessagesHelper.js";
import { useAddManualQuotationPaymentMutation, useGenerateJusPaySdkPayloadMutation } from "../../../../graphql/generated/index.tsx";
import { PAYMENT_MODE } from "../../../../config/constants.js";
import moment from "moment";
import { ConditionalComponent } from "../../../common/GeneralComponents.js";
import { CopyOutlined } from '@ant-design/icons';

const { Content } = Layout;

const onlineAmountSchema = Yup.object().shape({
  quotationOnlineAmount: Yup.number().required(commonValidationMessage('Amount')).min(1,"Atleast 1Rs/- is required"),
});

const OnlinePaymentQuotation = ({ tabState, manualQuotationId, userId,setReceivedOrderAmountData,setQuotationPaymentDetails,quotationPaymentDetails}) => {
  const [paymentLink, setPaymentLink] = useState("");
  const [addManualQuotationPayment, { loading: addManualQuotationPaymentloading }] = useAddManualQuotationPaymentMutation()
  const [generateJusPaySDKPayload, { loading: generateJusPaySDKPayloadLoading }] = useGenerateJusPaySdkPayloadMutation()

  const formik = useFormik({
    initialValues: {
      quotationOnlineAmount: '',
    },
    validationSchema: tabState === PAYMENT_MODE.ONLINE && onlineAmountSchema,
    onSubmit: (values, actions) => {
      // setOnlineAmountForQuotation(values?.quotationOnlineAmount);
      if (tabState === PAYMENT_MODE.ONLINE) {
        generateJusPaySDKPayload({
          variables: {
            userId: userId && userId,
            amount: values.quotationOnlineAmount
          },
          onCompleted(data) {
            setQuotationPaymentDetails(JSON.parse(data?.generateJusPaySDKPayload?.data?.jusPayResponse))
          },
          onError(error) {
            notification.error({ message: error.message });
          },
          fetchPolicy: "network-only",
        })
      }
      actions.setSubmitting(false);
    }
  });

  useEffect(() => {
    if (tabState === PAYMENT_MODE.ONLINE && quotationPaymentDetails) {
      addManualQuotationPayment({
        variables: {
          input: {
            paymentMode: tabState,
            manualQuotationId: manualQuotationId,
            onlinePaymentDetails: {
              amount: Number(formik?.values?.quotationOnlineAmount),
              jusPayOrderId: quotationPaymentDetails?.sdk_payload?.payload?.orderId,
              paymentLink: quotationPaymentDetails?.payment_links?.web,
              paymentLinkGenerationDate: moment().valueOf()
            }
          }
        },
        onCompleted(data) {
          setReceivedOrderAmountData(data?.addManualQuotationPayment?.data?.receivedOrderAmount);
          notification.success({
            message: data.addManualQuotationPayment.message,
          });
        },
        onError(error) {
          notification.error({ message: error.message });
        },
        fetchPolicy: "network-only",
      })   
    }
  }, [addManualQuotationPayment, quotationPaymentDetails])


  useEffect(() => {
    formik.setFieldValue("quotationOnlineAmount", "", true) 
    setPaymentLink('')
    formik.resetForm()
  }, [tabState])

  const copyPaymentLink = () => {
    const linkToCopy = quotationPaymentDetails?.payment_links?.web || '';
    setPaymentLink(linkToCopy);

    if (linkToCopy) {
      navigator.clipboard.writeText(linkToCopy).then(() => {
        // setCopied(true);
        notification.success({
          message: "Link copied to clipboard",
          duration: 1
        });
      }).catch(() => {
        message.error('Failed to copy link');
      });
    }
  };

  return (
    <Content className="content">
      <div className="content-wrapper">
        <Card
          loading={
            addManualQuotationPaymentloading || generateJusPaySDKPayloadLoading
          }
        >
          <FormikProvider value={formik}>
            <Form
              labelAlign="left"
              labelWrap={true}
              name="add-edit-category-page-form"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 10 }}
              onFinish={formik.handleSubmit}
            >
              <Form.Item
                id="quotationOnlineAmount"
                name="quotationOnlineAmount"
                label="Amount"
                required
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 10 }}
              >
                <Input
                  type="number"
                  id="quotationOnlineAmount"
                  name="quotationOnlineAmount"
                  placeholder="Enter Amount..."
                  suffix={<span />}
                  
                  value={formik.values.quotationOnlineAmount}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "quotationOnlineAmount",
                      Number(e.target.value.slice(0, 8))
                    )
                  }
                />
              </Form.Item>

              <ConditionalComponent
                shouldShow={quotationPaymentDetails?.payment_links?.web}
              >
                <Form.Item
                  id="paymentLink"
                  name="paymentLink"
                  label="Generated Payment Link"
                  required
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 10 }}
                >
                  <Input
                    id="paymentLink"
                    name="paymentLink"
                    value={quotationPaymentDetails?.payment_links?.web || ""}
                    placeholder="Payment Link"
                    suffix={
                      <Button
                        onClick={copyPaymentLink}
                        style={{
                          background: "none",
                          border: "none",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <CopyOutlined />
                      </Button>
                    }
                    disabled
                  />
                </Form.Item>
              </ConditionalComponent>

              <Col className="text-center mt-12px">
                <SubmitButton
                  loading={formik.isSubmitting}
                  type="primary"
                  htmlType="submit"
                  // disabled={quotationPaymentDetails?.payment_links?.web}
                >
               {quotationPaymentDetails?.payment_links?.web? "Create New Payment Link":"Send Payment Link"}   
                </SubmitButton>
              </Col>
            </Form>
          </FormikProvider>
        </Card>
      </div>
    </Content>
  );
};

export default OnlinePaymentQuotation;


