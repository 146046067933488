import React, { useState } from "react";
import { Layout, Card, Tabs, Space, Button, notification } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import BreadcrumbComponent from "../../common/Breadcrumb";
import { LOCATIONS } from "../../../config/routeConfig";
import { MODULE_TITLES } from "../../../config/moduleConfig";
import AdminAdvStatuspanel from "./AdvertisemntStatus/AdminAdvStatuspanel";
import SellerAdvStatusPanel from "./AdvertisemntStatus/SellerAdvStatusPanel";
import { ConditionalComponent } from "../../common/GeneralComponents";
import usePagination from "../../common/hooks/usePagination";
import {
  useAdminPromotedAdvListLazyQuery,
  useSellerPromotedAdvListLazyQuery,
} from "../../../graphql/generated/index.tsx";
import _ from "lodash";
import ViewBannerStatus from "./AdvertisemntStatus/ViewBannerStatus";
import { ADVERTISEMENT_TYPES, PERMISSION_SLUG } from "../../../config/constants";

const { Content } = Layout;
const StatusForPromoteYourAdd = (props) => {
  const params = useParams();
  const IS_PRODUCT_ADVERTISEMENT = props.advertisementType === ADVERTISEMENT_TYPES.PRODUCT_ADVERTISEMENT
  const [tabState, setTabState] = useState( "2" );
  const [adminAdvList, setAdminAdvList] = useState({
    data: [],
    totalRecords: 0,
  });
  const [sellerAdvList, setSellerAdvList] = useState({
    data: [],
    totalRecords: 0,
  });
  const [selectedAdv, setSelectedAdv] = React.useState(null);

  const { handlers: adminListHandler, values: adminListValue } =
    usePagination();
  const { handlers: sellerListHandler, values: sellerListValue } =
    usePagination();
  /* graphql */
  const [
    getAdminPromotedAdvListLazyQuery,
    {
      loading: adminPromotedAdvListLazyQueryLoading,
      variables: previousValuesAdmin,
      ...adminQueryOptions
    },
  ] = useAdminPromotedAdvListLazyQuery();
  const [
    getSellerPromotedAdvListLazyQuery,
    {
      data: sellerPromotedAdvListData,
      loading: sellerPromotedAdvListLazyQueryLoading,
      variables: previousValuesSeller,
      ...sellerQueryOptions
    },
  ] = useSellerPromotedAdvListLazyQuery();

  const breadcrumb_items = [
    { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
    {
      label: MODULE_TITLES.PROMOTE_ADVERTISEMENT.ROOT,
      to: LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.ROOT,
    },
    {
      label: MODULE_TITLES.PROMOTE_ADVERTISEMENT.LIST,
      to: LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.LIST[props.advertisementType ?? ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT].replace(
        ":categoryId",
        params?.categoryId
      ),
    },
    {
      label: MODULE_TITLES.PROMOTE_ADVERTISEMENT.VIEW,
      to: LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.VIEW[props.advertisementType ?? ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT].replace(
        ":categoryId",
        params?.categoryId
      ).replace(":promoteaddId", params?.promoteaddId),
    },
  ];

  /* Handlers */
  const handleClose = React.useCallback(() => setSelectedAdv(null), []);

  const getAdminPromoteAdvList = React.useCallback(
    (currentPageArg, limitArg, filtersArg = {}) => {
      let variables = {
        categoryId: params?.categoryId,
        sectionTypeId: params?.promoteaddId,
        filterBy: props.advertisementType ?? ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT
      };
      currentPageArg && (variables.page = currentPageArg - 1);
      limitArg && (variables.limit = limitArg);
      filtersArg.advertisementStatus &&
        (variables.advertisementStatus = filtersArg.advertisementStatus);

      getAdminPromotedAdvListLazyQuery({
        variables: variables,
        onCompleted(data) {
          setAdminAdvList({
            data: data?.adminPromotedAdvList.data,
            totalRecords: data?.adminPromotedAdvList.totalRecords,
          });
        },
        onError(error) {
          notification.error({ message: error.message });
        },
        fetchPolicy: "network-only",
      });
    },
    [getAdminPromotedAdvListLazyQuery, params, props.advertisementType]
  );

  const getSellerPromoteAdvList = React.useCallback(
    (currentPageArg, limitArg, filtersArg = {}) => {
      let variables = {
        categoryId: params?.categoryId,
        sectionTypeId: params?.promoteaddId,
        filterBy: props.advertisementType ?? ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT
      };
      currentPageArg && (variables.page = currentPageArg - 1);
      limitArg && (variables.limit = limitArg);
      filtersArg.advertisementStatus &&
        (variables.advertisementStatus = filtersArg.advertisementStatus);
      getSellerPromotedAdvListLazyQuery({
        variables: variables,
        onCompleted(data) {
          setSellerAdvList({
            data: data?.sellerPromotedAdvList.data,
            totalRecords: data?.sellerPromotedAdvList.totalRecords,
          });
        },
        onError(error) {
          notification.error({
            message: error.message,
          });
        },
        fetchPolicy: "network-only",
      });
    },
    [getSellerPromotedAdvListLazyQuery, params, props.advertisementType]
  );

  const hasAdminFilterChanged = () => {
    if (!adminQueryOptions.called) return true;

    const currentCopy = {
      page: adminListValue.pageValues.currentPage - 1,
      limit: adminListValue.pageValues.pageSize,
      advertisementStatus: adminListValue.filters.advertisementStatus,
    };
    const prevCopy = {
      page: previousValuesAdmin.page,
      limit: previousValuesAdmin.limit,
      advertisementStatus: previousValuesAdmin.advertisementStatus,
    };
    return _.isEqual(currentCopy, prevCopy) === false;
  };

  const hasSellerFilterChanged = () => {
    if (!sellerQueryOptions.called) return true;

    const currentCopy = {
      page: sellerListValue.pageValues.currentPage - 1,
      limit: sellerListValue.pageValues.pageSize,
      advertisementStatus: sellerListValue.filters.advertisementStatus,
    };
    const prevCopy = {
      page: previousValuesSeller.page,
      limit: previousValuesSeller.limit,
      advertisementStatus: previousValuesSeller.advertisementStatus,
    };

    return _.isEqual(currentCopy, prevCopy) === false;
  };

  React.useEffect(() => {
    if (tabState === "2" && hasSellerFilterChanged()) {
      getSellerPromoteAdvList(
        sellerListValue.pageValues.currentPage,
        sellerListValue.pageValues.pageSize,
        sellerListValue.filters
      );
    }
  }, [sellerListValue.pageValues, tabState]);

  React.useEffect(() => {
    if (tabState === "1" && hasAdminFilterChanged()) {
      getAdminPromoteAdvList(
        adminListValue.pageValues.currentPage,
        adminListValue.pageValues.pageSize,
        adminListValue.filters
      );
    }
  }, [adminListValue.pageValues, tabState]);

  return !selectedAdv ? (
    <Content className="content">
      <div className="page-header">
        <h2 className="page-title">
          {MODULE_TITLES.PROMOTE_ADVERTISEMENT.VIEW}
        </h2>
        <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
      </div>
      <div className="content-wrapper">
        <Card
          title="View Advertisment"
          extra={
            <ConditionalComponent
              shouldShow={tabState === "1" && !IS_PRODUCT_ADVERTISEMENT}
            >
              <Space>
                <ConditionalComponent
                  shouldShow={props.permission[PERMISSION_SLUG.ADD]}
                >
                  <Link
                    to={LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.ADDBANNERBYADMIN[props.advertisementType ?? ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT].replace(
                      ":categoryId",
                      params.categoryId
                    ).replace(":promoteaddId", params.promoteaddId)}
                  >
                    <Button>Add Banner Adv</Button>
                  </Link>
                </ConditionalComponent>
              </Space>
            </ConditionalComponent>
          }
        >
          <Tabs type="card" activeKey={tabState} onChange={setTabState}>
            <>
              <Tabs.TabPane tab="Seller Advertisement " key={2}>
                <SellerAdvStatusPanel
                  handlers={sellerListHandler}
                  values={sellerListValue}
                  listData={sellerAdvList}
                  loading={sellerPromotedAdvListLazyQueryLoading}
                  onSellerAdvView={setSelectedAdv}
                  permission={props.permission}
                  advType={IS_PRODUCT_ADVERTISEMENT ? ADVERTISEMENT_TYPES.PRODUCT_ADVERTISEMENT : ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT}
                />
              </Tabs.TabPane>
              {!IS_PRODUCT_ADVERTISEMENT && <Tabs.TabPane
                tab="Admin Advertisement"
                key={1}
                // disabled={IS_PRODUCT_ADVERTISEMENT}
              >
                <AdminAdvStatuspanel
                  handlers={adminListHandler}
                  values={adminListValue}
                  listData={adminAdvList}
                  loading={adminPromotedAdvListLazyQueryLoading}
                  onSellerAdvView={setSelectedAdv}
                  permission={props.permission}
                />
              </Tabs.TabPane>}
            </>
          </Tabs>
        </Card>
      </div>
    </Content>
  ) : (
    <ViewBannerStatus
      seletedItem={selectedAdv}
      onClose={handleClose}
      refetch={sellerQueryOptions.refetch}
      advertisementType={props.advertisementType}
    />
  );
};

export default StatusForPromoteYourAdd;
