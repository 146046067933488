import { Col, List, Row, Typography } from "antd"
import { getFormatedValue, RenderTags } from "../../../../../helper/GeneralHelper"

export function ListItem({item, labelColProps={}, valueColProps={}, labelStrong}) {
    let label = !labelStrong ? `${item?.label}` :<Typography.Text strong>{item?.label} </Typography.Text>
    return <List.Item>
        <Col sm={8} md={6} {...labelColProps}>{label}</Col>
        <Col sm={6} md={4} >:</Col>
        <Col sm={12} md={14} {...valueColProps}>{getFormatedValue(item.value)}</Col>
    </List.Item>
}

function YarnPriceMOQView({ moq, perKgMrp, perKgMinPrice, perKgMaxPrice, availableQuantity, unit}){
    const list = [
        { label: 'Available Qty', value: availableQuantity, },
        { label: `Min Order ${unit}`, value: moq },
        { label: `Per ${unit} MRP`, value: perKgMrp },
        { label: `Per ${unit} Min Price`, value: perKgMinPrice },
        { label: `Per ${unit} Max Price`, value: perKgMaxPrice },
    ];
    return <List
    dataSource={list}
    renderItem={ListItemRenderer}
/>
}

function ListItemRenderer(item){
    return <ListItem item={item} labelStrong />
}

function MoqForSet({ availableQuantity, minSetOrder, perPiecePrice, perPieceMrp, unit }){
    const list = [
        { label: 'Available Qty', value: availableQuantity, },
        { label: `Min Order ${unit}`, value: minSetOrder },
        { label: `Per ${unit} Price`, value: perPiecePrice },
        {label:`Per ${unit} MRP` ,value: perPieceMrp}
    ];
    return <List
    dataSource={list}
    renderItem={ListItemRenderer}
/>
}
export const PriceMoqView = ({ priceMoq, isYarn= false }) => {

    const unit = isYarn ? "KG" : "Set"
    if (!priceMoq) {
        return null
    }
    const MoqView = isYarn 
    ? <YarnPriceMOQView {...priceMoq} unit={unit} />
    : <MoqForSet {...priceMoq} unit={unit}/>

    return <Row>
        <Col><Typography.Title level={5} >Price MOQ</Typography.Title></Col>
        <Col xs={24}>
        {MoqView}
        </Col>
    </Row>
}

export const PackageDimensionsView = ({ packageDimensions }) => {
    if (!packageDimensions) {
        return null;
    }
    const { unitMeasurement, length, breadth, height } = packageDimensions
    let list = [
        { label: 'Length', value: `${getFormatedValue(length)} ${getFormatedValue(unitMeasurement)} ` },
        { label: 'Breadth', value: `${getFormatedValue(breadth)} ${getFormatedValue(unitMeasurement)} ` },
        { label: 'Height', value: `${getFormatedValue(height)} ${getFormatedValue(unitMeasurement)} `, }];

    return (<Row>
        <Col><Typography.Title level={5} >Package Dimensions</Typography.Title></Col>
        <Col xs={24}>
            <List
                dataSource={list}
                renderItem={(item) => <ListItem item={item} labelStrong />}
            />
        </Col>
    </Row>)
}

export const PackageWeightView = ({ packageWeight }) => {
    if (!packageWeight) {
        return null;
    }

    const { unitMeasurement, weight } = packageWeight

    let list = [
        { label: 'Weight', value: `${getFormatedValue(weight)} ${getFormatedValue(unitMeasurement)}` }
    ];

    return (<Row>
        <Col><Typography.Title level={5} >Package Weight</Typography.Title></Col>
        <Col xs={24}>
            <List
                dataSource={list}
                renderItem={(item) => <ListItem item={item} labelStrong />}
            />
        </Col>
    </Row>
    )

}

export const MiscellaneousView = ({ miscellaneous }) => {
    if (!miscellaneous || miscellaneous.length === 0) {
        return null
    }

    let list = [{ label: 'Miscellaneous', value: <RenderTags data={miscellaneous} /> }];

    return (
        <Row>
            <Col><Typography.Title level={5} >Miscellaneous</Typography.Title></Col>
            <Col xs={24}>
                <List
                    dataSource={list}
                    renderItem={(item) => <ListItem item={item} labelStrong />}
                />
            </Col>
        </Row>
    )

}

export const QuantityInSetView = ({ quantityInSet }) => {

    if (!quantityInSet) {
        return null
    }
    let list = [{ label: 'Set Qty', value: quantityInSet }];
    return <Row>
        <Col><Typography.Title level={5} >Quantity In Set </Typography.Title></Col>
        <Col xs={24}>
            <List
                dataSource={list}
                renderItem={(item) => <ListItem item={item} labelStrong />}
            />
        </Col>

    </Row>
}