import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { useParams } from "react-router"
import { Alert, Button, Card, Col, Image, List, Modal, Popconfirm, Row, Space, Tooltip, Typography, notification } from 'antd'
import { ListItem } from '../../Product/DetailSections/common'
import { Content } from 'antd/lib/layout/layout'
import BreadcrumbComponent from '../../../common/Breadcrumb'
import { MODULE_TITLES } from '../../../../config/moduleConfig'
import { LOCATIONS } from '../../../../config/routeConfig'
import { ConditionalComponent } from '../../../common/GeneralComponents'
import { UNIQUE_MESSAGES, commonValidationMessage } from '../../../../helper/MessagesHelper'
import { useActionRequiredOnUserPromotedAdvMutation, useAdvertisementVerifiedByAdminMutation } from '../../../../graphql/generated/index.tsx'
import VerifiedAccountImage from "../../../../assets/image/verified-account-icon-96.png";
import { FormikProvider, useFormik } from 'formik'
import { Form, Input } from 'formik-antd'
import { ADVERTISEMENT_TYPES, APPROVAL_TYPES } from '../../../../config/constants';
import { isValidDate } from '../../../../helper/DateFormatter';
import { CloseCircleOutlined } from '@ant-design/icons';

const styles = {
    approveBtn: {
        background: "#180C44",
        color: "#ffffff",
        border: 0,
        ":hover": {
            borderColor: "#180C44",
            border: 1,
        },
    },
};

const VerifiedImage = (
    <img
        src={VerifiedAccountImage}
        alt="Document is Verified"
        height={28}
        width={28}
    />
)
const options = { year: 'numeric', month: 'long', day: 'numeric' };
const DocumentRejectValidationSchema = Yup.object().shape({
    id: Yup.string().required(),
    advertisementCancelReason: Yup.string().required(
      commonValidationMessage("Reason For Rejection")
    ),
});

const ViewBannerStatus = ({ seletedItem, onClose, refetch, advertisementType }) => {
    const params = useParams();
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [isActionRequired, setIsActionRequired] = useState(false)

    const [advertisementVerifiedByAdminMutation] = useAdvertisementVerifiedByAdminMutation();
    const [actionRequiredOnUserPromotedAdvMutation] = useActionRequiredOnUserPromotedAdvMutation()
    const formik = useFormik({
        initialValues: {
            id: seletedItem._id,
            advertisementCancelReason: "",
        },
        validationSchema: DocumentRejectValidationSchema,
        async onSubmit(values, helpers) {
            helpers.setSubmitting(true);
            !values.advertisementCancelReason &&
                (values.documentCancelReason = null);
            try {
                await onOptionClickMutationHandler(
                    APPROVAL_TYPES.REJECTED,
                    values.id,
                    values.advertisementCancelReason
                );
                helpers.setSubmitting(false);
            } catch (error) {
                helpers.setSubmitting(false);
            }
        },
    });

    const breadcrumb_items = [
      {
        label: MODULE_TITLES.DASHBOARD.ROOT,
        to: LOCATIONS.DASHBOARD_ROUTE.ROOT,
      },
      {
        label: MODULE_TITLES.PROMOTE_ADVERTISEMENT.ROOT,
        to: LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.ROOT,
      },
      {
        label: MODULE_TITLES.PROMOTE_ADVERTISEMENT.LIST,
        to: LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.LIST[
          advertisementType ?? ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT
        ].replace(":categoryId", params?.categoryId),
      },
      {
        label: MODULE_TITLES.PROMOTE_ADVERTISEMENT.VIEW,
        to: LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.VIEW[
          advertisementType ?? ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT
        ]
          .replace(":categoryId", params?.categoryId)
          .replace(":promoteaddId", params?.promoteaddId),
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose();
        },
      },
      { label: MODULE_TITLES.PROMOTE_ADVERTISEMENT.VIEWBANNER },
    ];
    /**grapgql */



    const onOptionClickMutationHandler = React.useCallback(
        async (approvalStatusType, id,reason) => {
            return new Promise((resolve, reject) => {
                advertisementVerifiedByAdminMutation({
                variables: {
                    id,
                    verifiedByAdmin: approvalStatusType,
                    advertisementCancelReason:reason
                },
                onCompleted(data) {
                    notification.success({ message: data.advertisementVerifiedByAdmin.message })
                    // navigate(LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.ROOT)
                    refetch();
                    onClose()
                    resolve()
                },
                onError(error) {
                    notification.error({ message: error.message })
                    // navigate(LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.ROOT)
                    reject()
                }
            })
        })
        }, [advertisementVerifiedByAdminMutation, onClose, refetch]
    )

    const bannerAdvList = React.useMemo(() => [
        {label:"Banner Image Web",value:<Image alt="Banner_image" src={seletedItem?.bannerImageWeb} style={{ maxHeight: 100, maxWidth: 100 }}/>},
        {label:"Banner Image Mobile",value:<Image alt=" Banner_image" src={seletedItem?.bannerImageMobile} style={{ maxHeight: 100, maxWidth: 100 }}/>},
        { label: "Advertisement Type", value: seletedItem?.advertisementType.replace(/_/g, " ") },
        { label: "Banner Description", value: seletedItem?.bannerDescription },
        { label: "Banner Title", value: seletedItem?.bannerTitle },
        { label: "Start Date", value: seletedItem?.addedBy  && isValidDate(new Date(seletedItem?.startDate)) ? new Date(seletedItem?.startDate).toLocaleDateString('en-US', options) : "-" },
        { label: "End Date", value: seletedItem?.addedBy  && isValidDate(new Date(seletedItem?.endDate)) ? new Date(seletedItem?.endDate).toLocaleDateString('en-US', options) : "-" },
        { label: "Total Days", value: seletedItem?.totalDays },

        {
            label: "Total Payable Amount", value: seletedItem?.addedBy === "SELLER" ?
                <><strong name="Rs">{"\u20B9"} </strong>
                    {seletedItem?.totalPayableAmount}</> : "-"
        },
        { label: "Seller Business Name", value: seletedItem?.sellerBusinessName },
        { label: "Payment Status", value: seletedItem?.paymentStatus },
    ], [seletedItem])
    
    const productAdvList = React.useMemo(() => [
        { label: "Product Image", value: <Image alt="Product_image" src={seletedItem?.productImage} style={{ maxHeight: 100, maxWidth: 100 }} /> },
        { label: "Brand Name", value: seletedItem?.brandName },
        { label: "Product Name", value: seletedItem?.productName },
        { label: "Start Date", value: seletedItem?.addedBy  && isValidDate(new Date(seletedItem?.startDate)) ? new Date(seletedItem?.startDate).toLocaleDateString('en-US', options) : "-" },
        { label: "End Date", value: seletedItem?.addedBy  && isValidDate(new Date(seletedItem?.endDate)) ? new Date(seletedItem?.endDate).toLocaleDateString('en-US', options) : "-" },
        { label: "Total Days", value: seletedItem?.totalDays },

        {
            label: "Total Payable Amount", value: seletedItem?.addedBy === "SELLER" ?
                <><strong name="Rs">{"\u20B9"} </strong>
                    {seletedItem?.totalPayableAmount}</> : "-"
        },
        { label: "Seller Business Name", value: seletedItem?.sellerBusinessName },
        { label: "Payment Status", value: seletedItem?.paymentStatus },
    ], [seletedItem])

    useEffect(() => {
        if (seletedItem.isPaymentDone) {
            actionRequiredOnUserPromotedAdvMutation({
                variables: {
                    id: seletedItem._id
                },
                onCompleted(data) {
                    if (data && data.actionRequiredOnUserPromotedAdv.isActionRequired) {
                        setIsActionRequired(true)
                    }
                }
            })

        }

    }, [seletedItem])

    return (
        <Content className="content">
            <div className="page-header">
                <h2 className="page-title">View Adv Detail </h2>
                <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
            </div>
            <div className='content-wrapper'>
                <Row>
                    <Col sm={24} >
                        <Card title="Adv Detail" extra={
                            <Space align='center'>
                                <OptionsForAppovedAdvBanner item={seletedItem} isActionRequired={isActionRequired} onRejectAdv={() => setIsModalVisible(true)} onOptionClick={onOptionClickMutationHandler} />
                            </Space>
                        }><List
                                dataSource={advertisementType === "BANNER_ADVERTISEMENT" ? bannerAdvList : productAdvList}
                                renderItem={(item) => <ListItem item={item} labelStrong />}
                            />
                            <Row>
                                <Col span={12}>
                                    {seletedItem?.advertisementCancelReason && <Alert
                                        message="Rejected Reason"
                                        description={seletedItem?.advertisementCancelReason}
                                        type="error"
                                        banner
                                        className="mt-10"
                                    />}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Modal
                maskClosable={false}
                title={MODULE_TITLES.USER.REJECT_REASON}
                visible={isModalVisible}
                centered
                onOk={formik.submitForm}
                onCancel={() => setIsModalVisible(false)}
                okButtonProps={{ loading: formik.isSubmitting, disabled: !formik.isValid && (formik.dirty || formik.submitCount > 0) }}
            >
                <FormikProvider value={formik}>
                    <Form
                        id="document-reject-form"
                        name="document-reject-form"
                        layout="horizontal"
                        labelCol={{ span: 0 }}
                        labelWrap={true}
                    >
                        <Form.Item label="Reject Reason" name="advertisementCancelReason" required>
                            <Input.TextArea
                                name="advertisementCancelReason"
                                id="advertisementCancelReason"
                                type="text"
                                placeholder="Reason for Rejection..."
                                maxLength={80}
                            />
                        </Form.Item>
                        <Typography>{`${formik.values.advertisementCancelReason.length} / 80 Characters.`}</Typography>
                    </Form>
                </FormikProvider>
            </Modal>
        </Content>
    )
}

function OptionsForAppovedAdvBanner({
    item,
    onOptionClick,
    onRejectAdv,
    isActionRequired
}) {
    const isPending = item.verifiedByAdmin === "AWAITING_APPROVAL"
    const isApproved = item.verifiedByAdmin === "APPROVED"
    const isRejected = item.verifiedByAdmin === "REJECTED"
    const isPaymentFailed=item.paymentStatus === "failed"
    const handleApproveClick = () => onOptionClick(APPROVAL_TYPES.APPROVED, item._id);

    return (
        <>
            <Space direction='vertical'>
                <ConditionalComponent shouldShow={isPending && isActionRequired } >
                    <Popconfirm
                        placement="bottomRight"
                        title={UNIQUE_MESSAGES.APPROVE_MESSAGE("Banner")}
                        onConfirm={handleApproveClick}
                        okText="Yes"
                        cancelText="No"
                        className='mr-8'
                    >
                        <Button style={styles.approveBtn} >
                            Approve
                        </Button>
                    </Popconfirm>
                    <Popconfirm
                        placement="bottomRight"
                        title={UNIQUE_MESSAGES.REJECT_POPUP("Banner")}
                        onConfirm={onRejectAdv}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary"
                            danger >
                            Reject
                        </Button>
                    </Popconfirm>
                </ConditionalComponent>
                <ConditionalComponent shouldShow={isPaymentFailed}>
                <div style={{
                        display: "flex",
                        alignItems: "center",
                        gap: '5px'
                    }}>
                        <Typography style={{ color: '#ff4d4f' }}>
                            Payment Status Failed
                        </Typography>
                    </div>
                </ConditionalComponent>
                <ConditionalComponent shouldShow={isRejected && !isPaymentFailed}>
                    <Tooltip title="Banner Rejected">
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: '5px'
                        }}>
                            <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
                            <Typography style={{ color: '#ff4d4f' }}>
                                Rejected
                            </Typography>
                        </div>
                    </Tooltip>
            </ConditionalComponent>

                <ConditionalComponent shouldShow={isApproved}>
                    <Tooltip title="Product Approved">{VerifiedImage} Advertisement Approved </Tooltip>
                </ConditionalComponent>
            </Space>
        </>
    );
}
export default ViewBannerStatus
