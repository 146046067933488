import React, { useCallback, useState } from "react";
import { Layout, Table, Space, Button, Image, Form, Select, Popconfirm, Switch, notification} from "antd";
import { DeleteOutlined, EyeOutlined,  } from '@ant-design/icons';
import { getTableRowKey } from "../../../../helper/GeneralHelper";
import { ADVERTISEMENT_STATUS, PAGINATION, PERMISSION_SLUG, TOGGLE } from "../../../../config/constants";
import { itemRender } from "../../../../helper/Paginationfunction";
import { onImageLoadError } from "../../../../helper/imageHelper";
import { default_o, default_s } from "../../../../helper/importHelper/image";
import { useLocation, useNavigate } from "react-router";
import { ConditionalComponent } from "../../../common/GeneralComponents";
import { isValidDate } from "../../../../helper/DateFormatter"
import { UNIQUE_MESSAGES } from "../../../../helper/MessagesHelper";
import { useDeletUserPromotedAdvMutation, useUpdateUserPromotedAdvStatusMutation } from "../../../../graphql/generated/index.tsx";
import { result } from "lodash";

const { Content } = Layout;

const ADVERTISEMENT_STATUS_OPTIONS = [
  { label: ADVERTISEMENT_STATUS.ACTIVE, value: ADVERTISEMENT_STATUS.ACTIVE },
  { label: ADVERTISEMENT_STATUS.APPROVED, value: ADVERTISEMENT_STATUS.APPROVED },
  {label: ADVERTISEMENT_STATUS.REJECTED,value: ADVERTISEMENT_STATUS.REJECTED,},
  { label: ADVERTISEMENT_STATUS.PENDING, value: ADVERTISEMENT_STATUS.PENDING },
  { label: ADVERTISEMENT_STATUS.EXPIRED, value: ADVERTISEMENT_STATUS.EXPIRED },
];
const options = { year: 'numeric', month: 'short', day: 'numeric' };
const AdminAdvStatuspanel = ({onSellerAdvView,...props}) => {
  
  const [deletUserPromotedAdvMutation ,refetch]= useDeletUserPromotedAdvMutation()

  const { handlers, values,} = props;
  const { handlePageNoChange, handlePageSizeChange, handleFilterChange } =
    handlers;
  const { pageValues, filters } = values;


    const [updateUserPromotedAdvStatus] = useUpdateUserPromotedAdvStatusMutation({
      onCompleted(data) {
        notification.success({
          message: data?.updateUserPromotedAdvStatus?.message,
        });
      },
      refetchQueries(result){ 
          if(result.data.updateUserPromotedAdvStatus.code){
            return ['AdminPromotedAdvList']
          }
      },
      onError(error) {
        notification.error({
          message: error.message,
        });
      },
    });

    const handleActiveChange = (checked, e, record) => {
      props.permission[PERMISSION_SLUG.EDIT] && 
      updateUserPromotedAdvStatus({
        variables : {
          id: record._id,
          advertisementStatus: checked ? TOGGLE.ACTIVE : TOGGLE.INACTIVE,

        }
      })
  }

  const deleteAdminBannerType = React.useCallback((data)=>{
    deletUserPromotedAdvMutation(
      {
          variables:{id:data?._id},
          onCompleted(data){
              notification.success({
                  message: data.deletUserPromotedAdv.message,
              }); 
          },
          refetchQueries(result){ 
            if(result.data.deletUserPromotedAdv.code){
              return ['AdminPromotedAdvList']
            }
        },
          onError(error){
              notification.error({
                  message: error.message,
              });       
          },
      }
  )
},[deletUserPromotedAdvMutation,refetch])

  const tableColumns = [
    {
      title: "Banner Title",
      dataIndex: "bannerTitle",
    },
    {
      title: "Section Type",
      dataIndex: "sectionTypeName",
      ellipsis: true
  },
    {
      title: "Adv Status",
      dataIndex: "advertisementStatus",
      align:'center'
    },
    {
      title: " Admin Banner Status",
      dataIndex: "advertisementStatus",
      align: "center",
      render: (_text, record) => {
        return <Switch disabled={!props.permission[PERMISSION_SLUG.EDIT]}
           onChange={(checked, e) => handleActiveChange(checked, e, record)} 
          checkedChildren={TOGGLE.ACTIVE} checked={record.advertisementStatus === TOGGLE.ACTIVE} unCheckedChildren={TOGGLE.INACTIVE} defaultChecked style={{ width: 'fit-content' }} />
      }
    },
    {
      title: "Start Date",
      render: (_text, record) => {
        return isValidDate(new Date(record?.startDate)) ? new Date(record?.startDate).toLocaleDateString('en-US',options) : "-"
    }
    },
    {
      title: "End Date",
      render: (_text, record) => {
        return  isValidDate(new Date(record?.endDate))  ? new Date(record?.endDate).toLocaleDateString('en-US',options) : "-"
    }
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (_text, record) => {
        return (
          <Space size={10}>
             <ConditionalComponent shouldShow={props.permission[PERMISSION_SLUG.VIEW]}>
             <Button
              type="link"
              shape="circle"
              icon={<EyeOutlined />}
              loading={false}
              onClick={() => onSellerAdvView(record)}
            />
             </ConditionalComponent>
             <ConditionalComponent shouldShow={props.permission[PERMISSION_SLUG.DELETE]}>
              <Popconfirm
                placement="bottomRight"
                title={UNIQUE_MESSAGES.DELETE_POPUP("Adv.")}
                onConfirm={()=>deleteAdminBannerType(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" shape="circle" icon={<DeleteOutlined />} />
              </Popconfirm> 
             </ConditionalComponent>
            
          </Space>
        );
      },
    },
  ];

  const tableData = props.listData.data; //dataSource
  const dataLength=tableData.length

  return (
    <Content className="content">
      <div className="content-wrapper">
        <Form
          name="admin-adv-status-list"
          className="justify-content-end mb-20"
          layout="inline"
          labelWrap={true}
          initialValues={{ advertisementStatus: filters.advertisementStatus }}
        >
          <Form.Item name="advertisementStatus" label="Advertisement Status">
            <Select
              allowClear={true}
              style={{ minWidth: 200 }}
              placeholder="Select Status Type"
              name="advertisementStatus"
              value={filters.advertisementStatus}
              onChange={(value) =>
                handleFilterChange("advertisementStatus", value)
              }
              options={ADVERTISEMENT_STATUS_OPTIONS}
            />
          </Form.Item>
        </Form>
        <Table
          className="table-striped"
          style={{ overflow: "auto" }}
          columns={tableColumns}
          dataSource={tableData}
          ascend={true}
          rowKey={getTableRowKey}
          showSizeChanger={true}
          loading={props.loading}
          scroll={ dataLength > 5 && {y: 500 } }
          pagination={{
            total: props.listData.totalRecords,
            pageSize: pageValues.pageSize,
            current: pageValues.currentPage,
            showTotal: (total, range) =>
              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
            onChange: handlePageNoChange,
            showSizeChanger: true,
            pageSizeOptions: PAGINATION.DEFAULT_SIZE_OPTIONS,
            onShowSizeChange: handlePageSizeChange,
            itemRender: itemRender,
          }}
        />
      </div>
    </Content>
  );
};

export default AdminAdvStatuspanel;
