import React from "react";
import { Card, Col, Divider, Image, Row, Space,Typography } from "antd";
import { default_s } from "../../../helper/importHelper/image";
import { onImageLoadError } from "../../../helper/imageHelper";
import { ConditionalComponent } from "../../common/GeneralComponents";

const OrderDetails = ({subOrderList}) => {
  return (
    <>
      <Row>
        <Col sm={24} md={24}>
          <Typography.Text strong>
            <Divider orientation="left">Order Details :</Divider>
          </Typography.Text>
        </Col>
      </Row>
      <Row gutter={6}>
          {subOrderList?.map((item, index) => {
            return (
              <Col span={12}  key={index}>
                  <SetCardView
                    productName={item?.productName}
                    productImage={item?.productImage}
                    colors={item?.colors}
                    sizes={item?.sizes}
                    setQuantity={item?.setQuantity}
                    quantityInSet={item?.quantityInSet}   
                    totalOrderSetPrice={item?.totalOrderSetPrice}
                  />             
              </Col>
            );
          })}
      </Row>
    </>
  );
};


const SetCardView = ({ productImage,productName, sizes, colors, setQuantity,quantityInSet,totalOrderSetPrice}) => {
  return (
      <Col span={24}>
        <Card size="default">
          <Row justify={"space-between"} align={"middle"} gutter={6}>
            <Col >
              <Image alt=" " onError={onImageLoadError} src={productImage ? productImage : default_s} style={{ maxHeight: 100, maxWidth: 100 }} />
            </Col>
            <Col span={18}>
              <Typography.Text strong style={{wordWrap:'break-word'}}>{productName}</Typography.Text><br />
              <Space>
                  <Row>
                    <div style={{ display: 'flex', flexDirection: 'column',padding:"0px 10px 0px 0px" }} >
                      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <Typography.Text strong>Colors: </Typography.Text>
                        {
                          colors.map((clrItem, index) => {
                            return <span
                              key={index}
                              style={{
                                display: 'flex',
                                flexWrap:'wrap',
                                alignItems: "center",
                                height: "16px",
                                width: "16px",
                                borderRadius: "50%",
                                marginLeft: '5px',
                                border: '1px solid lightgrey',
                                backgroundColor: clrItem?.colorCode,
                              }}
                            ></span>
                          })}
                      </div>
                      <Col>
                        <Typography.Text strong>Set of : </Typography.Text>{" "}{quantityInSet}<br />
                      </Col>
                      <Col>
                        <Typography.Text strong>Quantity : </Typography.Text>{" "}{setQuantity}<br />
                      </Col>
                      <Col>
                        <Typography.Text strong>Total Order Set Price : </Typography.Text>{" "}{totalOrderSetPrice}<br />
                      </Col>
                  <ConditionalComponent shouldShow={sizes?.length>0}>
                    <Typography.Text strong>Sizes:
                      <Space wrap style={{ paddingLeft: '2px' }}>
                        {sizes && sizes?.map((sizeValues, i) => (
                          <span key={i}>{sizeValues?.sizeValue}</span>
                        ))}
                      </Space>
                    </Typography.Text>
                  </ConditionalComponent>
                    </div>     
                  </Row>             
              </Space>   
            </Col>
          </Row>
        </Card>
      </Col>
    
  );
};
export default OrderDetails;
