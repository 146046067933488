import { Space, Switch, notification } from "antd";
import { ADMIN_PRODUCT_APPROVAL_TYPES, APPROVAL_TYPES, PRODUCT_CHANGED_BY, TOGGLE } from "../../config/constants";
import React from 'react';
import { useUpdateProductStatusMutation } from "../../graphql/generated/index.tsx"

function ProductStatusSwitch({ statusChangedBy, id, verifiedByAdmin, isActive,refetch }) {
    const isPending =  verifiedByAdmin === ADMIN_PRODUCT_APPROVAL_TYPES.AWAITING_APPROVAL;
    const disableSwitch = isPending || statusChangedBy === PRODUCT_CHANGED_BY.SELLER
    const [updateProductStatusMutation] = useUpdateProductStatusMutation();
    const statusSwitchMutation = (checked) => {
        updateProductStatusMutation({
            variables:{
                productId: id,
                productStatus: checked ? TOGGLE.ACTIVE : TOGGLE.INACTIVE,
                productStatusChangedBy:PRODUCT_CHANGED_BY.ADIMIN
            },
            onCompleted(data){
                notification.success({
                    message: data.updateProductStatus.message,
                    onClose:notification.destroy()
                });
                refetch()
            },
            onError(error){
                notification.error({
                    message: error.message,
                });
            }
        })
    }

    const onChange = (checked) => {
        if (disableSwitch) return;
        statusSwitchMutation(checked)
    };

    return (
        <Space>
            <Switch onChange={onChange} disabled={disableSwitch}                 
                checkedChildren={TOGGLE.ACTIVE}
                checked={isActive === TOGGLE.ACTIVE }
                unCheckedChildren={isPending ? APPROVAL_TYPES.PENDING :TOGGLE.INACTIVE}
                style={{ width: 'fit-content' }}
                 />
        </Space>
    )
}
export default ProductStatusSwitch