import React from "react";
import { Content } from "antd/lib/layout/layout";
import { Button, Card, Col, notification, Row, Upload } from "antd";
import { ORDER_DOCUMENT_TYPES, IMAGE_TYPE, UPLOAD_PATH} from "../../../../config/constants";
import { AWSHelper } from "../../../../helper/S3helper";
import { generateRandomFileName } from "../../../../helper/GeneralHelper";
import {
  useManageDocumentsForManualQuotationMutation,
  useManageDocumentListLazyQuery,
} from "../../../../graphql/generated/index.tsx";
import { ConditionalComponent } from "../../../common/GeneralComponents.js";
import {
  CloudUploadOutlined,
  DownloadOutlined,
  EyeOutlined,
} from "@ant-design/icons";

async function download(fileUrl, filename) {
  const response = await fetch(fileUrl);
  const blob = await response.blob();
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

const ManageDocuments = ({ subOrderId, handleModalClose }) => {
  const [documentList, setDocumentList] = React.useState([]);
  const [
    getManageDocumentListLazyQuery,
    { loading: manageDocumentListLazyQueryLoading, refetch },
  ] = useManageDocumentListLazyQuery();
  const [manageDocumentsForManualQuotationMutation] =
    useManageDocumentsForManualQuotationMutation();

  React.useEffect(() => {
    getManageDocumentListLazyQuery({
      variables: { id: subOrderId },
      onCompleted(data) {
        setDocumentList(data?.manageDocumentList);
        refetch();
      },
      onError(error) {
        notification.error({ message: error.message });
      },
      fetchPolicy: "network-only",
    });
  }, [getManageDocumentListLazyQuery, handleModalClose, subOrderId]);

  function openFile(url) {
    window.open(url, "_blank").focus();
  }

  function UploadControl({ name, files, type, isDocAlreadyPresent }) {
    const onSubmitDocument = async (file) => {
      try {
        // const fileName =  generateRandomFileName(type);
        const fileExtension = file.type.split("/");
        const fileName = generateRandomFileName(type) + "." + fileExtension[1];
        if (file.length <= 0) {
          return notification.error({ message: `Something went wrong` });
        }
        await AWSHelper.upload(file, fileName, UPLOAD_PATH[type], file?.type);
        await manageDocumentsForManualQuotationMutation({
          variables: {
            manualQuotationSubOrderId: subOrderId,
            documentType: type,
            documentFileName: fileName,
          },
          onCompleted(data) {
            notification.success({
              message: data.manageDocumentsForManualQuotation.message,
            });
            handleModalClose();
          },
          onError(error) {
            notification.error({ message: error.message });
          },
        });
      } catch (err) {
        notification.error({ message: err });
      }
    };
    const onDocumentChange = React.useCallback(({ file }) => {
      let fileExtension = file.type.split("/").pop();
      if (![IMAGE_TYPE.PDF, IMAGE_TYPE.PDF_2].includes(fileExtension)) {
        return notification.error({ message: "File must be a PDF" });
      }
      onSubmitDocument(file);
    }, []);

    return (
      <>
        <Upload
          accept=".pdf"
          beforeUpload={() => false}
          action="/upload.do"
          id={type}
          name={name}
          maxCount={1}
          onChange={onDocumentChange}
          fileList={files}
          className=""
        >
          <Button
            style={{ display: "flex", alignItems: "center" }}
            type="dashed"
            icon={<CloudUploadOutlined />}
            disabled={isDocAlreadyPresent}
          >
            Upload
          </Button>
        </Upload>
      </>
    );
  }

  return (
    <Card loading={manageDocumentListLazyQueryLoading}>
      <Content className="content">
        {documentList?.map((doc, key) => {
          return (
            <Row
              className="mb-10"
              key={key}
              align="middle"
              style={{ gap: "20px" }}
            >
              <Col span={6} className="d-flex">
                <strong>{`${doc?.documentName?.replace(/_/g, " ")} `}</strong>
              </Col>
              <Col span={6}>
                {ORDER_DOCUMENT_TYPES.INVOICE === doc?.documentType &&
                !doc?.documentFileName ? (
                  "-"
                ) : (
                  <UploadControl
                    type={doc?.documentType}
                    name={doc?.documentType}
                    isDocAlreadyPresent={doc?.documentFileName ? true : false}
                  />
                )}
              </Col>
              <ConditionalComponent shouldShow={doc?.documentFileName} span={6}>
                <Col>
                  <Button
                    style={{ display: "flex", alignItems: "center" }}
                    type="dashed"
                    icon={<DownloadOutlined />}
                    onClick={() =>
                      download(doc?.documentFileName, doc?.documentName)
                    }
                  >
                    Download
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    type="link"
                    shape="circle"
                    icon={<EyeOutlined />}
                    onClick={() => openFile(doc?.documentFileName)}
                  />
                </Col>
              </ConditionalComponent>
            </Row>
          );
        })}
      </Content>
    </Card>
  );
};

export default ManageDocuments;
