import React from 'react'
import { Button, Card, Space, Table, notification, } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { EyeOutlined } from '@ant-design/icons';
import { MODULE_TITLES } from '../../../../config/moduleConfig'
import BreadcrumbComponent from '../../../common/Breadcrumb'
import { useNavigate, useParams } from 'react-router'
import { LOCATIONS } from '../../../../config/routeConfig'
import { PAGINATION, PERMISSION_SLUG } from '../../../../config/constants';
import { usePagination } from '../../../../context/PaginationContext';
import { itemRender } from '../../../../helper/Paginationfunction';
import { useBulkUploadSheetListLazyQuery } from '../../../../graphql/generated/index.tsx';
import moment from 'moment';
import { ConditionalComponent } from '../../../common/GeneralComponents';


const BulkProductList = (props) => {
    const {
        pageValues,
        handlePageNoChange,
        handlePageSizeChange,
    } = usePagination();

    const params = useParams()
    const navigate = useNavigate()
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [bulkProductList, setBulkProductList] = React.useState([]);


    const [getBulkUploadSheetListLazyQuery, { loading: bulkUploadSheetListLazyQueryLoading }] = useBulkUploadSheetListLazyQuery();

    const getBulkUploadList = React.useCallback((currentPageArg, limitArg) => {
        let variables = {
            categoryId: params?.categoryId
        };
        currentPageArg && (variables.page = currentPageArg - 1);
        limitArg && (variables.limit = limitArg)

        getBulkUploadSheetListLazyQuery({
            variables: variables,
            onCompleted(data) {
                setBulkProductList(data?.bulkUploadSheetList.data)
                setTotalRecords(data?.bulkUploadSheetList.totalRecords)
            },
            onError(error) {
                notification.error({
                    message: error.message,
                });
            },
            fetchPolicy: "network-only"
        })
    }, [setBulkProductList, setTotalRecords, getBulkUploadSheetListLazyQuery]);

    React.useEffect(() => {
        getBulkUploadList(pageValues.currentPage, pageValues.pageSize);
    }, [pageValues]);

    const handleBulkDataId = (i) => {
        return i._id
    }

    const breadcrumb_items = [
        { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
        { label: MODULE_TITLES.BULK_LIST.ROOT ,to: LOCATIONS.BULK_LIST_ROUTES.ROOT },
    ];


    const tableColumns = [
        {
            title: "ID",
            dataIndex: "sheetId",
            align: 'center'
        },
        {
            title: "Business Name",
            dataIndex: "businessName",
        },
        {
            title: "Seller Name",
            dataIndex: "sellerName",
        },

        {
            title: "Category",
            dataIndex: "categoryName",
         
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (_text, record) => {
                function formateDate(date) {
                    let formatedDate=moment.unix(date/1000).format('MMMM Do YYYY, h:mm:ss a');
                    return formatedDate
                   }
                return record.createdAt && formateDate(record.createdAt) 
            }
        },
        {
            title: "Update At",
            dataIndex: "updatedAt",
            render: (_text, record) => {
                function formateDate(date) {
                    let formatedDate=moment.unix(date/1000).format('MMMM Do YYYY, h:mm:ss a');
                    return formatedDate
                }
                return record.updatedAt && formateDate(record.updatedAt) 
            }
        },
        {
        title: "Status",
        dataIndex: "status",
        align: 'center'
        },
        {
            title: "Actions",
            align: 'center',
            render: (_text, record) => {
                let viewBulkProductDetailListURL = LOCATIONS.BULK_LIST_ROUTES.VIEW.replace(':bulkSheetId', record._id);
                return (
                    <Space size={10}>
                        <ConditionalComponent shouldShow={props?.permission[PERMISSION_SLUG.VIEW]}>
                        <Button
                            type="link"
                            shape="circle"
                            icon={<EyeOutlined />}
                            loading={false}
                            onClick={() => navigate(viewBulkProductDetailListURL, { state: record })}
                        />
                        </ConditionalComponent>
                        {/* <Button
                            type="link"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            loading={false}
                            onClick={() => handleRowDelete(record.id)}
                        /> */}
                    </Space>
                );
            },
        },
    ];
    /**Delete Row Selection  */
    
    // const handleRowDelete = (key) => {
    //     const filtedDataList = bulkProductList.filter((item) => {
    //         return item.id !== key
    //     })
    //     setBulkProductList(filtedDataList);

    // }

    const tableData = bulkProductList;
    return (
        <Content className='content'>
            <div className='page-header'>
                <h2 className='page-title'>{MODULE_TITLES.BULK_LIST.ROOT}</h2>
                <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
            </div>
            <div className='content-wrapper'>
                <Card title={MODULE_TITLES.BULK_LIST.LIST}>
                    <Table
                        className="table-striped"
                        style={{ overflow: "auto" }}
                        columns={tableColumns}
                        dataSource={tableData}
                        ascend={true}
                        rowKey={handleBulkDataId}
                        showSizeChanger={true}
                        loading={bulkUploadSheetListLazyQueryLoading}
                        pagination={{
                            total: totalRecords,
                            pageSize: pageValues.pageSize,
                            current: pageValues.currentPage,
                            showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            onChange: handlePageNoChange,
                            showSizeChanger: true,
                            pageSizeOptions: PAGINATION.DEFAULT_SIZE_OPTIONS,
                            onShowSizeChange: handlePageSizeChange,
                            itemRender: itemRender,
                        }}
                    />
                </Card>
            </div>
        </Content>
    )
}

export default BulkProductList