

import React, { useRef } from "react";
import * as Yup from "yup";
import { Content } from "antd/lib/layout/layout";
import { MODULE_TITLES } from "../../../config/moduleConfig";
import { LOCATIONS } from "../../../config/routeConfig";
import { Card, Col, Select, DatePicker, Radio, notification, Input as AntdInput, Form as AntdForm, Row } from "antd";
import dayjs from "dayjs";
import { Form, Input, SubmitButton } from "formik-antd";
import { FormikProvider, useFormik } from "formik";
import BreadcrumbComponent from "../../common/Breadcrumb";
import { commonValidationMessage } from "../../../helper/MessagesHelper";
import { OFFERS_AND_COUPON_TYPE } from "../../../config/constants";
import { useNavigate } from "react-router";
import { useAddUpdateCouponCodeMutation, useCategoryListQuery } from "../../../graphql/generated/index.tsx";
import { ConditionalComponent } from "../../common/GeneralComponents";
import moment from "moment"

const RADIO_BUTTON_FOR = {
  YES: "YES",
  NO: "NO"
}
const DISCOUNT_TYPE = {
  PERCENTAGE: "DISCOUNT IN PERCENTAGE",
  AMOUNT: "DISCOUNT IN AMOUNT"
}
const { RangePicker } = DatePicker;
const dateFormatList = ['DD/MM/YYYY'];
const disabledDate = (current) => {
  return current && current < dayjs().endOf("day");
};

const AddOfferAndDiscount = (props) => {
  const [selectedCouponType, setSelectedCouponType] = React.useState("")
  const navigate = useNavigate();
  const selectRef = useRef();

  /*GraphQl  */
  const { data: categoryListData, loading: categoryListLoading, } = useCategoryListQuery();
  const [addUpdateCouponCodeMutation] = useAddUpdateCouponCodeMutation()

  const breadcrumb_items = [
    { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
    { label: MODULE_TITLES.OFFERS_DISCOUNT.LIST, to: LOCATIONS.OFFERS_AND_DISCOUNT_ROUTES.ROOT },
    { label: MODULE_TITLES.OFFERS_DISCOUNT.ADD }
  ];

  const validationSchema = Yup.object().shape({
    selectCategoryTypes: Yup.array().min(1, "Select at least one Category").required(commonValidationMessage("Category")),
    selectCoupon: Yup.string().required(commonValidationMessage("At least one Coupon")),
    titleForCoupon: Yup.string().max(120, "Can't enter more than 120 characters").required(commonValidationMessage("Title")),
    description: Yup.string().max(2000, "Can't enter more than 2000 characters").required(commonValidationMessage("Description")),
    date: Yup.array().min(2, 'Please select start and end date').required(commonValidationMessage("Date")),
    couponCode: Yup.string()
      .min(6, "Minimum 6 characters required")
      .max(12, "Can't enter more than 12 characters").matches(/^[^\s]{6,12}$/, "*Please Avoid WhiteSpace")
      .required(commonValidationMessage("Coupon")),
    uptoDiscountpercentage: Yup.number()
      .when('selectCoupon', {
        is: (selectCoupon) => selectCoupon === "UPTO_DISCOUNT",
        then: Yup.number()
          .min(1, 'Amount must be at least 1 %').max(100, "Amount Can't be More than 100 %")
          .required(commonValidationMessage("Percentage")),
        otherwise: Yup.number().nullable(),
      }),

    upToDiscountAmount: Yup.number()
      .when('selectCoupon', {
        is: (selectCoupon) => selectCoupon === "UPTO_DISCOUNT",
        then: Yup.number()
          .min(1, 'Amount must be greater than or equal to 1')
          // .max(1000000, "Can't Be more than 100000")
          .required(commonValidationMessage("Amount")),
        otherwise: Yup.number().nullable(),
      }),
    ddCoins: Yup.number()
      .when("selectCoupon", {
        is: (selectCoupon) => selectCoupon === "DD_COINS",
        then: Yup.number()
          .min(1, 'DD Coins must be greater than or equal to 1').max(10000000, "Can't Be More than 10000000")
          .required('DD Coins is required'),
        otherwise: Yup.number().nullable(),
      }),
    restrictUsersNumberCount: Yup.string()
  .test('restrictNumberCount', 'Number of user must be at least 1 ', function (value) {
    if (this.resolve(Yup.ref('restrictnumbersofusers'))) {
      return !value || parseInt(value, 10) >= 1;
    }
    return true;
  })
  .when('restrictnumbersofusers', {
    is: true,
    then: Yup.string()
      .required('Please enter the maximum number of users')
      .matches(/^\d+$/, 'Please enter a valid number'),
    otherwise: Yup.string().notRequired(),
  }),
    couponCartValueCount: Yup.string().
    test('couponCartValueCount', 'Cart value must be at least 1 ', function (value) {
      if (this.resolve(Yup.ref('couponCartValue'))) {
        return !value || parseInt(value, 10) >= 1;
      }
      return true;
    })
    .when('couponCartValue', {
      is: true,
      then: Yup.string()
        .required('Please enter the maximum number of users uses this coupan')
        .matches(/^\d+$/, 'Please enter a valid number'),
      otherwise: Yup.string().notRequired(),
    }),
      flatDiscountpercentage: Yup.string().when(["selectCoupon","flatDiscount"], (selectCoupon,flatDiscount) => {
        if (selectCoupon==="FLAT_DISCOUNT" && flatDiscount) {
          return Yup.number()
            .min(1, "Flat Discount must be at least 1 % ")
            .max(100, "Flat Discount Cant be More than 100 %")
            .required(commonValidationMessage("Percentage"))
        }
        return Yup.string().optional().nullable();
      }), 
      flatDiscountAmount: Yup.number().when(["selectCoupon","flatDiscount"], (selectCoupon,flatDiscount) => {
        if (selectCoupon==="FLAT_DISCOUNT" && flatDiscount === false) {
          return Yup.number()
            .min(1, "Flat Discount must be at least 1")
            .required(commonValidationMessage("Amount"))
        }
        return Yup.string().optional().nullable();
      }),
  });

  const formik = useFormik({
    initialValues: {
      selectCategoryTypes: [],
      selectCoupon: "",
      titleForCoupon: " ",
      description: " ",
      date: undefined,
      couponCode: '',
      restrictnumbersofusers: false,
      restrictUsersNumberCount: '',
      couponCartValue: false,
      couponCartValueCount: '',
      isDiscountInPercentage: '',
      flatDiscountpercentage: '',
      uptoDiscountpercentage: '',
      // uptoDiscountAmount: '',
      flatDiscountAmount: '',
      freeDeliveryApplied: '',
      flatDiscount: null,
      upToDiscount: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      let variables = {
        input: {
          categoryIds: values.selectCategoryTypes,
          couponType: values.selectCoupon,
          title: values.titleForCoupon,
          description: values.description,
          //date
          couponCode: values.couponCode,
          restrictNumberOfUsers: formik.values.restrictnumbersofusers,
          // restrictedUsersCount
          isCouponApplyOnOrderAmount: formik.values.couponCartValue,
          // appliedOrderAmount
          uptoDiscountAmount: "",
        },
      }
      if (values.selectCoupon === "FREE_DELIVERY") {
        delete variables.input.uptoDiscountAmount
      }
      if (values.selectCoupon === "FLAT_DISCOUNT") {
        delete variables.input.uptoDiscountAmount
        variables.input.isDiscountInPercentage = formik.values.flatDiscount
      }
      if (values.selectCoupon === "DD_COINS") {
        delete variables.input.uptoDiscountAmount
        variables.input.ddCoins = Number(formik.values.ddCoins)
      }
      if (values.restrictnumbersofusers === true) {
        variables.input.restrictedUsersCount = Number(formik.values.restrictUsersNumberCount);
      }
      if (values.couponCartValue === true) {
        variables.input.appliedOrderAmount = Number(formik.values.couponCartValueCount);
      }
      if (values.selectCoupon === "FLAT_DISCOUNT" && values.flatDiscount) {
        delete variables.input.uptoDiscountAmount
        variables.input.discountPercentage = Number(formik.values.flatDiscountpercentage)
      }
      if (values.selectCoupon === "FLAT_DISCOUNT" && !values.flatDiscount) {
        delete variables.input.uptoDiscountAmount
        delete variables.input.discountPercentage
        variables.input.discountAmount = Number(formik.values.flatDiscountAmount);
      }
      if (values.selectCoupon === "UPTO_DISCOUNT") {
        variables.input.discountPercentage = Number(formik.values.uptoDiscountpercentage);
        variables.input.uptoDiscountAmount = Number(values.upToDiscountAmount)
      }

      const startDateUTC=moment.utc(values.date[0],"DD/MM/YYYY").unix()*1000
      const endDateUTC = moment.utc(values.date[1],"DD/MM/YYYY").unix()*1000;
      delete variables.date;
      variables.input.startDate = startDateUTC.valueOf();
      variables.input.endDate = endDateUTC.valueOf();

      addUpdateCouponCodeMutation({
        variables: variables,
        onCompleted(data) {
          notification.success({ message: data.addUpdateCouponCode.message });
          actions.setSubmitting(false);
          navigate(
            LOCATIONS.OFFERS_AND_DISCOUNT_ROUTES.ROOT
          );
        },
        onError(error) {
          notification.error({ message: error.message });
          actions.setSubmitting(false);
        },
      });
    },
  });

  const handleselectCategoryTypeChange = (value) => {
    formik.setFieldValue("selectCategoryTypes", value);
    if (value.length === categoryListData?.categoryList?.length) {
      // Close the dropdown
      selectRef.current.blur();
    }
  };

  const handleCouponChange = React.useCallback((value) => {
    setSelectedCouponType(value);
    formik.setFieldValue("selectCoupon", value);
  }, [setSelectedCouponType]);

  const handleRestrictedUserRadioChange = (e) => {
    let selectedRadioButton = e.target.value
    formik.setFieldValue('restrictnumbersofusers', selectedRadioButton);
    if (selectedRadioButton === 'No') {
      formik.setFieldValue('restrictUsersNumberCount', "");
    }
  };
  const handleCartValueUserRadioChange = (e) => {
    let selectedRadioButton = e.target.value
    formik.setFieldValue('couponCartValue', selectedRadioButton);
    if (selectedRadioButton === 'No') {
      formik.setFieldValue('restrictUsersNumberCount', "");
    }
  };
  React.useEffect(() => {
    if (formik.values.restrictnumbersofusers ) {
      formik.setFieldValue('restrictUsersNumberCount', " ");
    }
  }, [formik.values.restrictnumbersofusers])
  React.useEffect(()=>{
    if (formik.values.couponCartValue ) {
      formik.setFieldValue('couponCartValueCount', " ");
    }
  },[ formik.values.couponCartValue])

  return (
    <Content className="content">
      <div className="page-header">
        <h2 className="page-title">
          {MODULE_TITLES.OFFERS_DISCOUNT.ROOT}
        </h2>
        <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
      </div>
      <div className="content-wrapper">
        <Card title={MODULE_TITLES.OFFERS_DISCOUNT.ADD}>
          <FormikProvider value={formik}>
            <Form
              labelAlign="left"
              labelWrap={true}
              name="add-edit-category-page-form"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 10 }}
            >
              <Form.Item name="selectCategoryTypes" label="Select Category" >
                <Select
                  allowClear={true}
                  placeholder="Select Category"
                  mode="multiple"
                  name="selectCategoryTypes"
                  value={formik.values.selectCategoryTypes}
                  loading={categoryListLoading}
                  options={categoryListData?.categoryList?.map(item => ({ label: item.categoryName, value: item._id })) || []}
                  onChange={handleselectCategoryTypeChange}
                  onBlur={() => formik.setFieldTouched("selectCategoryTypes", true)}
                  ref={selectRef}
                />
              </Form.Item>

              <Form.Item
                label="Select Coupon Type"
                name="selectCoupon"
                required
              >
                <Select
                  allowClear={true}
                  placeholder="Select Coupon"
                  name="selectCoupon"
                  style={{ minWidth: "300px" }}
                  value={formik.values.selectCoupon || undefined}
                  onChange={handleCouponChange}
                  options={Object.values(OFFERS_AND_COUPON_TYPE).map(val => ({ label: val.replace(/_/g, " "), value: val, }))}
                />
              </Form.Item>
              {selectedCouponType !== "" && <SelectedOfferType selectedCouponType={selectedCouponType} formik={formik} />}
              {
                <div>
              <AntdForm.Item label="Title" name="titleForCoupon" id="titleForCoupon" required validateStatus={formik.errors['titleForCoupon'] && "error"}
                help={formik.errors['titleForCoupon']}>
                <AntdInput.TextArea
                  name="titleForCoupon"
                  id="titleForCoupon"
                  type="text"
                  placeholder="Title for the Coupon..."
                  maxLength={121}
                  rows={3}
                  onChange={(e) => formik.setFieldValue(e.currentTarget.name, e.currentTarget.value)}
                  value={formik.values.titleForCoupon}
                />
              </AntdForm.Item>
            </div>
          }

              {<AntdForm.Item label="Description" name="description" id="description" required validateStatus={formik.errors['description'] && "error"}
                help={formik.errors['description']}
              >
                <AntdInput.TextArea
                  name="description"
                  id="description"
                  type="text"
                  placeholder="Description for the Coupon..."
                  maxLength={20001}
                  rows={3}
                  onChange={(e) => formik.setFieldValue(e.currentTarget.name, e.currentTarget.value)}
                  value={formik.values.description}
                />
              </AntdForm.Item>}

              <Form.Item label="Start-End Date" name="date" id="date" required>
                <RangePicker
                  onChange={(data, dateString) => {
                    formik.setFieldValue("date", dateString);
                  }}
                  name="date"
                  onBlur={() => formik.handleBlur({ target: { name: 'date' }, currentTarget: { name: 'date' } })}
                  disabledDate={disabledDate}
                  format={dateFormatList}
                  inputReadOnly
                  clearIcon={false}
                />
              </Form.Item>

              <Form.Item
                label="Coupon Code"
                name="couponCode"
                required
              >
                <Input type="text"
                  value={formik.values.couponCode}
                  name="couponCode"
                  id="couponCode"
                  maxLength={12}
                  minLength={6}
                  placeholder="Enter Coupon Code..."
                  suffix={<span />}
                  autoComplete="off" //stpp showing previous suggestion
                  onInput={e =>   {
                    const input = e.target;
                    const start = input.selectionStart;
                    const end = input.selectionEnd;                 
                    input.value = e.target.value.toUpperCase();
                    // Set the selection range back to the original position
                    input.setSelectionRange(start, end)} }
                  onKeyDown={(e)=>{
                    [',',";","."].includes(e.key) && e.preventDefault()
                  }}
                  onWheel={(e) => e.target.blur()}

                />
              </Form.Item>

              <Form.Item label="Do You Want To Restrict The Number Of Users ? " name="restrictnumbersofusers">
                <Radio.Group onChange={handleRestrictedUserRadioChange} defaultValue={false} >
                  <Radio value={true}>{RADIO_BUTTON_FOR.YES}</Radio>
                  <Radio value={false}>{RADIO_BUTTON_FOR.NO}</Radio>
                </Radio.Group>
              </Form.Item>
              <ConditionalComponent shouldShow={formik.values.restrictnumbersofusers === true}>
                <Form.Item label="Maximum number of users " name="restrictUsersNumberCount" required>
                  <Input
                    type="number"
                    value={formik.values.restrictUsersNumberCount}
                    // onChange={(e) => {
                    //   formik.setFieldValue('restrictUsersNumberCount', e.target.value);
                    // }}
                    name="restrictUsersNumberCount"
                    id="restrictUsersNumberCount"
                    placeholder="Enter the number of users you want to restrict..."
                    suffix={<span />}
                    min={1}
                    onChange={(e) => formik.setFieldValue('restrictUsersNumberCount', e.target.value.slice(0, 4))}
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
              </ConditionalComponent>

              <Form.Item label="Do You Want To Apply This Coupon On Cart Value ? "
                name="couponCartValue"
              >
                <Radio.Group onChange={handleCartValueUserRadioChange} defaultValue={false} >
                  <Radio value={true}>{RADIO_BUTTON_FOR.YES}</Radio>
                  <Radio value={false}>{RADIO_BUTTON_FOR.NO}</Radio>
                </Radio.Group>
              </Form.Item>
              <ConditionalComponent shouldShow={formik.values.couponCartValue === true}>
                <Form.Item
                  label="Cart Value Count"
                  name="couponCartValueCount"
                  required
                >
                  <Input
                    type="number"
                    value={formik.values.couponCartValueCount}
                    name="couponCartValueCount"
                    id="couponCartValueCount"
                    placeholder="Enter the Cart Value..."
                    suffix={<span />}
                    min={1}
                    onChange={(e) => formik.setFieldValue('couponCartValueCount', e.target.value.slice(0, 7))}
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
              </ConditionalComponent>

              <Col sm={24} md={12} className="text-right">
                <SubmitButton loading={formik.isSubmitting} type="primary" >
                  {MODULE_TITLES.COMMON.SAVE}
                </SubmitButton>
              </Col>
            </Form>
          </FormikProvider>
        </Card>
      </div>
    </Content>
  );
};

export default AddOfferAndDiscount;

const SelectedOfferType = (props) => {
  let coupanType = props.selectedCouponType
  const [flatDiscountType, setFlatDiscountType] = React.useState('');
  const [uptoDiscountType, setUptoDiscountType] = React.useState('')

  //Flat Discount Change Handler
  const handleFlatDiscountTypeChange = (e) => {
    const selectedType = e.target.value;
    props.formik.setFieldValue('flatDiscount', selectedType);
    setFlatDiscountType(selectedType);

  };
  const handleUptoDiscountTypeChange = (e) => {
    const selectedType = e.target.value;
    props.formik.setFieldValue('upToDiscount', selectedType);
    setUptoDiscountType(selectedType);
  };
  React.useEffect(() => {
    props.formik.setFieldValue("flatDiscountpercentage", "", true)
    props.formik.setFieldValue('flatDiscountAmount', "", true);
    props.formik.setFieldValue("uptoDiscountpercentage", "", true)
    props.formik.setFieldValue('upToDiscountAmount', "", true);
    props.formik.setFieldTouched('flatDiscountpercentage',"",true)
    props.formik.setFieldTouched('flatDiscountAmount',"",true)
    props.formik.setFieldTouched('uptoDiscountpercentage',"",true)
    props.formik.setFieldTouched('upToDiscountAmount',"",true)
  }, [flatDiscountType, uptoDiscountType, coupanType])

  switch (coupanType) {
    case OFFERS_AND_COUPON_TYPE.DD_COINS:
      return (
        <Form.Item label="Enter number of coin users will get by redeem this coupon ? " name="ddCoins" required>
          <Input type="number"
            value={props.formik.values.ddCoins}
            name="ddCoins"
            id="ddCoins"
            placeholder="Enter no. of coins..."
            suffix={<span />}
            min={1}
            onChange={(e) => props.formik.setFieldValue('ddCoins', e.target.value.slice(0, 7))}
            onWheel={(e) => e.target.blur()}
          />
        </Form.Item>
      );
    case OFFERS_AND_COUPON_TYPE.FLAT_DISCOUNT:
      return (
        <>
          <Form.Item label="Want To Give Discount In ? " name="flatDiscount" required>
            <Row gutter={20} className="d-flex">
              <Col>
                <Radio.Group onChange={handleFlatDiscountTypeChange} value={flatDiscountType} name="flatDiscount" className="d-flex">
                  <Radio value={true} name="flatDiscountPercentageButton">
                    {DISCOUNT_TYPE.PERCENTAGE} %
                  </Radio>
                  <Radio value={false} name="flatDiscountPercentageButton">
                    {DISCOUNT_TYPE.AMOUNT} {"\u20B9"}
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
          </Form.Item>

          <ConditionalComponent shouldShow={props.formik.values.flatDiscount} >
            <Form.Item label="Enter Flat Discount %" name="flatDiscountpercentage" required>
              <Input
                type="number"
                value={props.formik.values.flatDiscountpercentage}
                name="flatDiscountpercentage"
                id="flatDiscountpercentage"
                placeholder="Enter Discount %..."
                suffix={<span />}
                min={1}
                max={99}
                onChange={(e) => props.formik.setFieldValue('flatDiscountpercentage', e.target.value.slice(0, 2))}
                onWheel={(e) => e.target.blur()}
              />
            </Form.Item>
          </ConditionalComponent>

          <ConditionalComponent
            shouldShow={!props.formik.values.flatDiscount}
          >
            <Form.Item label="Enter Flat Discount Amount" name="flatDiscountAmount" required >
              <Input
                type="number"
                value={props.formik.values.flatDiscountAmount}
                name="flatDiscountAmount"
                id="flatDiscountAmount"
                placeholder="Enter Discount Amount..."
                suffix={<span />}
                min={1}
                onChange={(e) => props.formik.setFieldValue('flatDiscountAmount', e.target.value.slice(0, 7))}
                onWheel={(e) => e.target.blur()}
              />
            </Form.Item>
          </ConditionalComponent>
        </>
      );
    case OFFERS_AND_COUPON_TYPE.UPTO_DISCOUNT:
      return (
        <>

          {uptoDiscountType &&
            <Form.Item
              label="Want to give discount in percentage or Amount?"
              name="upToDiscount"
              required
            >
              <Radio.Group onChange={handleUptoDiscountTypeChange} value={uptoDiscountType} name="upToDiscount">
                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '50px', flexWrap: 'wrap' }} >
                  <div>
                    <Radio value={true}>{DISCOUNT_TYPE.PERCENTAGE}{" "} % </Radio>
                  </div>
                  <div>
                    <Radio value={false}>{DISCOUNT_TYPE.AMOUNT}{" "}<strong name="Rs">{"\u20B9"} </strong></Radio>
                  </div>
                </div>
              </Radio.Group>
            </Form.Item>}

          <Form.Item label="Enter Upto Discount %" name="uptoDiscountpercentage" required >
            <Input
              type="number"
              value={props.formik.values.uptoDiscountpercentage}
              name="uptoDiscountpercentage"
              id="uptoDiscountpercentage"
              placeholder="Enter Discount in %..."
              suffix={<span />}
              min={1}
              onBlur={props.formik.uptoDiscountpercentage}
              onChange={(e) => props.formik.setFieldValue('uptoDiscountpercentage', e.target.value.slice(0, 2))}
              onWheel={(e) => e.target.blur()}

            />
          </Form.Item>

          <Form.Item label="Enter Discount Amount upto" name="upToDiscountAmount" required >
            <Input
              type="number"
              value={props.formik.values.upToDiscountAmount}
              name="upToDiscountAmount"
              id="upToDiscountAmount"
              placeholder="Enter Discount Amount..."
              suffix={<span />}
              min={1}
              onChange={(e) => props.formik.setFieldValue('upToDiscountAmount', e.target.value.slice(0, 7))}
              onWheel={(e) => e.target.blur()}
            />
          </Form.Item>
        </>
      );
    case OFFERS_AND_COUPON_TYPE.FREE_DELIVERY:
      return (<>
        <Form.Item label="Free Delivery" name="freeDeliveryApplied" required >
          <Input
            type="number"
            value={props.formik.values.freeDeliveryApplied}
            name="freeDeliveryApplied"
            id="freeDeliveryApplied"
            placeholder="Free Delivery is Applied"
            suffix={<span />}
            min={1}
            disabled
          />
        </Form.Item>
      </>)
    default:
      return null;
  }
}