import { Space, Tag } from 'antd';
import React from 'react'
import Resizer from "react-image-file-resizer";

function generateRandomFileName(prefix) {
    const timestamp = Date.now() + Math.floor(Math.random() * 100);
    if (prefix) {
        return '254_' + prefix + '_' + timestamp;
    } else {
        return '254_' + timestamp;
    }

}
function removeValuesFromResult(data,keys=[],values=[]){
    function replacer(key, value) {
        if (values.includes(value) || keys.includes(key) ) {
          return undefined;
        }
        return value;
      }
      return JSON.parse(JSON.stringify(data,replacer))    
}
function imageFileResizer(file,width, height){
    return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
            file,
            width ? width : 300,
            height ? height : 300,
            "PNG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "file"
        )
    })
}

function checkIfImageExists(url) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;

        if (img.complete) {
            resolve(true);
        } else {
            img.onload = () => {
                resolve(true);
            };

            img.onerror = (err) => {
                reject();
            };
        }
    })
}
function getFormatedValue(value) {
    let valueType = typeof (value);
    switch (valueType) {
        case "undefined":
            return '-';
        case "string":
            return value;
        case "number":
            return value;
        case "object":{
            return React.isValidElement(value) 
            ? value
            : Array.isArray(value) 
            ? value.toString() : '-';
        }
        case "boolean":
            return value ? "Yes" : "No"
        default:
            return 'N/A'
    }
}
function getTableRowKey(record){
    return record?._id; 
}
function doNothing(){
    return false;
}

function RenderTags({data = []}){
 if(!data || data.length === 0){
    return null
 }
 const tags = data.map((value) => <Tag key={value} >{value}</Tag>) 

 return <Space size={[2, 8]} wrap>{tags}</Space>
}

function preventDefault(e){
    e.preventDefault();
};
  

export { generateRandomFileName, imageFileResizer, checkIfImageExists, removeValuesFromResult, getFormatedValue, getTableRowKey, doNothing, RenderTags, preventDefault };
