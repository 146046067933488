import React from "react";
import { Card, Col, Divider,  Row,  Typography } from "antd";
import { ListItem } from "../Product/DetailSections/common";
import { isValidDate } from "../../../helper/DateFormatter";

const InvoiceDetails = ({invoiceOrderPaymentDetails,invoiceNumberId,deliveredDate,expectedDeliveryDate,sampleOrderType}) => {

    const deliveryType = invoiceOrderPaymentDetails?.deliveryType;
    const formattedDeliveryType = deliveryType
        ? deliveryType.replace(/_/g, " ")
        : "";

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return (
      <>
          <Row>
              <Col sm={24} md={24}>
                  <Typography.Text strong>
                      <Divider orientation='left' >Invoice Order Payment Details </Divider>
                  </Typography.Text>
              </Col>
          </Row>
          <Row gutter={6} wrap>
              <Col md={12}>
                  <Card>
                  <ListItem
                          item={{
                              label: "Invoice Number Id",
                              value: invoiceNumberId,
                          }}
                          labelStrong={true}
                      />
                       <ListItem
                          item={{
                              label: "Delivery Type",
                              value: formattedDeliveryType,
                          }}
                          labelStrong={true}
                      />
                       <ListItem
                          item={{
                              label: "Sample Order Type",
                              value: sampleOrderType,
                          }}
                          labelStrong={true}
                      />
                      
                      <ListItem
                          item={{
                              label: "totalPrice",
                              value: `₹ ${invoiceOrderPaymentDetails?.totalPrice}`,
                          }}
                          labelStrong={true}
                      />
                      <ListItem
                          item={{
                              label: "Discount",
                              value: `₹ ${invoiceOrderPaymentDetails?.discountOnPrice}`,
                          }}
                          labelStrong={true}
                      />
                      <ListItem
                          item={{
                              label: "GST Inc.",
                              value: `₹ ${invoiceOrderPaymentDetails?.gstRatePrice}`,
                          }}
                          labelStrong={true}
                      />
                      <ListItem
                          item={{
                              label: "Delivery Charge",
                              value: `₹ ${invoiceOrderPaymentDetails?.deliveryCharge}`,
                          }}
                          labelStrong={true}
                      />
                      <ListItem
                          item={{
                              label: "Total Payable Amount",
                              value: `₹ ${invoiceOrderPaymentDetails?.totalPayableAmount}`,
                          }}
                          labelStrong={true}
                      />
                      <ListItem
                          item={{
                             
                              label: "Expected Delivery Date",
                              value: expectedDeliveryDate && isValidDate(new Date(expectedDeliveryDate)) ?
                              new Date(expectedDeliveryDate).toLocaleDateString('en-US', options) : "-",
                          }}
                          labelStrong={true}
                      />
                      <ListItem
                          item={{
                            label: "Delivered Date",
                              value: deliveredDate && isValidDate(new Date(deliveredDate)) ?
                              new Date(deliveredDate).toLocaleDateString('en-US', options) : "-",
                          }}
                          labelStrong={true}
                      />
                   
                  </Card>
              </Col>
          </Row>
      </>
  );
};

export default InvoiceDetails;
