import React from "react";
import { Card, Col, Divider, Image, Row, Space, Typography } from "antd";
import { default_s } from "../../../helper/importHelper/image";
import { onImageLoadError } from "../../../helper/imageHelper";

const SampleDetail = ({ productImage, productName, unit, quantity }) => {
    return (
        <>
            <Row>
                <Col sm={24} md={24}>
                    <Typography.Text strong>
                        <Divider orientation="left">Order Details :</Divider>
                    </Typography.Text>
                </Col>
            </Row>
            <Row gutter={6}>
                <Col span={12}>
                    <SetCardView
                        productName={productName}
                        productImage={productImage}
                        unit={unit}
                        quantity={quantity}
                    />
                </Col>
            </Row>
        </>
    );
};

const SetCardView = ({ productImage, productName, unit, quantity }) => {
    return (
        <Col span={24}>
          <Card size="default">
            <Row justify={"space-between"} align={"middle"} gutter={6}>
              <Col >
                <Image alt=" " onError={onImageLoadError} src={productImage ? productImage : default_s} style={{ maxHeight: 100, maxWidth: 100 }} />
              </Col>
              <Col span={18}>
                <Typography.Text strong style={{wordWrap:'break-word'}}>{productName}</Typography.Text><br />
                <Space>
                    <Row>
                      <div style={{ display: 'flex', flexDirection: 'column',padding:"0px 10px 0px 0px" }} >
                        <Col>
                          <Typography.Text strong>Unit : </Typography.Text>{" "}{unit}<br />
                        </Col>
                        <Col>
                          <Typography.Text strong>Quantity : </Typography.Text>{" "}{quantity}<br />
                        </Col>
                      </div>     
                    </Row>             
                </Space>   
              </Col>
            </Row>
          </Card>
        </Col>
      
    );
  };
export default SampleDetail;
