import { BulkPriceSectionSchema } from "./validations";

/**
 * 
 * @param {import("../../../../graphql/generated").BulkPriceField[]} input
 * @returns
 */
const getBulkPrice = (input) => {
    if (!Array.isArray(input) || input.length === 0) {
        return [{
            price: undefined,
            days: undefined,
        }]
    }
    return input.map((item) => ({
        price: item.price,
        days: item.days,
    }))
}


/**
 * 
 * @param {import("../../../../graphql/generated").PromoteYourAdvDetailsQueryResult['data']} data 
 * @returns 
 */
export async function onDetailsLoadCompleted(data) {
    if (!data?.promoteYourAdvDetails) {
        return;
    }
    const {
        categoryId,
        _id,
        advertisementType,
        sectionType,
        uniqueImpressions,
        price,
        bulkPrice,
    } = data.promoteYourAdvDetails;

    this.setFieldValue("_id", _id, true);
    this.setFieldValue("categoryId", categoryId, true);
    this.setFieldValue("advertisementType", advertisementType, true);
    this.setFieldValue("sectionType", sectionType, true);
    this.setFieldValue("uniqueImpressions", uniqueImpressions, true);
    this.setFieldValue("perDayPrice", price, true);
    this.setFieldValue("bulkPrice", getBulkPrice(bulkPrice), true);
}


export function generatePayload(values) {
    let variables = {
        categoryId: values.categoryId,
        advertisementType: values.advertisementType,
        sectionType: values.sectionType,
        price: Number(values.perDayPrice),
        uniqueImpressions: Number(values.uniqueImpressions),
    };

    if (values._id) {
        variables._id = values._id
    }

    const bulkPrice = values.bulkPrice.filter((i) =>
        BulkPriceSectionSchema.isValidSync(i)
    );

    if (bulkPrice.length > 0) {
        variables.bulkPrice = bulkPrice;
    }

    return variables;
}