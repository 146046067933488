import React, { useCallback, useEffect, useState } from "react";
import { Content } from "antd/lib/layout/layout";
import { FormikProvider, useFormik } from "formik";
import { Card, Col, DatePicker, notification } from "antd";
import * as Yup from "yup";
import { Form, Input, SubmitButton, Select } from "formik-antd";
import { MODULE_TITLES } from "../../../../config/moduleConfig";
import { commonValidationMessage } from "../../../../helper/MessagesHelper";
import { ConditionalComponent } from "../../../common/GeneralComponents";
import { PAYMENT_MODE } from "../../../../config/constants";
import { useAddManualQuotationPaymentMutation, useGetUserBalanceLazyQuery } from "../../../../graphql/generated/index.tsx";
import moment from "moment";
import dayjs from "dayjs";

const OFFLINE_PAYMENT_MODE = [
  { label: "CHEQUE", value: "CHEQUE" },
  { label: "RTGS", value: "RTGS" },
  { label: "IMPS", value: "IMPS" },
  { label: "NEFT", value: "NEFT" },
  { label: "UPI", value: "UPI" },
  { label: "CASH", value: "CASH" },
  { label: "CREDIT BALANCE", value: "CREDIT_BALANCE" }, 
 ]

const OflinePaymentQuotation = ({ tabState, manualQuotationId, userId ,setReceivedOrderAmountData ,setTabState}) => {
  const [creditDebitDetail,setCreditDebitDetail]=useState([])
  const [addManualQuotationPayment, { loading: addManualQuotationPaymentloading }] = useAddManualQuotationPaymentMutation()
  const [getUserBalance, { loading: getUserBalanceLoading, refetch }] = useGetUserBalanceLazyQuery();
  const getUserBalanceList = useCallback((userIdArg,pageArg,limitArg) => {
      let variables = {};
      userIdArg && (variables.userId = userIdArg);
      pageArg  && (variables.page = pageArg  - 1 );
      limitArg   && (variables.limit = limitArg );

      getUserBalance({
        variables: variables,
        onCompleted(data) {
          setCreditDebitDetail(data?.getUserBalance)
     },
        async update() {
          refetch()
      },
        fetchPolicy: "network-only",
      });
    },
    [userId, getUserBalance,addManualQuotationPayment]
  );

  const quoationOflinePaymentModeSchema = Yup.object().shape({
    type: Yup.string()
      .required('Payment Mode is required')
      .oneOf(['CHEQUE', 'RTGS','IMPS','NEFT','CASH','UPI','CREDIT_BALANCE'], 'Invalid charge type. Please select either CHEQUE or RTGS.'),
    chequeNumber: Yup.string().when('type', {
      is: 'CHEQUE',
      then: Yup.string().required(commonValidationMessage('Cheque Number')),
    }),
    amount: Yup.number().min(1,commonValidationMessage('Amount')).when('type', {
      is: 'CHEQUE',
      then: Yup.number().required(commonValidationMessage('Amount')),
      otherwise: Yup.number().strip(), // This removes the field from validation
    }),
    chequeDate: Yup.string().when('type', {
      is: 'CHEQUE',
      then: Yup.string().required(commonValidationMessage('Cheque Date')),
    }),
    Chequeclearingdate: Yup.string().when('type', {
      is: 'CHEQUE',
      then: Yup.string().required(commonValidationMessage('Cheque Clearing Date')),
    }),
    paymentReferenceNumber: Yup.string().when('type', {
      is: (val) => {
        return val !== 'CASH' && ['RTGS', 'IMPS', 'NEFT', 'UPI'].includes(val);
      },
      then: Yup.string().required('Reference Number is required'), // Validation for specified 'type' values excluding 'CASH'
    }),
    referenceDate: Yup.string().when('type', {
      is: (val) => {
        return val === 'RTGS' || val === 'IMPS'|| val === 'NEFT'|| val === 'CASH' || val === 'UPI';
      },
      then: Yup.string().required(commonValidationMessage('Reference Date')),
    }),
    refrenceAmount: Yup.number().when('type',{
      is: (type) => {
        return type !== 'CHEQUE' && (type === 'RTGS' || type === 'IMPS' || type === 'NEFT' || type === 'CASH' || type === 'UPI');
      },
      then: Yup.number()
        .typeError('Amount must be a number')
        .required('Amount is required')
        .integer('Amount must be an integer')
        .positive('Amount must be a positive number'),
      otherwise: Yup.number()
        .nullable()
        .transform((value, originalObject) => {
          return originalObject.type === 'CHEQUE' ? null : value;
        })
        .max(creditDebitDetail?.totalAmount, `Amount can't be more than ${creditDebitDetail?.totalAmount}`).min(1,'Atleast 1 Rs is required')
    }),
    
  });

  const formik = useFormik({
    initialValues: {
      type: "",
      chequeNumber: "",
      chequeDate: '',
      Chequeclearingdate: '',
      amount: "",
      referenceDate:''
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: quoationOflinePaymentModeSchema,
    onSubmit: (values, actions) => {
      const chequeDateUTC = moment.utc(values?.chequeDate).unix() * 1000
      const ChequeclearingDateUTC = moment.utc(values?.chequeDate).unix() * 1000
      const rtgsDateUTC = moment.utc(values?.paymentDate).unix() * 1000
      if (tabState === PAYMENT_MODE.OFFLINE) {
        const input = {
          paymentMode: tabState && tabState,
          manualQuotationId: manualQuotationId,
          offlinePaymentDetails: {
            offlinePaymentType: values?.type,
          }
        };
        if (values?.type === "CHEQUE") {
          input.offlinePaymentDetails = {
            ...input.offlinePaymentDetails,
            amount: Number(values?.amount),
            chequeNumber: Number(values?.chequeNumber),
            chequeDate: chequeDateUTC.valueOf(),
            // chequeclearing:ChequeclearingDateUTC.valueOf()
          };
        }
        if (values?.type === "RTGS" ||  values?.type === 'IMPS'|| values?.type === 'NEFT'|| values?.type === 'UPI'||values?.type === 'CREDIT_BALANCE') {
          input.offlinePaymentDetails = {
            ...input.offlinePaymentDetails,
            paymentReferenceNumber: Number(values?.paymentReferenceNumber),
            paymentDate: rtgsDateUTC.valueOf(),
            amount: values?.refrenceAmount
          };
        }
        if (values?.type === "CASH" ) {
          input.offlinePaymentDetails = {
            ...input.offlinePaymentDetails,
            paymentDate: rtgsDateUTC.valueOf(),
            amount: values?.refrenceAmount
          };
        }
        if (values?.type === "CREDIT_BALANCE" ) {
          input.offlinePaymentDetails = {
            ...input.offlinePaymentDetails,
            paymentDate: rtgsDateUTC.valueOf(),
            amount: values?.refrenceAmount
          };
        }
        addManualQuotationPayment({
          variables: {
            input: input
          },
          onCompleted(data) {
            setReceivedOrderAmountData(data?.addManualQuotationPayment?.data?.receivedOrderAmount)
            notification.success({
              message: data.addManualQuotationPayment.message,
            });
          },
          onError(error) {
            notification.error({ message: error.message });
          },
          fetchPolicy: "network-only",
        });
      }
      actions.setSubmitting(false);
      formik.resetForm();
      setTabState(PAYMENT_MODE.HISTORY)     
    }
  });
  useEffect(() => {
    if (formik.values.type !== formik.initialValues.type) {
      formik.setValues({
        ...formik.initialValues,
        type: formik.values.type, 
      });
      formik.setTouched({});
      formik.setErrors({});
    }
  }, [formik.values.type, formik.initialValues, formik.setValues, formik.setTouched, formik.setErrors]);

  useEffect(() => {
    if (formik.values.type === "CREDIT_BALANCE"){
      getUserBalanceList(userId, 1, 20);
    }
    //  formik.setFieldValue('chequeDate','',true)
     formik.setFieldValue('referenceDate','',false)
  },[formik.values.type]);

  useEffect(()=>{
    formik.resetForm()
  },[tabState])

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day") ;
  };
  
  return (
    <Content className="content">
      <Card loading={addManualQuotationPaymentloading || getUserBalanceLoading}>
        <div className="content-wrapper">
          <FormikProvider value={formik}>
            <Form
              labelAlign="left"
              labelWrap={true}
              name="add-edit-category-page-form"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 10 }}
              onFinish={formik.handleSubmit}
            >
              <Form.Item label="Offline Payment Mode"
                name="type"
                required
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 10 }}
              >
                <Select
                  name="type"
                  style={{ minWidth: 200 }}
                  allowClear
                  placeholder="Payment Mode..."
                  options={OFFLINE_PAYMENT_MODE}
                />
              </Form.Item>

              <ConditionalComponent shouldShow={formik.values.type === "CHEQUE"}>
                <Form.Item
                  label="Cheque Number"
                  name="chequeNumber"
                  required
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 10 }}
                >
                  <Input
                    type="number"
                    id="chequeNumber"
                    name="chequeNumber"
                    onChange={(e)=>formik.setFieldValue('chequeNumber', e.target.value.slice(0, 6))}
                    value={formik.values.chequeNumber}
                    placeholder=" Cheque Number..."
                    suffix={<span />}

                  />
                </Form.Item>

                <Form.Item
                  label="Cheque Date"
                  name="chequeDate"
                  required
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 10 }}
                >
                  <DatePicker
                    id="chequeDate"
                    name="chequeDate" style={{ width: "100%" }}
                    onChange={(data, dateString) => {
                      formik.setFieldValue("chequeDate", dateString);
                    }}
                    disabledDate={disabledDate}
                    inputReadOnly
                    onBlur={() => formik.handleBlur({ target: { name: 'chequeDate' }, currentTarget: { name: 'chequeDate' } })}
                  />
                </Form.Item>

                <Form.Item
                  label="Cheque clearing date"
                  name="Chequeclearingdate"
                  required
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 10 }}
                >
                  <DatePicker
                    id="Chequeclearingdate"
                    name="Chequeclearingdate" style={{ width: "100%" }} onChange={(data, dateString) => {
                      formik.setFieldValue("Chequeclearingdate", dateString);
                    }}
                    disabledDate={disabledDate}
                    inputReadOnly
                    onBlur={() => formik.handleBlur({ target: { name: 'Chequeclearingdate' }, currentTarget: { name: 'Chequeclearingdate' } })}
                  />
                </Form.Item>

                <Form.Item
                  label="Amount"
                  name="amount"
                  required
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 10 }}
                >
                  <Input
                    type="number"
                    id="amount"
                    name="amount"
                    min={1}
                    onChange={(e) => formik.setFieldValue('amount', Number(e.target.value.slice(0, 6)))}
                    value={formik.values.amount}
                    placeholder="Amount..."
                    suffix={<span />}
                    maxLength={7}
                    
                  />
                </Form.Item>
              </ConditionalComponent>

              <ConditionalComponent shouldShow={formik.values.type === "RTGS" || formik.values.type === "IMPS" ||  formik.values.type === "NEFT" || formik.values.type==="UPI"}>
                <Form.Item
                  label="Reference Number"
                  name="paymentReferenceNumber"
                  required
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 10 }}
                >
                  <Input
                    type="text"
                    id="paymentReferenceNumber"
                    name="paymentReferenceNumber"
                    onChange={formik.handleChange}
                    value={formik.values.paymentReferenceNumber}
                    placeholder="Reference Number..."
                    suffix={<span />}
                  />
                </Form.Item>
                
                <Form.Item
                  label="Date"
                  name="referenceDate"
                  required
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 10 }}
                >
                  <DatePicker
                    id="referenceDate"
                    name="referenceDate" style={{ width: "100%" }} onChange={(data, dateString) => {
                      formik.setFieldValue("referenceDate", dateString);
                    }}
                    disabledDate={disabledDate}
                    inputReadOnly
                    onBlur={() => formik.handleBlur({ target: { name: 'referenceDate' }, currentTarget: { name: 'referenceDate' } })}
                  />
                </Form.Item>

                <Form.Item
                  label="Amount"
                  name="refrenceAmount"
                  required
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 10 }}
                >
                  <Input
                    type="number"
                    id="refrenceAmount"
                    name="refrenceAmount"
                    onChange={(e) => formik.setFieldValue('refrenceAmount', Number(e.target.value.slice(0, 6)))}
                    value={formik.values.refrenceAmount}
                    placeholder="Amount..."
                    suffix={<span />}
                    maxLength={7}
                  />
                </Form.Item>
              </ConditionalComponent>

              <ConditionalComponent shouldShow={formik.values.type === "CASH"}>
              <Form.Item
                  label="Date"
                  name="referenceDate"
                  required
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 10 }}
                >
                  <DatePicker
                    id="referenceDate"
                    name="referenceDate" style={{ width: "100%" }} onChange={(data, dateString) => {
                      formik.setFieldValue("referenceDate", dateString);
                    }}
                    disabledDate={disabledDate}
                    inputReadOnly
                    onBlur={() => formik.handleBlur({ target: { name: 'referenceDate' }, currentTarget: { name: 'referenceDate' } })}
                  />
                </Form.Item>

                <Form.Item
                  label="Amount"
                  name="refrenceAmount"
                  required
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 10 }}
                >
                  <Input
                    type="number"
                    id="refrenceAmount"
                    name="refrenceAmount"
                    onChange={(e) => formik.setFieldValue('refrenceAmount', Number(e.target.value.slice(0, 6)))}
                    value={formik.values.refrenceAmount}
                    placeholder="Amount..."
                    suffix={<span />}
                    maxLength={7}
                  />
                </Form.Item>

              </ConditionalComponent>

              <ConditionalComponent shouldShow={formik.values.type === "CREDIT_BALANCE"}>
              <Form.Item
                  label="Available Amount"
                  name="availableAmount"
                  required
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 10 }}
                  
                >
                  <Input
                    type="number"
                    id="availableAmount"
                    name="availableAmount"
                    value={creditDebitDetail?.totalAmount}
                    max={creditDebitDetail?.totalAmount}
                    min={1}
                    placeholder="Amount..."
                    suffix={<span />}
                    maxLength={7}
                    disabled
                    
                  />
                </Form.Item>

                <Form.Item
                  label="Amount"
                  name="refrenceAmount"
                  required
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 10 }}
                >
                  <Input
                    type="number"
                    id="refrenceAmount"
                    name="refrenceAmount"
                    onChange={(e) => formik.setFieldValue('refrenceAmount', Number(e.target.value.slice(0, 6)))}
                    value={formik.values.refrenceAmount}
                    placeholder="Amount..."
                    suffix={<span />}
                    maxLength={7}
                    max={creditDebitDetail?.totalAmount}
                  />
                </Form.Item>

              </ConditionalComponent>

              <Col sm={10} md={13} className="text-right mt-12px" >
                <SubmitButton
                  loading={formik.isSubmitting}
                  type="primary"
                  htmlType="submit"
                >
                  {MODULE_TITLES.COMMON.SAVE}
                </SubmitButton>
              </Col>
            </Form>
          </FormikProvider>
        </div>
      </Card>
    </Content>
  );
};

export default OflinePaymentQuotation;

