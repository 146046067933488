import aws from 'aws-sdk';

const COGNITO_POOL_ID = process.env.REACT_APP_AWS_COGNITO_POOL_ID
const BUCKET_NAME = process.env.REACT_APP_AWS_S3_BUCKET
const REGION = process.env.REACT_APP_AWS_S3_REGION

class AWSHelper {
    static upload = async (file, newFileName, dirName,contentType) => {
        aws.config.region = REGION;
        aws.config.credentials = new aws.CognitoIdentityCredentials({
            IdentityPoolId: COGNITO_POOL_ID
        });
        var s3 = new aws.S3({
            params: {
                Bucket: BUCKET_NAME
            },
        });
        const formData = new FormData();
        formData.append('file', file);
        const uploadResult = async () => {
            let params = {
                Key: dirName + "/" + newFileName,
                Bucket: BUCKET_NAME,
                Body: file,
                ContentType: contentType,
                ACL: 'public-read',    
            };
                      
            if(!contentType){
                delete params.ContentType
            }
            return await s3.upload(params).promise();
        }
        return await uploadResult();
    }
}

export {
    AWSHelper
}
