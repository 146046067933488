import { generateRandomFileName } from "./GeneralHelper";
import { default_s } from "./importHelper/image";

export function validateImageDimensions(file, callback) {
    return new Promise((resolve, reject) => {
        try {
            if (!file) return reject({ isValid: false, message: `file not found` });

            const img = new Image();
            img.src = window.URL.createObjectURL(file.originFileObj);
            img.onload = function () {
                const width = img.naturalWidth, height = img.naturalHeight;
                window.URL.revokeObjectURL(img.src);
                const result = callback(height, width)
                return resolve(result)
            };

        } catch (error) {
            return reject({ isValid: false, message: error.message })
        }
    });
}

export function onImageLoadError(e) {
    e.target.onerror = null;
    e.target.src = default_s;
}

export const VALIDATE_MODE = {
    'GEQ':'GEQ',
    'EQ': 'EQ',
    'LEQ':'LEQ'
}

export const DIMENSIONS_LIST = {
    CATEGORY: {
        MIN_HEIGHT: 350,
        MIN_WIDTH: 170,
        validator: (height, width) => validateWithExpectedDimensions(height, width, DIMENSIONS_LIST.CATEGORY.MIN_HEIGHT, DIMENSIONS_LIST.CATEGORY.MIN_WIDTH)
    },
    BANK: {
        MIN_HEIGHT: 432,
        MIN_WIDTH: 432,
        validator: (height, width) => validateWithExpectedDimensions(height, width, DIMENSIONS_LIST.BANK.MIN_HEIGHT, DIMENSIONS_LIST.BANK.MIN_WIDTH),
    },
    SUB_CATEGORY: {
        MIN_HEIGHT: 432,
        MIN_WIDTH: 432,
        validator: (height, width) => validateWithExpectedDimensions(height, width, DIMENSIONS_LIST.SUB_CATEGORY.MIN_HEIGHT, DIMENSIONS_LIST.SUB_CATEGORY.MIN_WIDTH)
    },
    TOP_DEALS: {
        MIN_HEIGHT: 330,
        MIN_WIDTH: 330,
        validator: (height, width) => validateWithExpectedDimensions(height, width, DIMENSIONS_LIST.TOP_DEALS.MIN_HEIGHT, DIMENSIONS_LIST.TOP_DEALS.MIN_WIDTH)
    },
    POPULAR_STYLES: {
        MIN_HEIGHT: 360,
        MIN_WIDTH: 240,
        validator: (height, width) => validateWithExpectedDimensions(height, width, DIMENSIONS_LIST.POPULAR_STYLES.MIN_HEIGHT, DIMENSIONS_LIST.POPULAR_STYLES.MIN_WIDTH)
    },
    OFFERS: {
        MIN_HEIGHT: 330,
        MIN_WIDTH: 300,
        validator: (height, width) => {
            let result = { message: '', isValid: true };
            if (height >= DIMENSIONS_LIST.OFFERS.MIN_HEIGHT && width >= DIMENSIONS_LIST.OFFERS.MIN_WIDTH) {
                return result;
            }
            result.isValid = false;
            result.message = `Image width and height should be minimum ${DIMENSIONS_LIST.OFFERS.MIN_WIDTH}x${DIMENSIONS_LIST.OFFERS.MIN_HEIGHT} but found ${width}x${height}`;

            return result;
        }
    },
    EXPLORE_STYLES: {
        MIN_HEIGHT: 188,
        MIN_WIDTH: 188,
        validator: (height, width) => validateWithExpectedDimensions(height, width, DIMENSIONS_LIST.EXPLORE_STYLES.MIN_HEIGHT, DIMENSIONS_LIST.EXPLORE_STYLES.MIN_WIDTH)
    },
    BANNER_FOR_WEB:{
        MIN_HEIGHT: 480,
        MIN_WIDTH: 1440,
        validator:(height,width) => validateWithExpectedDimensions(height,width,DIMENSIONS_LIST.BANNER_FOR_WEB.MIN_HEIGHT,DIMENSIONS_LIST.BANNER_FOR_WEB.MIN_WIDTH,)
    },
    BANNER_FOR_MOBILE:{
        MIN_HEIGHT: 576,
        MIN_WIDTH: 1024,
        validator:(height,width) => validateWithExpectedDimensions(height,width,DIMENSIONS_LIST.BANNER_FOR_MOBILE.MIN_HEIGHT,DIMENSIONS_LIST.BANNER_FOR_MOBILE.MIN_WIDTH,)
    },
    COLOR_IMAGE:{
        MIN_HEIGHT:60,
        MIN_WIDTH:60,
        validator: (height, width) => validateWithExpectedDimensions(height, width, DIMENSIONS_LIST.COLOR_IMAGE.MIN_HEIGHT, DIMENSIONS_LIST.COLOR_IMAGE.MIN_WIDTH)

    }
}

export function validateWithExpectedDimensions(height, width, expectedHeight, expectedWidth, checkMode = VALIDATE_MODE.GEQ) {
    let result = { isValid: false, message: '' };
    switch (checkMode) {
        case VALIDATE_MODE.EQ: {
            result.isValid = height === expectedHeight && width === expectedWidth
            break;
        }
        case VALIDATE_MODE.LEQ: {
            result.isValid = height <= expectedHeight && width <= expectedWidth
            break;
        }
        default: {
            result.isValid = height >= expectedHeight && width >= expectedWidth;
            break;
        }
    }
    if (!result.isValid) {
        result.message = `Width and height should be atleast ${expectedWidth}x${expectedHeight} but found ${width}x${height}.`;
    }
    return result;
}
export const ImageNote = ({ height, width, fixedDimension }) => {
    if (fixedDimension) {
        return <p> <strong>Note :</strong> Image  must be  Width {width}px * Height {height}px.</p>
    }
      return <p> <strong>Note :</strong> Image  must be atleast Width {width}px * Height {height}px.</p> 

}
export const getImageObject = (formikItem) => (formikItem?.[0]?.originFileObj) ? formikItem?.[0]?.originFileObj : null; 

export const getImageNameFromObject = (formikItem, prefix = '') => formikItem?.[0]?.originFileObj
    ? `${generateRandomFileName(prefix)}.${formikItem?.[0]?.originFileObj.type.split("/").pop()}`
    : formikItem?.[0].name;

    