import { MODULE_SLUG } from "../config/constants";

const { makePermissionChecker } = require("./AuthHelper");


export function getSideBarItemsByRole(processedRoles,side_menu_items,itemsAvailableToAll=[]){
    // const {hasModulePermission} = makePermissionChecker(processedRoles);
    // let items = [...itemsAvailableToAll];
    // side_menu_items.forEach(item=>{
    //     if(hasModulePermission(item.module)){
    //         items.push(item)
    //     }
    // })
    // return items;
    return withPermission(processedRoles)(side_menu_items)
}

function withPermission(processedRoles) {
    const { hasModulePermission } = makePermissionChecker(processedRoles);
    function hasChildren(currentItem) {
        return typeof (currentItem?.modules) !== "undefined" && currentItem?.children?.length > 0;
    }
    function makeItem(item) {
        return {
            key: item?.key,
            label: item?.label,
            icon: item?.icon,
            title: item?.label,
            children: [],
        }
    }
    function manageItems(child, parent) {
        const item = makeItem(child);
        if (hasChildren(child)) {
            for (const subChild of child.children) {
                manageItems(subChild, item.children)
            }

        }else{
            if(hasModulePermission(child.module)|| child.module === MODULE_SLUG.OPEN_FOR_ALL ){
                delete item.children
                parent.push(item)
            }
            return
        }
        if(item.children.length > 0){
                parent.push(item)
        }
    }

    return function makeSidebar(data = []) {
        const sidebarItems = [];
        for (const item of data) {
            manageItems(item, sidebarItems);
        }
        return sidebarItems;
    }
}
