import React from 'react'
import { Card, Form, Input, Col, Row, Typography, Divider, notification, Select, Space, Button, Modal } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useParams } from 'react-router';
import BreadcrumbComponent from '../../../common/Breadcrumb.jsx';
import { LOCATIONS } from '../../../../config/routeConfig.js';
import { MODULE_TITLES } from '../../../../config/moduleConfig.js';
import ProductListTable from './ProductListTable.jsx';
import AdditionalChargeTable from './AdditionalChargeTable.jsx';
import { useManualQuotationSubOrderDetailLazyQuery, useUpdateManualQuotationTrackingOrderStatusMutation } from '../../../../graphql/generated/index.tsx';
import { ConditionalComponent } from "../../../common/GeneralComponents";
import { TRACKING_ORDER_STATUS } from '../../../../config/constants.js';
import ManageDocuments from './ManageDocuments.jsx';
import { FormikProvider, useFormik } from 'formik';

const SubOrderDetail = () => {
    const params = useParams()
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [additionalCharges, setAdditionalCharges] = React.useState([]);
    const [subOrderDetail, setSubOrderDetail] = React.useState([]);
    const [productList, setProductList] = React.useState([]);
    const [updateTrackingOrderStatus, setUpdateTrackingOrderStatus] = React.useState()
    const [updateTrackingOrderStatusMutation, { loading: updateTrackingOrderStatusLoading }] = useUpdateManualQuotationTrackingOrderStatusMutation()
    const [getManualQuotationSubOrderDetailsLazyQuery, { loading: manualQuotationSubOrderDetailLazyQueryLoading }] = useManualQuotationSubOrderDetailLazyQuery();
    const salesOrderId = subOrderDetail?.subOrderNumberId?.split("-")
    React.useEffect(() => {
        getManualQuotationSubOrderDetailsLazyQuery({
            variables: { id: params.subOrderId },
            onCompleted(data) {
                setUpdateTrackingOrderStatus(data?.manualQuotationSubOrderDetail?.trackingOrderStatus)
                setSubOrderDetail(data?.manualQuotationSubOrderDetail)
                setAdditionalCharges(data?.manualQuotationSubOrderDetail?.additionalCharges)
                setProductList(data?.manualQuotationSubOrderDetail?.products)

            },
            onError(error) {
                notification.error({ message: error.message });
            },
            fetchPolicy: "network-only",
        });
    }, []);

    const sendTrackingOrderStatus = React.useCallback((updateTrackingOrderStatusArg) => {
        let variables = {
            id: params?.subOrderId,
            trackingOrderStatus: updateTrackingOrderStatusArg && updateTrackingOrderStatusArg
        };
        updateTrackingOrderStatusMutation({
            variables: variables,
            onCompleted(data) {
                notification.success({
                    message: data.updateManualQuotationTrackingOrderStatus.message
                })
            },
            onError(error) {
                notification.error({
                    message: error.message,
                    onClose: notification.destroy()
                });
            },
        })
    }, [setUpdateTrackingOrderStatus, updateTrackingOrderStatusMutation])

    const handleTrackingUpdateChange = React.useCallback((selectedValue) => {
        setUpdateTrackingOrderStatus(selectedValue)
        sendTrackingOrderStatus(selectedValue)
    })

    const handleManageDocumentClick = () => {
        setIsModalVisible(true);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const breadcrumb_items = [
        { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
        { label: MODULE_TITLES.QUOTE_GENERATION_NAME.SALES_ORDER, to: LOCATIONS.SALES_ORDER_ROUTES.ROOT },
        {
            label: MODULE_TITLES.QUOTE_GENERATION_NAME.SALES_ORDER_DETAIL, to: LOCATIONS.SALES_ORDER_ROUTES.ORDER_DETAILS.replace(':sellerUserId', params?.sellerUserId).replace(':salesOrderId', salesOrderId?.[0]).replace(':manualQuotationOrderId', subOrderDetail?.manualQuotationOrderId).replace(':buyerUserId', subOrderDetail?.buyerDetails?._id)
        },
        { label: "Sub Order Detail" }
    ];

    let sumOfProductAmount = productList?.reduce((accumulator, item) => {
        return accumulator + (item?.quantity * item?.unitPrice);
    }, 0);

    let QuotationAdditionalAmount = additionalCharges?.reduce(
        (accumulator, item) => {
            return accumulator + item.amount;
        },
        0
    );
    const formik = useFormik({
        validateOnChange:false
    })
    return (
        <Content className='content'>
            <div className='page-header'>
                <h2 className='page-title'>{MODULE_TITLES.QUOTE_GENERATION_NAME.SALES_ORDER}</h2>
                <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
            </div>
            <div className='content-wrapper'>
                <Card title={"Sub Order Details"} loading={manualQuotationSubOrderDetailLazyQueryLoading || updateTrackingOrderStatusLoading} extra={
                    <Space size={10}>
                        <Button
                            onClick={handleManageDocumentClick}
                            type="primary" >
                            Manage Documents
                        </Button>
                    </Space>
                } >
                    <Row justify='space-between'>
                        <Row gutter={24}>
                            <Col >
                                <Typography.Title level={5}>Invoice Number Id : {subOrderDetail?.invoiceNumberId}</Typography.Title>
                            </Col>
                        </Row>
                        <Form
                            id="updateTrackingOrderStatus"
                            name="updateTrackingOrderStatus"
                            layout="inline"
                            initialValues={subOrderDetail?.trackingOrderStatus &&
                                subOrderDetail?.trackingOrderStatus.replace(/_/g, " ")}
                        >
                            <Form.Item label={<strong>
                                Update Tracking Order Status To
                            </strong>}>
                                <Select
                                    allowClear={true}
                                    style={{ width: 230 }}
                                    placeholder="Update Delivery Status"
                                    name="updateTrackingOrderStatus"
                                    defaultValue={subOrderDetail?.trackingOrderStatus && subOrderDetail?.trackingOrderStatus.replace(/_/g, " ")}
                                    value={updateTrackingOrderStatus}
                                    loading={updateTrackingOrderStatusLoading}
                                    onChange={handleTrackingUpdateChange}
                                    options={Object.values(TRACKING_ORDER_STATUS).map(val => ({ label: val.replace(/_/g, " "), value: val, }))}
                                />
                            </Form.Item>
                        </Form>
                        {/* <Form
                            id="updateTrackingOrderStatus"
                            name="updateTrackingOrderStatus"
                            layout="inline"
                            initialValues={{
                                updateTrackingOrderStatus: subOrderDetail?.trackingOrderStatus &&
                                    subOrderDetail?.trackingOrderStatus.replace(/_/g, " ")
                            }}
                        >
                            <Form.Item label={<strong>
                                Update Tracking Order Status To
                            </strong>}>
                                <Select
                                    allowClear={true}
                                    style={{ width: 230 }}
                                    placeholder="Update Delivery Status"
                                    name="updateTrackingOrderStatus"
                                    loading={updateTrackingOrderStatusLoading}
                                    onChange={handleTrackingUpdateChange}
                                    options={Object.values(TRACKING_ORDER_STATUS).map(val => ({ label: val.replace(/_/g, " "), value: val, }))}
                                    value={formik.values.updateTrackingOrderStatus}
                                />
                            </Form.Item>
                        </Form> */}


                    </Row>
                    <Row>
                        <Col sm={24} md={10}>
                            <Typography.Title level={5}>Buyer Details :</ Typography.Title>
                            <Typography>
                                {subOrderDetail?.buyerDetails?.buyerName}, <br />
                                {subOrderDetail?.buyerDetails?.businessName},<br />
                                {subOrderDetail?.buyerDetails?.fullAddress}<br />
                                {subOrderDetail?.buyerDetails?.phoneNumber}
                            </Typography>
                        </Col>
                    </Row>
                    <ProductListTable data={productList} viewOnly={true} />
                    <Col align="end" style={{ margin: "12px 15px 0px 0px" }}>
                        <strong>
                            Gross Amount : {sumOfProductAmount ? sumOfProductAmount : 0}{" "}
                            RS
                        </strong>
                    </Col>
                    <ConditionalComponent
                        shouldShow={additionalCharges.length > 0}
                    >
                        <Divider />
                        <Typography.Title level={5} strong>Additional Charges</ Typography.Title>
                        <AdditionalChargeTable data={additionalCharges} viewOnly={true} />
                        <Col align="end" style={{ margin: "12px 15px 12px 0px" }}>
                            <strong>
                                Additional Amount :{" "}
                                {QuotationAdditionalAmount && QuotationAdditionalAmount} RS
                            </strong>
                            <br />
                        </Col>
                    </ConditionalComponent>
                    <ConditionalComponent
                        shouldShow={subOrderDetail?.igstPercentage > 0}
                    >
                        <Col align="end" style={{ margin: "12px 15px 0px 0px" }}>
                            <strong>
                                {`IGST ${subOrderDetail?.igstPercentage}% : ${subOrderDetail?.igstAmount} RS`}
                            </strong>
                        </Col>
                    </ConditionalComponent>
                    <ConditionalComponent
                        shouldShow={subOrderDetail?.cgstPercentage > 0}
                    >
                        <Col align="end" style={{ margin: "12px 15px 0px 0px" }}>
                            <strong>
                                {`CGST ${subOrderDetail?.cgstPercentage}% : ${subOrderDetail?.cgstAmount} RS`}
                            </strong>
                        </Col>
                    </ConditionalComponent>
                    <ConditionalComponent
                        shouldShow={subOrderDetail?.sgstAmount > 0}
                    >
                        <Col align="end" style={{ margin: "12px 15px 0px 0px" }}>
                            <strong>
                                {`SGST ${subOrderDetail?.sgstPercentage}% : ${subOrderDetail?.sgstAmount} RS`}
                            </strong>
                        </Col>
                    </ConditionalComponent>
                    <Col align="end" style={{ margin: "12px 15px 0px 0px" }}>
                        <strong>
                            Total Amount : {subOrderDetail?.totalOrderAmount} RS
                        </strong>
                    </Col>
                    <Divider />
                          
                    <FormikProvider value={formik} >
                        <Form
                            labelAlign="left"
                            labelWrap={true}
                            name="add-edit-category-page-form"
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 10 }}
                            initialValues={{
                                termsAndConditions: subOrderDetail?.termsAndConditions || '',
                                transportName: subOrderDetail?.transportDetails?.transportName || '',
                                vehicleNumber: subOrderDetail?.transportDetails?.vehicleNumber || '',
                                eWayBillNumber: subOrderDetail?.eWayBillNumber || '',
                              }}
                        >
                            <Form.Item label="Terms & Conditions" name="termsAndConditions">
                                <Input.TextArea
                                    name="termsAndConditions"
                                    rows={6}
                                    placeholder="Terms & Conditions..."
                                    // defaultValue={subOrderDetail?.termsAndConditions ||''}
                                    disabled
                                />
                            </Form.Item>

                            <ConditionalComponent shouldShow={subOrderDetail?.transportDetails}>
                                <Typography.Title level={5} strong>Transport Detail</ Typography.Title>
                            </ConditionalComponent>

                            <ConditionalComponent shouldShow={subOrderDetail?.transportDetails?.transportName}>
                                <Form.Item label="Transport Name" name="transportName">
                                    <Input
                                        type="text"
                                        id="transportName"
                                        name="transportName"
                                        placeholder="Transport Name..."
                                        suffix={<span />}
                                        // defaultValue={subOrderDetail?.transportDetails?.transportName ||''}
                                        disabled
                                    />
                                </Form.Item>
                            </ConditionalComponent>

                            <ConditionalComponent shouldShow={subOrderDetail?.transportDetails?.vehicleNumber}>
                                <Form.Item label="Vehicle Number" name="vehicleNumber">
                                    <Input
                                        type="text"
                                        id="vehicleNumber"
                                        name="vehicleNumber"
                                        placeholder="Vehicle Number..."
                                        suffix={<span />}
                                        // defaultValue={subOrderDetail?.transportDetails?.vehicleNumber ||''}
                                        disabled
                                    />
                                </Form.Item>
                            </ConditionalComponent>

                            <ConditionalComponent shouldShow={subOrderDetail?.eWayBillNumber}>
                                <Form.Item label="E-way Bill" name="eWayBillNumber">
                                    <Input
                                        type="text"
                                        id="eWayBillNumber"
                                        name="eWayBillNumber"
                                        placeholder="E-way Bill..."
                                        suffix={<span />}
                                        // defaultValue={subOrderDetail?.eWayBillNumber ||''}
                                        disabled
                                    />
                                </Form.Item>
                            </ConditionalComponent>

                        </Form>

                    </FormikProvider>
                </Card>
            </div>
            <Modal
                title="Manage Documents"
                visible={isModalVisible}
                footer={null}
                centered
                onCancel={handleModalCancel}
            >
                {isModalVisible && (
                    <ManageDocuments subOrderId={params.subOrderId} handleModalClose={handleModalCancel} />
                )}
            </Modal>
        </Content>
    )
}

export default SubOrderDetail;
