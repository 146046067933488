import React from 'react';
import { Layout, Card, Table, notification, Space, Button, Typography, Image, Popconfirm,  Row, Col, Modal,Form as AntdForm} from 'antd';
import { Form, Input } from "formik-antd";
import {  EyeOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MODULE_TITLES } from '../../../../config/moduleConfig';
import { LOCATIONS } from '../../../../config/routeConfig';
import BreadcrumbComponent from '../../../common/Breadcrumb.jsx';
import { ADMIN_PRODUCT_APPROVAL_TYPES, API_PLATFORM_TYPE, APPROVAL_TYPES, PAGINATION, PERMISSION_SLUG } from '../../../../config/constants';
import { itemRender } from '../../../../helper/Paginationfunction';
import { useProductVerifiedByAdminMutation, useUserBulkProductsListLazyQuery } from '../../../../graphql/generated/index.tsx';
import { default_s } from '../../../../helper/importHelper/image';
import { onImageLoadError } from '../../../../helper/imageHelper';
import { UNIQUE_MESSAGES, commonValidationMessage } from '../../../../helper/MessagesHelper';
import ProductStatusSwitch from '../../../common/ProductStatusSwitch';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from "yup";
import { ConditionalComponent } from '../../../common/GeneralComponents';
import usePagination from '../../../common/hooks/usePagination';


const { Content } = Layout;
const MODAL_KEY = { WITH_OPTIONS: "withOptions", VIEW_REASON: "viewReason" };
const styles = {
    approveBtn: {
        background: "#180C44",
        color: "#ffffff",
        border: 0,
        ":hover": {
            borderColor: "#180C44",
            border: 1,
        },
    },
};

const ViewProductListFromSheet = (props) => {
    const location = useLocation();
    const sheetDetails = location.state
    const params = useParams()
    const navigate = useNavigate();
    const { handlers:{handlePageNoChange,handlePageSizeChange},values:{pageValues} } = usePagination();
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [userProductList, setUserProductList] = React.useState();
    const [selectedProducts,setSelectedProducts]=React.useState([])
    const [rejectReason, setRejectReason] = React.useState("");
    const [isModalVisible, setIsModalVisible] = React.useState({
        withOptions: false,
        viewReason: false,
    });


    /* graphql */
    const [getUserBulkProductsListLazyQuery, { loading: getuserBulkProductsListLazyQueryLoading, refetch }] = useUserBulkProductsListLazyQuery()
    const [productVerifyMutation] = useProductVerifiedByAdminMutation();
    const getBulkProductList = React.useCallback((currentPageArg, limitArg) => {
        let variables = {
            sheetId: params?.bulkSheetId,
            apiSlug: API_PLATFORM_TYPE.CMS
        };
        currentPageArg && (variables.page = currentPageArg - 1);
        limitArg && (variables.limit = limitArg)

        getUserBulkProductsListLazyQuery({
            variables: variables,
            onCompleted(data) {
                setUserProductList(data?.userProductsList?.data)
                setTotalRecords(data?.userProductsList?.totalRecords)
            },
            onError(error) {
                notification.error({
                    message: error.message,
                });
            },
            fetchPolicy: "network-only"
        })
    }, [setUserProductList, setTotalRecords, getUserBulkProductsListLazyQuery]);

    React.useEffect(() => {
        getBulkProductList(pageValues.currentPage, pageValues.pageSize);
    }, [pageValues]);
    
    const breadcrumb_items = [
        { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
        { label: MODULE_TITLES.BULK_LIST.ROOT,to:LOCATIONS.BULK_LIST_ROUTES.ROOT },
        { label: MODULE_TITLES.BULK_LIST.LIST}
    ];

    const DocumentRejectValidationSchema = Yup.object().shape({
        productIds: Yup.array().required(),
        rejectReason: Yup.string().required(
            commonValidationMessage("Reason For Rejection")
        ),
    });

    const formik = useFormik({
        initialValues: {
            productIds: [],
            rejectReason: "",
        },
        validationSchema: DocumentRejectValidationSchema,
        onSubmit(values) {
            !values.rejectReason && (values.rejectReason = null);
            verifyProduct(
                ADMIN_PRODUCT_APPROVAL_TYPES.QC_REJECTED,
                values.productIds,
                values.rejectReason
            );
        },
    });

    const handleOk = React.useCallback(() => formik.submitForm() , [formik]);

    const showModal = React.useCallback(
        (record, key) => {
            setIsModalVisible((state) => ({ ...state, [key]: true }));
            if (key === MODAL_KEY.WITH_OPTIONS) {
                // formik.setFieldValue("productIds", selectedProducts, true);
                formik.setFieldValue("productIds", selectedProducts, false);
            }
            if (key === MODAL_KEY.VIEW_REASON) {
                setRejectReason(record.productCancelReason);
            }
        },
        [selectedProducts, formik]
    );

    const closeModal = React.useCallback(
        (key) => {
            setIsModalVisible((state) => ({ ...state, [key]: false }));
            if (key === MODAL_KEY.VIEW_REASON) {
                setRejectReason("")
            } else {
                formik.resetForm()
              };
        }, []);
    const verifyProduct = React.useCallback(
        (
            approvalTypeArg,
            productIdArg,
            rejectReasonArg
        ) => {
            let variables = {
                verifiedByAdmin: approvalTypeArg,
                productIds: productIdArg,
            };
            rejectReasonArg &&
                (variables.productCancelReason = rejectReasonArg);
                productVerifyMutation({
                variables: variables,
                onCompleted(data) {
                    notification.success({
                        message: data.productVerifiedByAdmin.message,
                    });
                    formik.resetForm();
                    setSelectedProducts([]);
                    closeModal(MODAL_KEY.WITH_OPTIONS);
                    refetch();
                },
                onError(error) {
                    notification.error({
                        message: error.message,
                    });
                    closeModal(MODAL_KEY.WITH_OPTIONS);
                }
            });
        },
        [formik, closeModal, productVerifyMutation, refetch]
    );
    
    const handleBulkProductetailKey = (item) => {
        return item._id
    }

    const handleApprovelClick = () => verifyProduct(APPROVAL_TYPES.APPROVED, selectedProducts)

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedProducts(selectedRowKeys)
        },
        selectedRowKeys:selectedProducts,
        getCheckboxProps:(record,)=>({
            disabled: [ADMIN_PRODUCT_APPROVAL_TYPES.APPROVED, ADMIN_PRODUCT_APPROVAL_TYPES.QC_REJECTED].includes(record.verifiedByAdmin) 
        })
    };

    const tableColumns = [
        {
            title: "Product Image",
            dataIndex: "productImage",
            render: (_text, record) => {
                return <Image alt="Product Image" onError={onImageLoadError} src={record.productImage ? record?.productImage : default_s} style={{ maxHeight: 100, maxWidth: 100 }} />
            }
        },
        {
            title: "Listing Title",
            dataIndex: "listingTitle",
        },
        {
            title: "Category Name",
            dataIndex: "categoryName"
        },
        {
            title: "Brand Name",
            dataIndex: "brandName",
        },
        {
            title: "Business Name",
            dataIndex: ["userDetails", "businessName"]
        },
        {
            title: "Status",
            dataIndex: "productStatus",
            render: (_text, record) => {
                return (
                    <ProductStatusSwitch statusChangedBy={record?.productStatusChangedBy} id={record._id} verifiedByAdmin={record?.verifiedByAdmin} isActive={record?.productStatus} refetch={refetch}/>
                    
                );
            },
        },
        {
            title: "Actions",
            dataIndex: "_id",
            align: 'center',
            render: (_text, record) => {
                let viewIndividualBulkProductDetailURL = LOCATIONS.BULK_LIST_ROUTES.VIEWBULKSTATUS.replace(':bulkSheetId', params.bulkSheetId ).replace(':productId', record._id);
                return (
                    <Space size={10}>
                        <ConditionalComponent shouldShow={props.permission[PERMISSION_SLUG.VIEW]} >
                        <Button
                            type="link"
                            shape="circle"
                            icon={<EyeOutlined />}
                            onClick={() => navigate(viewIndividualBulkProductDetailURL, {state : location.state})}
                        />
                        </ConditionalComponent>

                    </Space>
                );
            },
        },
    ];

    const tableData = userProductList

    return (
        <Content className='content'>
            <div className='page-header'>
                <h2 className='page-title'>{MODULE_TITLES.BULK_LIST.LIST}</h2>
                <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
            </div>
            <div className='content-wrapper'>
                <Row>
                    <Col sm={24}>
                    <Card title='Product Details' extra={
                    <Space align='center'>
                        <ConditionalComponent shouldShow={props.permission[PERMISSION_SLUG.EDIT]}>
                            <OptionsForBulkAppoved showModal={showModal} disableButton={selectedProducts} onChange={handleApprovelClick}/>
                        </ConditionalComponent>
                    </Space>
                }>
                    <div>
                        <div>
                            <Typography.Title level={5}>ID : {sheetDetails?.sheetId}</Typography.Title>
                        </div>
                        <div>
                            <Typography.Title level={5}>Business Name : {sheetDetails?.businessName}</Typography.Title>
                        </div>
                    </div>
                    <Modal
                        maskClosable={false}
                        title={MODULE_TITLES.USER.REJECT_REASON}
                        visible={isModalVisible.withOptions}
                        centered
                        onOk={handleOk}
                        onCancel={() => closeModal(MODAL_KEY.WITH_OPTIONS)}
                        okButtonProps={{ loading: formik.isSubmitting }}
                        destroyOnClose={true}
                    >
                        <FormikProvider value={formik}>
                            <Form
                                id="product-reject-form"
                                name="product-reject-form"
                                layout="horizontal"
                                labelCol={{ span: 0 }}
                                labelWrap={true}
                            >

                                        <AntdForm.Item name="rejectReason" id="rejectReason"
                                            validateStatus={formik.errors['rejectReason'] && "error"}
                                            help={formik.errors['rejectReason']}>
                                            <Input.TextArea
                                                name="rejectReason"
                                                id="rejectReason"
                                                type="text"
                                                placeholder="Reason for Rejection of document..."
                                            />
                                        </AntdForm.Item>
                            </Form>
                        </FormikProvider>
                    </Modal>
                    <Modal
                        maskClosable={false}
                        title={MODULE_TITLES.USER.REJECT_REASON}
                        visible={isModalVisible.viewReason}
                        okButtonProps={{ style: { display: "none" } }}
                        cancelText="close"
                        centered
                        onCancel={() => closeModal(MODAL_KEY.VIEW_REASON)}
                    >
                        <p>{rejectReason}</p>
                    </Modal>
                    <Table
                        className="table-striped"
                        style={{ overflow: "auto" }}
                        columns={tableColumns}
                        dataSource={tableData}
                        ascend={true}
                        rowKey={handleBulkProductetailKey}
                        showSizeChanger={true}
                        loading={getuserBulkProductsListLazyQueryLoading}
                        rowSelection={{
                            preserveSelectedRowKeys: true,
                            ...rowSelection
                        }}
                        pagination={{
                            total: totalRecords,
                            pageSize: pageValues.pageSize,
                            current: pageValues.currentPage,
                            showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            onChange:handlePageNoChange,
                            showSizeChanger: true,
                            pageSizeOptions: PAGINATION.DEFAULT_SIZE_OPTIONS,
                            onShowSizeChange: handlePageSizeChange,
                            itemRender: itemRender,
                        }}
                    />
                </Card>                 
                    </Col>
                </Row>              
            </div>
        </Content>
    )
}
function OptionsForBulkAppoved({showModal, onChange,disableButton}) {

    const handleModalShow = React.useCallback(() => showModal(null, MODAL_KEY.WITH_OPTIONS), [showModal]);
    
    return (
        <>
            <Space >
                <Popconfirm
                    placement="bottomRight"
                    title={UNIQUE_MESSAGES.APPROVE_MESSAGE("Selected Bulk Product")}
                    onConfirm={onChange}
                    okText="Yes"
                    cancelText="No"
                    className='mr-8'
                    disabled={disableButton.length === 0}
                >
                    <Button style={styles.approveBtn} disabled={disableButton.length===0}>
                        Approve
                    </Button>
                </Popconfirm>
                <Popconfirm
                    placement="bottomRight"
                    title={UNIQUE_MESSAGES.REJECT_POPUP("Selected Bulk Product")}
                    onConfirm={handleModalShow}
                    okText="Yes"
                    cancelText="No"
                    disabled={disableButton.length===0}
                >
                    <Button type="primary"
                        danger disabled={disableButton.length === 0} >
                        Reject
                    </Button>
                </Popconfirm>
            </Space>
        </>
    );
}
export default ViewProductListFromSheet

