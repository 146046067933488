
import React from "react";
import { Card, Col, Divider,Row, Typography, } from "antd";
import { ListItem } from "../Product/DetailSections/common";

const PaymentDetails = ({razorPayPaymentStatus,orderNumberId,shipmentManagementType}) => {

  return (
    <>
          <Row>
              <Col sm={24} md={24}>
                  <Typography.Text strong>
                      <Divider orientation="left">Payment Details :</Divider>
                  </Typography.Text>
              </Col>
          </Row>
          <Row gutter={6} wrap>
              <Col sm={12}>
                  <Card>
                      <ListItem
                          item={{
                              label: "Payment Mode",
                              value:shipmentManagementType
                          }}
                          labelStrong={true}
                      />
                      <ListItem
                          item={{
                              label: "Payment Status",
                              value: razorPayPaymentStatus,
                          }}
                          labelStrong={true}
                      />
                      <ListItem
                          item={{
                              label: "orderNumberId",
                              value: orderNumberId,
                          }}
                          labelStrong={true}
                      />
                      <ListItem
                          item={{
                              label: "Payment ID",
                              // value:Paymentid,
                          }}
                          labelStrong={true}
                      />
                      <ListItem
                          item={{
                              label: "Payment Date",
                              // value:PaymentDate,
                          }}
                          labelStrong={true}
                      />
                  </Card>
              </Col>
          </Row>
    </>
  );
};

export default PaymentDetails;
