import React, { useEffect } from "react";
import { Button, Card, Space, Table, notification, Form, Input } from "antd";
import { Content } from "antd/lib/layout/layout";
import { EyeOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { MODULE_TITLES } from "../../../config/moduleConfig";
import BreadcrumbComponent from "../../common/Breadcrumb";
import { usePromoteYourAdvListPaginationLazyQuery } from "../../../graphql/generated/index.tsx";
import { useNavigate, useParams } from "react-router";
import { LOCATIONS } from "../../../config/routeConfig";
import { ADVERTISEMENT_TYPES, PAGINATION, PERMISSION_SLUG } from "../../../config/constants";
import { Link } from "react-router-dom";
import { usePagination } from "../../../context/PaginationContext";
import { itemRender } from "../../../helper/Paginationfunction";
import { getTableRowKey } from "../../../helper/GeneralHelper";
import { ConditionalComponent } from "../../common/GeneralComponents";


const PromoteYourAddList = (props) => {
  const {
    pageValues,
    search,
    handlePageNoChange,
    handlePageSizeChange,
    handleSearchChange,
  } = usePagination();

  const params = useParams();
  const navigate = useNavigate();
  const IS_PRODUCT_ADVERTISEMENT= props.advertisementType === ADVERTISEMENT_TYPES.PRODUCT_ADVERTISEMENT; 

  const breadcrumb_items = [
    { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
    {
      label: MODULE_TITLES.PROMOTE_ADVERTISEMENT.ROOT,
      to: LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.ROOT,
    },
    {
      label: MODULE_TITLES.PROMOTE_ADVERTISEMENT.LIST,
    },
  ];
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [promoteYourAdvList, setPromoteYourAdvList] = React.useState([]);

    const [getPromoteYourAdvListPaginationLazyQuery,{loading: promoteYourAdvListPaginationLazyQueryLoading, refetch },] = usePromoteYourAdvListPaginationLazyQuery({
      variables: {
        categoryId: params?.categoryId,
      },
      onCompleted(data) {
        setPromoteYourAdvList(data.promoteYourAdvListPagination);
      },
      onError(error) {
        notification.error({ message: error.message });
        navigate(LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.ROOT);
      },
      fetchPolicy: "network-only",
    });
  


  const getCategoryTypeList = React.useCallback(
    (searchArg, currentPageArg, limitArg) => {
      let variables = {
        categoryId: params?.categoryId,
        filterBy: IS_PRODUCT_ADVERTISEMENT ? ADVERTISEMENT_TYPES.PRODUCT_ADVERTISEMENT : ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT
      };

      searchArg && (variables.search = searchArg);
      currentPageArg && (variables.page = currentPageArg - 1);
      limitArg && (variables.limit = limitArg);

      getPromoteYourAdvListPaginationLazyQuery({
        variables: variables,
        onCompleted(data) {
          setPromoteYourAdvList(data?.promoteYourAdvListPagination.data);
          setTotalRecords(data?.promoteYourAdvListPagination.totalRecords);
        },
        onError(error) {
          notification.error({
            message: error.message,
          });
        },
        fetchPolicy: "network-only",
      });
    },
    [getPromoteYourAdvListPaginationLazyQuery, IS_PRODUCT_ADVERTISEMENT, params]
  );

  React.useEffect(() => {
    getCategoryTypeList(search, pageValues.currentPage, pageValues.pageSize);
  }, [pageValues]);

  const tableColumns = [
    {
      title: "Advertisement Section Type ",
      dataIndex: "sectionType",
      render: (_text, record) => {
          return record.sectionType.replace(/_/g, " ")
      }
    },
    // {
    //   title: "Advertisement Type",
    //   dataIndex: "advertisementType",
    //   render: (_text, record) => {
    //     if(advType===record.advertisementType){
    //       return record.advertisementType.replace(/_/g, " ")
    //     }
    //   },
    // },
    {
      title: "Price",
      dataIndex: "price", 
    },
    
    {
      title: "Total Advertisement Counts",
      dataIndex: "totalAdvCounts",
      render: (_text, record) => {
        return record?.totalAdvCounts ? record?.totalAdvCounts : "-";
      },
      align: "center",
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (_text, record) => {
        let editUrl = LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.EDIT[props.advertisementType ?? ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT].replace(
          ":categoryId",
          params.categoryId
        ).replace(":promoteaddId", record._id);
        let viewUrl = LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.VIEW[props.advertisementType ?? ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT].replace(
          ":categoryId",
          params.categoryId
        ).replace(":promoteaddId", record._id);
        return (
          <Space size={10}>
            <ConditionalComponent shouldShow={props.permission[PERMISSION_SLUG.VIEW]}>
              <Button
                type="link"
                shape="circle"
                icon={<EyeOutlined />}
                loading={false}
                onClick={() => navigate(viewUrl)}
              />
            </ConditionalComponent>
            <ConditionalComponent shouldShow={props.permission[PERMISSION_SLUG.EDIT]}>
              <Button
                type="link"
                shape="circle"
                icon={<EditOutlined />}
                loading={false}
                onClick={() => navigate(editUrl)}
              />
            </ConditionalComponent>

          </Space>
        );
      },
    },
  ]
 
  // const filteredAdvList = promoteYourAdvList.filter(item => item.advertisementType === advType);
  const tableData=promoteYourAdvList


 
  return (
    <Content className="content">
      <div className="page-header">
        <h2 className="page-title">
          {MODULE_TITLES.PROMOTE_ADVERTISEMENT.ROOT}
        </h2>
        <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
      </div>
      <div className="content-wrapper">
        <Card
          title={MODULE_TITLES.PROMOTE_ADVERTISEMENT.LIST}
          extra={
            <Space>
              <Link to={LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.ADD[props.advertisementType ?? ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT].replace(":categoryId",params.categoryId)}>
                <ConditionalComponent shouldShow={props.permission[PERMISSION_SLUG.ADD]}>
                  <Button disabled={tableData.length === 4 }>Add Advertisement Plan</Button>
                </ConditionalComponent>
              </Link>
            </Space>
          }
        >
          <Form
            name="horizontal_login"
            className="justify-content-end mb-20"
            layout="inline"
            labelWrap={true}
          >
            <Form.Item name="search">
              <Input
                prefix={<SearchOutlined className="site-form-item-icon" />}
                type="text"
                placeholder="Search"
                allowClear={true}
                onChange={(e) => handleSearchChange(e.target.value)}
              />
            </Form.Item>
          </Form>
          <Table
            className="table-striped"
            style={{ overflow: "auto" }}
            columns={tableColumns}
            dataSource={tableData}
            ascend={true}
            rowKey={getTableRowKey}
            showSizeChanger={true}
            loading={promoteYourAdvListPaginationLazyQueryLoading}
            pagination={{
              total: totalRecords,
              pageSize: pageValues.pageSize,
              current: pageValues.currentPage,
              showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              onChange: handlePageNoChange,
              showSizeChanger: true,
              pageSizeOptions: PAGINATION.DEFAULT_SIZE_OPTIONS,
              onShowSizeChange: handlePageSizeChange,
              itemRender: itemRender,
            }}
          />
        </Card>
      </div>
    </Content>
  );
};

export default PromoteYourAddList;
