import React, { useCallback } from 'react';
import { Layout, Table, Space, Button, Image, Form, Select, Popconfirm, Switch } from 'antd';
import { getTableRowKey } from '../../../../helper/GeneralHelper';
import { ADVERTISEMENT_STATUS, ADVERTISEMENT_TYPES, PAGINATION, PERMISSION_SLUG, TOGGLE } from '../../../../config/constants';
import { EyeOutlined,DeleteOutlined } from '@ant-design/icons';
import { itemRender } from '../../../../helper/Paginationfunction';
import { ConditionalComponent } from '../../../common/GeneralComponents';
import { isValidDate } from '../../../../helper/DateFormatter';
import { UNIQUE_MESSAGES } from '../../../../helper/MessagesHelper';
const { Content } = Layout;

const ADVERTISEMENT_STATUS_OPTIONS = [
    { label: ADVERTISEMENT_STATUS.ACTIVE, value: ADVERTISEMENT_STATUS.ACTIVE },
    { label: ADVERTISEMENT_STATUS.APPROVED, value: ADVERTISEMENT_STATUS.APPROVED },
    { label: ADVERTISEMENT_STATUS.REJECTED, value: ADVERTISEMENT_STATUS.REJECTED, },
    { label: ADVERTISEMENT_STATUS.PENDING, value: ADVERTISEMENT_STATUS.PENDING },
    { label: ADVERTISEMENT_STATUS.EXPIRED, value: ADVERTISEMENT_STATUS.EXPIRED },
]

const SellerAdvStatusPanel = ({ onSellerAdvView, ...props }) => {
    const { handlers, values } = props;
    const {
        handlePageNoChange,
        handlePageSizeChange,
        handleFilterChange,
    } = handlers;
    const { pageValues, filters } = values
    const options = { year: 'numeric', month: 'short', day: 'numeric' };

    //  Handle Toggle (switch) Mutation Will Call here

    // const sellerBannerUpdate = React.useCallback((values) => categoryUpdateMutation({
    //     variables: values,
    //     onCompleted(data){
    //         notification.success({
    //             message: data.sellerBannerUpdate.message,
    //         });
    //         refetch()
    //     },
    //     onError(error) {
    //         notification.error({
    //             message: error.message,
    //         });
    //     },
    // }), [ categoryUpdateMutation, refetch]);

    //   const handleActiveChange = React.useCallback((checked, e, record) => {
    //     props.permission[PERMISSION_SLUG.EDIT] && sellerBannerUpdate({
    //         _id: record._id,
    //         isActive: checked ? TOGGLE.ACTIVE : TOGGLE.INACTIVE,
    //     })
    // },[props.permission, sellerBannerUpdate])




    //  Delete Mutation Will Call here


       const deleteSellerBannerAdv = useCallback((id) => {
    // deleteAdminMutation => herecalldeleteMutation(
    //     {
    //         variables: { _id: id },
    //         onCompleted(data) {
    //             notification.success({
    //                 message: data.deleteAdmin.message,
    //             });
    //         },
    //         onError(error) {
    //             notification.error({
    //                 message: error.message,
    //             });
    //         },
    //         async update() {
    //             refetch()
    //         }

    //     }
    // )
    }, []) 
    // => will add herecalldeleteMutation dependency

    const tableBannerColumns = [
        {
            title: "Banner Title",
            dataIndex: "bannerTitle",
        },
        {
            title: "Section Type",
            dataIndex: "sectionTypeName",
            ellipsis: true
        },
        {
            title: "Adv Status",
            dataIndex: "advertisementStatus",
            align:"center",
        },
        // {
        //     title: "Seller Banner Status",
        //     dataIndex: "isActive",
        //     align: "center",
        //     render: (_text, record) => {
        //       return <Switch disabled={!props.permission[PERMISSION_SLUG.EDIT]}
        //         //  onChange={(checked, e) => handleActiveChange(checked, e, record)} 
        //         checkedChildren={TOGGLE.ACTIVE} checked={record.isActive === TOGGLE.ACTIVE} unCheckedChildren={TOGGLE.INACTIVE} defaultChecked style={{ width: 'fit-content' }} />
        //     }
        //   },
        {
            title: "Payment Status",
            dataIndex: "jusPayPaymentStatus",
            render: (_text, record) => {
                return record?.jusPayPaymentStatus?.toUpperCase()
            },
            align:"center",
        },
        {
            title: "Seller Name",
            dataIndex: "sellerBusinessName",
            ellipsis: true
        },
        {
            title: "Start Date",
            render: (_text, record) => {
                return isValidDate(new Date(record?.startDate)) ? new Date(record?.startDate).toLocaleDateString('en-US', options) : "-"
            }
        },
        {
            title: "End Date",
            render: (_text, record) => {
                return isValidDate(new Date(record?.endDate)) ? new Date(record?.endDate).toLocaleDateString('en-US', options) : "-"
            }
        },
        {
            title: "Actions",
            dataIndex: "actions",
            render: (_text, record) => {
                return (
                    <Space size={10}>
                        <ConditionalComponent shouldShow={props.permission[PERMISSION_SLUG.VIEW]}>
                            <Button
                                type="link"
                                shape="circle"
                                icon={<EyeOutlined />}
                                loading={false}
                                onClick={() => onSellerAdvView(record)}
                            />
                        </ConditionalComponent>
                        {/* <ConditionalComponent shouldShow={props.permission[PERMISSION_SLUG.DELETE]}>
                            <Popconfirm
                                placement="bottomRight"
                                title={UNIQUE_MESSAGES.DELETE_POPUP("Adv.")}
                                onConfirm={deleteSellerBannerAdv(record._id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="link" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </ConditionalComponent> */}
                    </Space>
                )
            }
        }
    ];
    const tableProductColumns = [
        {
            title: "Product Name",
            dataIndex: "productName",
            render: (_text, record) => {
                return record?.productName ? record?.productName : "-"
            },
        },
        {
            title: "Section Type",
            dataIndex: "sectionTypeName",
            ellipsis: true
        },
        {
            title: "Adv Status",
            dataIndex: "advertisementStatus",
        },
        // {
        //     title: "Seller Banner Status",
        //     dataIndex: "isActive",
        //     align: "center",
        //     render: (_text, record) => {
        //       return <Switch disabled={!props.permission[PERMISSION_SLUG.EDIT]}
        //         //  onChange={(checked, e) => handleActiveChange(checked, e, record)} 
        //         checkedChildren={TOGGLE.ACTIVE} checked={record.isActive === TOGGLE.ACTIVE} unCheckedChildren={TOGGLE.INACTIVE} defaultChecked style={{ width: 'fit-content' }} />
        //     }
        //   },
        {
            title: "Payment Status",
            dataIndex: "jusPayPaymentStatus",
            align:"center",
            render: (_text, record) => {
                return record?.jusPayPaymentStatus.toUpperCase()
            }
        },
        {
            title: "Seller Name",
            dataIndex: "sellerBusinessName",
            ellipsis: true
        },
        {
            title: "Start Date",
            render: (_text, record) => {
                return isValidDate(new Date(record?.startDate)) ? new Date(record?.startDate).toLocaleDateString('en-US', options) : "-"
            }
        },
        {
            title: "End Date",
            render: (_text, record) => {
                return isValidDate(new Date(record?.endDate)) ? new Date(record?.endDate).toLocaleDateString('en-US', options) : "-"
            }
        },
        {
            title: "Actions",
            dataIndex: "actions",
            render: (_text, record) => {
                return (
                    <Space size={10}>
                        <ConditionalComponent shouldShow={props.permission[PERMISSION_SLUG.VIEW]}>
                            <Button
                                type="link"
                                shape="circle"
                                icon={<EyeOutlined />}
                                loading={false}
                                onClick={() => onSellerAdvView(record)}
                            />
                        </ConditionalComponent>
                        {/* <ConditionalComponent shouldShow={props.permission[PERMISSION_SLUG.DELETE]}>
                            <Popconfirm
                                placement="bottomRight"
                                title={UNIQUE_MESSAGES.DELETE_POPUP("Adv.")}
                                onConfirm={deleteSellerBannerAdv(record._id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="link" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </ConditionalComponent> */}

                    </Space>
                )
            }
        }
    ];

    const tableData = props.listData.data; //dataSource
    const dataRowLength = tableData.length

    return (<Content className='content'>
        <div className='content-wrapper'>
            <Form name="seller-adv-status-list" className="justify-content-end mb-20" layout="inline" labelWrap={true}
                initialValues={{ advertisementStatus: filters.advertisementStatus }}
            >
                <Form.Item name="advertisementStatus" label="Advertisement Status">
                    <Select
                        allowClear={true}
                        style={{ minWidth: 200 }}
                        placeholder="Select Status Type"
                        name="advertisementStatus"
                        value={filters.advertisementStatus}
                        onChange={(value) => handleFilterChange('advertisementStatus', value)}
                        options={ADVERTISEMENT_STATUS_OPTIONS}
                    />
                </Form.Item>
            </Form>
            <Table
                className="table-striped"
                style={{ overflow: "auto" }}
                columns={props.advType === ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT ? tableBannerColumns : tableProductColumns}
                dataSource={tableData}
                ascend={true}
                rowKey={getTableRowKey}
                showSizeChanger={true}
                loading={props.loading}
                scroll={ dataRowLength > 5 && {y: 500 } }
                pagination={{
                    total: props.listData.totalRecords,
                    pageSize: pageValues.pageSize,
                    current: pageValues.currentPage,
                    showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    onChange: handlePageNoChange,
                    showSizeChanger: true,
                    pageSizeOptions: PAGINATION.DEFAULT_SIZE_OPTIONS,
                    onShowSizeChange: handlePageSizeChange,
                    itemRender: itemRender,
                }}
            />
        </div>
    </Content>
    )
}

export default SellerAdvStatusPanel

