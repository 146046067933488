import React from "react";
import { Form, Input } from "formik-antd";
import { Radio, Space } from "antd";
import { MANUAL_ORDER_CREATION_PAYMENT_TERMS } from "../../../../config/constants";
import { ConditionalComponent } from "../../../common/GeneralComponents";

const PaymentTerms = ({formik,paymentTermRadioValue,handleRadioChange}) => {
    return (
      <Form.Item label="Payment Terms" name="paymentTerms" required>
        <Radio.Group
          name="paymentTermRadioGroup"
          value={paymentTermRadioValue}
          onChange={handleRadioChange}
        >
          <Space direction="vertical">
            <Radio value={"isAdvancePayment"}>
              {MANUAL_ORDER_CREATION_PAYMENT_TERMS["100%_ADVANCE"].replace(
                "_",
                " "
              )}
            </Radio>
            <Radio value={"isPaymentAgainstDelivery"}>
              {MANUAL_ORDER_CREATION_PAYMENT_TERMS[
                "100%_PAYMENT_AGAINST_DELIVERY_(COD)"
              ].replace(/_/g, " ")}
            </Radio>
            <Radio value={"isCreditPayment"}>
              {MANUAL_ORDER_CREATION_PAYMENT_TERMS.PAYMENT_ON_CREDIT.replace(
                /_/g,
                " "
              )}
              <ConditionalComponent
                shouldShow={paymentTermRadioValue === "isCreditPayment"}
              >
                <Form.Item
                  label="Credit Limit In Days (Max. 90 Days)"
                  name="creditLimitDays"
                  labelCol={{ span: 8 }}
                >
                  <Input
                    type="number"
                    id="creditLimitDays"
                    name="creditLimitDays"
                    placeholder="Credit Limit In Days..."
                    suffix={<span />}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    max={90}
                    min={1}
                    style={{ width: "200px" }}
                  />
                </Form.Item>
              </ConditionalComponent>
            </Radio>
            <Radio value={"isSplitPayment"}>
              {MANUAL_ORDER_CREATION_PAYMENT_TERMS.SPLIT_PAYMENT.replace(
                /_/g,
                " "
              )}
              <ConditionalComponent
                shouldShow={paymentTermRadioValue === "isSplitPayment"}
              >
                <Form.Item
                  label="On Arrival (%)"
                  name="onArrivalPaymentPercentage"
                  labelCol={{ span: 8 }}
                >
                  <Input
                    type="number"
                    id="onArrivalPaymentPercentage"
                    name="onArrivalPaymentPercentage"
                    placeholder="Payment On Arrival..."
                    suffix={<span />}
                    max={100}
                    min={1}
                    style={{ width: "200px" }} // Set the width using style property
                  />
                </Form.Item>
                <Form.Item
                  label="On Delivery (%)"
                  name="onDeliveryPaymentPercentage"
                  labelCol={{ span: 8 }}
                >
                  <Input
                    type="number"
                    id="onDeliveryPaymentPercentage"
                    name="onDeliveryPaymentPercentage"
                    placeholder="Payment On Delivery..."
                    suffix={<span />}
                    max={100}
                    min={1}
                    style={{ width: "200px" }} // Set the width using style property
                  />
                </Form.Item>
              </ConditionalComponent>
            </Radio>
          </Space>
        </Radio.Group>
        {formik?.errors?.selectedRadioBtn && (
          <div style={{ color: "#ff4d4f" }}>
            {formik.errors.selectedRadioBtn}
          </div>
        )}
      </Form.Item>
    );
};

export default PaymentTerms;
