
import { Card, Col, Divider, Row } from 'antd';
import { ListItem } from '../Product/DetailSections/common';



const  BusinessDetails = ({buyerUserDetails,sellerUserDetails}) => {

    return (
      <Row gutter={10} style={{wordWrap:'break-word'}}>
        <Col span={12}>
          <Divider orientation='left' >Buyer Details</Divider>
          <Card>
            <ListItem
              item={{
                label: "Name",
                value:buyerUserDetails?.name
              }}
              labelStrong={true}
            />
            <ListItem
              item={{
                label: "Business Name",
                value:buyerUserDetails?.businessName
              }}
              labelStrong={true}
            />
            <ListItem
              item={{
                label: "Email",
                value:buyerUserDetails?.email
              }}
              labelStrong={true}
            />
            <ListItem
              item={{
                label: "Mobile",
                value:buyerUserDetails?.phoneNumber
              }}
              labelStrong={true}
            />
            <ListItem
              item={{
                label: "Address",
                value:buyerUserDetails?.fullAddress,
              }}
              labelStrong={true}
            />

          
          </Card>
        </Col>
        <Col span={12}>
          <Divider orientation='left' >Seller Details</Divider>
          <Card>
            <ListItem
              item={{
                label: "Name",
                value:sellerUserDetails?.name
              }}
              labelStrong={true}
            />
            <ListItem
              item={{
                label: "Business Name",
                value:sellerUserDetails?.businessName

              }}
              labelStrong={true}
            />
            <ListItem
              item={{
                label: "Email",
                value:sellerUserDetails?.email
              }}
              labelStrong={true}
            />
            <ListItem
              item={{
                label: "Mobile",
                value:sellerUserDetails?.phoneNumber
              }}
              labelStrong={true}
            />
            <ListItem
              item={{
                label: "Address",
                value:sellerUserDetails?.fullAddress
              }}
              labelStrong={true}
            />          
          </Card>
        </Col>
      </Row>
    );
}

export default BusinessDetails
