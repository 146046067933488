import { Col, Form, Input, Typography } from "antd";

export function ReadOnlyFormField({ value, label, children = null, colProps = {} }) {
    const view = !children
        ? <Input value={value} disabled={true} />
        : children;

    if (!children && !value) {
        return null
    }


    return <Col span={12} {...colProps}>
        <Form.Item label={<Typography.Text strong>{label}</Typography.Text>} >
            {view}
        </Form.Item>
    </Col>
}

export function ConditionalComponent({ shouldShow, children }) {
    if (!shouldShow) {
        return null;
    }
    return children
}


