import React from "react";
import * as Yup from "yup";
import { Content } from "antd/lib/layout/layout";
import { MODULE_TITLES } from "../../../config/moduleConfig";
import { LOCATIONS } from "../../../config/routeConfig";
import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Select,
  Form as AntForm,
  notification,
} from "antd";
import { Form, Input, SubmitButton } from "formik-antd";
import { FormikProvider, useFormik } from "formik";
import BreadcrumbComponent from "../../common/Breadcrumb";
import {
  commonValidationMessage,
  UNIQUE_MESSAGES,
} from "../../../helper/MessagesHelper";
import { ADVERTISEMENT_TYPES, OPERATIONS } from "../../../config/constants";
import { useNavigate, useParams } from "react-router";
import {
  useUpdatePromoteYourAdvMutation,
  useAddPromoteYourAdvMutation,
  usePromoteYourAdvDetailsLazyQuery,
} from "../../../graphql/generated/index.tsx";
import {
  BulkPriceSectionSchema,
  BulkPriceSchema,
  notlessThanBulkPrice,
  sectionTypeWhenValidation,
  BANNER_PROMOTION_TYPES,
  PRODUCT_PROMOTION_TYPES,
  handleOnChange,
} from "./helpers/validations";
import {
  generatePayload,
  onDetailsLoadCompleted,
} from "./helpers/formikHelper";

const PromotionValidationSchema = Yup.object().shape({
  advertisementType: Yup.string().required(),
  // advertisementType: Yup.string()
  //   .oneOf(
  //     ADVERTISEMENT_TYPES.map((v) => v.value),
  //     "Select Proper Product Advertisement Type"
  //   )
  //   .required(commonValidationMessage("Advertisement Type")),
  sectionType: Yup.string()
    .when("advertisementType", sectionTypeWhenValidation)
    .required(commonValidationMessage("Banner Promotion Type")),
  uniqueImpressions: Yup.number().required(
    commonValidationMessage("Minimum Unique Impression")
  ),
  // sectionTypeName: Yup.string().required(commonValidationMessage('Subscription Name')),
  perDayPrice: Yup.number()
    .required(commonValidationMessage("Single Day Price"))
    .test(
      "perDayPriceCheck",
      "Per Day Price must be more than Bulk Price",
      notlessThanBulkPrice
    ),
  bulkPrice: Yup.array().of(BulkPriceSchema),
});

const AddEditPromoteYourAdd = (props) => {
  const { operationType, advertisementType } = props;
  const IS_PRODUCT_ADVERTISEMENT =
    advertisementType === ADVERTISEMENT_TYPES.PRODUCT_ADVERTISEMENT;
  const isEditOperation = operationType === OPERATIONS.EDIT;
  const params = useParams();
  const navigate = useNavigate();
  const [currentAdvertisementType] = React.useState(
    advertisementType ?? ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT
  );
  const [bannerOptions] = React.useState(() =>
    IS_PRODUCT_ADVERTISEMENT ? PRODUCT_PROMOTION_TYPES : BANNER_PROMOTION_TYPES
  );

  /*GraphQl  */
  const [updatePromoteYourAdvMutation] = useUpdatePromoteYourAdvMutation();
  const [addPromoteYourAdvMutation] = useAddPromoteYourAdvMutation();
  const [getPromoteYourAdvDetailsLazyQuery] =
    usePromoteYourAdvDetailsLazyQuery();

  const breadcrumb_items = [
    { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
    {
      label: MODULE_TITLES.PROMOTE_ADVERTISEMENT.ROOT,
      to: LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.ROOT,
    },
    {
      label: MODULE_TITLES.PROMOTE_ADVERTISEMENT.LIST,
      to: LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.LIST[
        advertisementType ?? ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT
      ].replace(":categoryId", params?.categoryId),
    },
    {
      label: isEditOperation
        ? MODULE_TITLES.PROMOTE_ADVERTISEMENT.EDIT
        : MODULE_TITLES.PROMOTE_ADVERTISEMENT.ADD,
    },
  ];

  const addInputField = () => {
    const data = { price: undefined, days: undefined };
    formik.setFieldValue("bulkPrice", [...formik.values.bulkPrice, data], true);
  };

  const removeInputFields = (index) => {
    // if (formik.values.bulkPrice.length > 1) {
      const values = [...formik.values.bulkPrice];
      values.splice(index, 1);
      formik.setFieldValue("bulkPrice", values, true);
    // }
  };

  const formik = useFormik({
    initialValues: {
      categoryId: params?.categoryId,
      advertisementType: currentAdvertisementType,
      sectionType: "",
      uniqueImpressions: "",
      bulkPrice: [
        {
          price: undefined,
          days: undefined,
        },
      ],
      perDayPrice: "",
    },
    validationSchema: PromotionValidationSchema,
    onSubmit: (values, actions) => {
      performOperation(isEditOperation, {
        variables: {
          input: generatePayload(values),
        },
        onCompleted(data) {
          actions.setSubmitting(false);
          notification.success({
            message: isEditOperation
              ? data.updatePromoteYourAdv.message
              : data.addPromoteYourAdv.message,
          });
          navigate(
            LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.LIST[
              props.advertisementType ??
                ADVERTISEMENT_TYPES.BANNER_ADVERTISEMENT
            ].replace(":categoryId", params.categoryId)
          );
        },
        onError(error) {
          notification.error({ message: error.message });
          actions.setSubmitting(false);
        },
      }).catch((error) => {
        actions.setSubmitting(false);
        notification.error({ message: error.message });
      });
    },
  });

  React.useEffect(() => {
    if (isEditOperation) {
      getPromoteYourAdvDetailsLazyQuery({
        variables: {
          id: params?.promoteaddId,
        },
        onCompleted: (data) => onDetailsLoadCompleted.call(formik, data),
        onError(error) {
          notification.error({ message: error.message });
          navigate(LOCATIONS.PROMOTE_ADVERTISEMENT_ROUTES.ROOT);
        },
        fetchPolicy: "network-only",
      });
    }
  }, []);

  function performOperation(isEdit, options) {
    isEdit
      ? updatePromoteYourAdvMutation(options)
      : addPromoteYourAdvMutation(options);
  }

  const handleAdvertisementTypeChange = (value) => {
    formik.setFieldValue("advertisementType", value);
  };

  const handlebannerPromotionTypeChange = (value) => {
    formik.setFieldValue("sectionType", value);
  };
  return (
    <Content className="content">
      <div className="page-header">
        <h2 className="page-title">
          {" "}
          {MODULE_TITLES.PROMOTE_ADVERTISEMENT.ROOT}{" "}
        </h2>
        <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
      </div>
      <div className="content-wrapper">
        <Card title="Advertisement Plans">
          <FormikProvider value={formik}>
            <Form
              labelAlign="left"
              labelWrap={true}
              name="add-edit-category-page-form"
              layout="ver"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 10 }}
            >
              <Form.Item
                label="Advertisement Type"
                name="advertisementType"
                required
              >
                {/* <Select
                  placeholder="Advertisement Type"
                  name="advertisementType"
                  value={formik.values.advertising || undefined}
                  options={ADVERTISEMENT_TYPES}
                  style={{ minWidth: "300px" }}
                  onChange={handleAdvertisementTypeChange}
                  disabled
                /> */}
                <Input
                  type="text"
                  id="advertisementType"
                  name="advertisementType"
                  prefix={<span />}
                  value={currentAdvertisementType.replace(/_/g, " ")}
                  disabled
                  onChange={handleAdvertisementTypeChange}
                />
              </Form.Item>

              <Form.Item
                label="Banner Promotion Type"
                name="sectionType"
                required
              >
                <Select
                  placeholder="Select Banner Promotion Type"
                  name="sectionType"
                  value={formik.values.sectionType || undefined}
                  options={bannerOptions}
                  style={{ minWidth: "300px" }}
                  onChange={handlebannerPromotionTypeChange}
                />
              </Form.Item>

              <Form.Item
                label="Single Day Price"
                name="perDayPrice"
                id="perDayPrice"
                required
              >
                <Input
                  type="number"
                  min={1}
                  id="perDayPrice"
                  name="perDayPrice"
                  placeholder="Single Day Price"
                  prefix={<span />}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "perDayPrice",
                      e.target.value.slice(0, 6)
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                label="Minimum Unique Impression"
                name="uniqueImpressions"
                required
              >
                <Input
                  type="number"
                  min={1}
                  id="uniqueImpressions"
                  name="uniqueImpressions"
                  placeholder="Minimun UI"
                  prefix={<span />}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "uniqueImpressions",
                      e.target.value.slice(0, 7)
                    )
                  }
                />
              </Form.Item>

              {formik.values.bulkPrice.length>0 && 
              <AntForm.Item layout="vertical" label="Bulk price">
                {formik.values.bulkPrice.map((data, index) => {
                  const { price, days } = data;
                  return (
                    <Row
                      justify="space-between"
                      align="middle"
                      key={index}
                      gutter={[24, 16]}
                    >
                      <Col span={8}>
                        <Form.Item
                          label="Bulk Price"
                          name={`bulkPrice.${index}.price`}
                        >
                          <Input
                            type="number"
                            min={1}
                            onChange={(e) =>
                              handleOnChange.call(formik, e, true)
                            }
                            value={price}
                            name={`bulkPrice.${index}.price`}
                            placeholder="Bulk Price"
                            suffix={<span />}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item
                          label="Number of Days"
                          name={`bulkPrice.${index}.days`}
                          id={`bulkPrice.${index}.days`}
                        >
                          <Input
                            type="number"
                            min={1}
                            onChange={(e) =>
                              handleOnChange.call(formik, e, true)
                            }
                            value={days}
                            name={`bulkPrice.${index}.days`}
                            placeholder="Number of days"
                            suffix={<span />}
                          />
                        </Form.Item>
                      </Col>

                      <Col className="text-center">
                        <Popconfirm
                          placement="rightBottom"
                          title={UNIQUE_MESSAGES.DELETE_POPUP("Input")}
                          onConfirm={() => removeInputFields(index)}
                          okText="Yes"
                          cancelText="No"
                          // disabled={index === 0}
                        >
                          <Button
                            // disabled={index === 0}
                            type="ghost"
                            style={{ marginTop: "5px" }}
                          >
                            Remove
                          </Button>
                        </Popconfirm>
                      </Col>
                    </Row>
                  );
                })}
                <Row justify="end">
                  <Col className="">
                    <Button
                      disabled={
                        (formik.errors?.bulkPrice &&
                          formik.errors.bulkPrice.length > 0) ||
                        formik.values.bulkPrice.every((i) =>
                          BulkPriceSectionSchema.isValidSync(i)
                        ) === false
                      }
                      onClick={addInputField}
                      type="primary"
                    >
                      Add New Bulk Price
                    </Button>
                  </Col>
                </Row>
              </AntForm.Item>}

              <Col sm={24} md={12} className="text-right">
                <SubmitButton disabled={formik.isSubmitting}>
                  {MODULE_TITLES.COMMON.SAVE}
                </SubmitButton>
              </Col>
            </Form>
          </FormikProvider>
        </Card>
      </div>
    </Content>
  );
};
export default AddEditPromoteYourAdd;
