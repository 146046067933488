import React from 'react'
import { Button, Card, Space, Table, message, notification } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import moment from 'moment';
import { useManualQuotationPaymentHistoryListLazyQuery } from '../../../../graphql/generated/index.tsx';
import { itemRender } from '../../../../helper/Paginationfunction.js';
import { getTableRowKey } from '../../../../helper/GeneralHelper.js';
import { PAGINATION } from '../../../../config/constants.js';
import { usePagination } from '../../../../context/PaginationContext.jsx';
import { CopyOutlined } from '@ant-design/icons';

const QuotationPaymentHistory = ({ manualQuotationId ,tabState,receivedOrderAmountData}) => {
    const { pageValues, handlePageNoChange, handlePageSizeChange } = usePagination();
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [paymentHistoryList, setPaymentHistoryList] = React.useState([]);
    const [paymentLink, setPaymentLink] = React.useState("");

    const [getManualQuotationPaymentHistoryList, { loading: manualQuotationPaymentHistoryListLoading,refetch }] = useManualQuotationPaymentHistoryListLazyQuery()
    const getPaymentHistoryList = React.useCallback((currentPageArg, limitArg) => {
        let variables = { manualQuotationOrderId: manualQuotationId && manualQuotationId };
        currentPageArg && (variables.page = currentPageArg - 1);
        limitArg && (variables.limit = limitArg)

        getManualQuotationPaymentHistoryList({
            variables: variables,
            onCompleted(data) {
                setPaymentHistoryList(data?.manualQuotationPaymentHistoryList.data)
                setTotalRecords(data?.manualQuotationPaymentHistoryList.totalRecords)
            },
            onError(error) {
                notification.error({
                    message: error.message,
                });
            },
            fetchPolicy: "network-only"
        })
    }, [setPaymentHistoryList, setTotalRecords, getManualQuotationPaymentHistoryList,]);

    React.useEffect(() => {
        getPaymentHistoryList(pageValues.currentPage, pageValues.pageSize);
    }, [tabState,pageValues,receivedOrderAmountData]);

    const copyPaymentLink = (record) => {
        const linkToCopy = record?.paymentLink || '';
        setPaymentLink(linkToCopy);
        if (linkToCopy) {
          navigator.clipboard.writeText(linkToCopy).then(() => {
            notification.success({
              message: "Payment Link copied to clipboard",
              duration: 1
            });
          }).catch(() => {
            message.error('Failed to copy link');
          });
        }
      };

    const tableColumns = [
        {
            title: "S.No.",
            key: "sno",
            render: (a, v, index) => index + 1,
            width: '8%',
        },
        {
            title: "Payment Mode",
            dataIndex: "paymentMode",
            render: (_text, record) => record?.paymentMode?.replace(/_/g, " ") || "-",
            align: 'center'
        },
        {
            title: "Reference Number",
            dataIndex: "paymentReferenceNumber",
            render: (_text, record) => {
                if (record?.paymentReferenceNumber) {
                    return record?.paymentReferenceNumber
                } else if (record?.paymentLink) {
                    return record?.paymentLink
                } else return "-"
            },
            width: '18%',
            ellipsis: true,
            align: 'center'

        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (_text, record) => `${record?.amount} Rs/-` || "-",
            align: 'center'
        },
        {
            title: "Date",
            dataIndex: "date",
            render: (_text, record) => {
                function formateDate(date) {
                    let formatedDate = moment.unix(date / 1000).format('Do MMM YYYY');
                    return formatedDate
                }
                return record?.paymentDate && formateDate(record?.paymentDate)
            },
            align: 'center'
        },
        {
            title: "Status",
            align: 'center',
            dataIndex: "paymentStatus",
        },

        {
            title: "Action",
            dataIndex: "action",
            align: 'center',
            render: (text, record) => {
                let payMentLink = record?.paymentLink
                return (
                    <Space size={10}>
                    <Button
                        onClick={()=>copyPaymentLink(record)}
                        disabled={!payMentLink}
                        style={{
                          background: "none",
                          border: "none",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <CopyOutlined />
                      </Button>
                    </Space>
                )

            }
        },
    ];

    const tableData = paymentHistoryList;
    return (
        <Content className='content'>
            <div className='content-wrapper'>
                <Card >
                    <Table
                        className="table-striped"
                        style={{ overflow: "auto" }}
                        columns={tableColumns}
                        dataSource={tableData}
                        ascend={true}
                        rowKey={getTableRowKey}
                        showSizeChanger={true}
                        loading={manualQuotationPaymentHistoryListLoading}
                        pagination={{
                            total: totalRecords,
                            pageSize: pageValues.pageSize,
                            current: pageValues.currentPage,
                            showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            onChange: handlePageNoChange,
                            showSizeChanger: true,
                            pageSizeOptions: PAGINATION.DEFAULT_SIZE_OPTIONS,
                            onShowSizeChange: handlePageSizeChange,
                            itemRender: itemRender,
                        }}
                    />
                </Card>
            </div>
        </Content>
    )
}

export default QuotationPaymentHistory;
