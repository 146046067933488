import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Space,
  Table,
  Col,
  Row,
  Typography,
  notification,
  Popconfirm,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import {
  useCancelManualQuotationOrderMutation,
  useGetUserBalanceLazyQuery,
  useManualSubOrderQuotationListLazyQuery,
  useUpdateManualQuotationCompletedStatusMutation,
} from "../../../../graphql/generated/index.tsx";
import { itemRender } from "../../../../helper/Paginationfunction.js";
import BreadcrumbComponent from "../../../common/Breadcrumb.jsx";
import { LOCATIONS } from "../../../../config/routeConfig.js";
import { PAGINATION } from "../../../../config/constants.js";
import { MODULE_TITLES } from "../../../../config/moduleConfig.js";
import { usePagination } from "../../../../context/PaginationContext.jsx";
import { UNIQUE_MESSAGES } from "../../../../helper/MessagesHelper.js";
import { ConditionalComponent } from "../../../common/GeneralComponents.js";
import { getTableRowKey } from "../../../../helper/GeneralHelper.js";

const SalesOrderDetail = (props) => {
  const { search, pageValues, handlePageNoChange, handlePageSizeChange } =
    usePagination();
  const params = useParams();
  const navigate = useNavigate();
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [manualSubOrderList, setManualSubOrderList] = React.useState([]);
  const [isQuotationCancelled, setIsQuotationCancelled] = useState();
  const [buyerDetails, setBuyerDetails] = React.useState({});
  const [creditbalanceTotalAmount, setCreditbalanceTotalAmount] = React.useState(0);
  const [creditBalanceData, setCreditBalanceData] = React.useState([]);
  const [orignalQuotationAmount, setOrignalQuotationAmount] = React.useState(0);
  const [receivedAmount, setReceivedAmount] = React.useState(0);
  const [salesOrderDocument, setSalesOrderDocument] = React.useState();
  const [isQuotationCompleted, setIsQuotationCompleted] = React.useState(false);
  const [salesOrderId, setSalesOrderId] = React.useState("");

  // graphql Quatation list query call here

  const [
    getUpdateManualQuotationCompletedStatus,
    { loading: updateManualQuotationCompletedStatusLoading },
  ] = useUpdateManualQuotationCompletedStatusMutation();
  const handleManualQuotationCompailation = () => {
    getUpdateManualQuotationCompletedStatus({
      variables: {
        id: params?.manualQuotationOrderId && params?.manualQuotationOrderId,
      },
      onCompleted(data) {
        notification.success({
          message: data?.updateManualQuotationCompletedStatus?.message,
        });
        setIsQuotationCompleted(true);
      },
      onError(error) {
        notification.error({
          message: error.message,
        });
      },
      fetchPolicy: "network-only",
    });
  };

  const [
    getManualSubOrderListLazyQuery,
    { loading: manualSubOrderListLazyQueryLoading, refetch },
  ] = useManualSubOrderQuotationListLazyQuery();

  const getManualQuoationList = React.useCallback(
    (searchArg, currentPageArg, limitArg) => {
      let variables = {
        manualQuotationOrderId: params?.manualQuotationOrderId,
      };
      searchArg && (variables.search = searchArg);
      currentPageArg && (variables.page = currentPageArg - 1);
      limitArg && (variables.limit = limitArg);
      getManualSubOrderListLazyQuery({
        variables: variables,
        onCompleted(data) {
          setManualSubOrderList(data?.manualSubOrderQuotationList.data);
          setBuyerDetails(data?.manualSubOrderQuotationList.buyerDetails);
          setTotalRecords(data?.manualSubOrderQuotationList.totalRecords);
          setReceivedAmount(data?.manualSubOrderQuotationList?.receivedOrderAmount)
          setIsQuotationCancelled(
            data?.manualSubOrderQuotationList?.isQuotationCancelled
          );
          setOrignalQuotationAmount(
            data?.manualSubOrderQuotationList?.totalOrderAmount
          );
          setReceivedAmount(
            data?.manualSubOrderQuotationList?.receivedOrderAmount
          );
          setSalesOrderDocument(
            data?.manualSubOrderQuotationList?.salesOrderDocument
          );
          setIsQuotationCompleted(
            data?.manualSubOrderQuotationList?.isQuotationCompleted
          );
          setSalesOrderId(data?.manualSubOrderQuotationList?.salesOrderId);
        },
        onError(error) {
          notification.error({
            message: error.message,
          });
        },
        fetchPolicy: "network-only",
      });
    },
    [
      setManualSubOrderList,
      setTotalRecords,
      getManualSubOrderListLazyQuery,
      isQuotationCompleted,
    ]
  );

  React.useEffect(() => {
    getManualQuoationList(search, pageValues.currentPage, pageValues.pageSize);
  }, [pageValues]);

  const [cancelManualQuotationOrder] = useCancelManualQuotationOrderMutation();
  const confirmDeleted = useCallback(
    (id) => {
      cancelManualQuotationOrder({
        variables: { id: id },
        onCompleted(data) {
          notification.success({
            message: data.cancelManualQuotationOrder.message,
          });
        },
        onError(error) {
          notification.error({
            message: error.message,
          });
        },
        async update() {
          refetch();
        },
      });
    },
    [cancelManualQuotationOrder]
  );

  const [getUserBalance, { loading: getUserBalanceLoading }] =
    useGetUserBalanceLazyQuery();
  const getUserAddressList = useCallback(
    (userIdArg, limitArg, skipArg) => {
      let variables = {};
      userIdArg && (variables.userId = userIdArg);
      skipArg && (variables.page = skipArg - 1);
      limitArg && (variables.limit = limitArg);

      getUserBalance({
        variables: variables,
        onCompleted(data) {
          setCreditBalanceData(data?.getUserBalance?.data);
          setCreditbalanceTotalAmount(data?.getUserBalance?.totalAmount);
        },
        onError(error) {
          //   notification.error({
          //     message: error.message,
          //   });
        },
        fetchPolicy: "network-only",
      });
    },
    [setCreditBalanceData, setTotalRecords, getUserBalance]
  );

  useEffect(() => {
    getUserAddressList(
      params.buyerUserId,
      pageValues.pageSize,
      pageValues.currentPage
    );
  }, [pageValues]);

  const breadcrumb_items = [
    { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
    {
      label: MODULE_TITLES.QUOTE_GENERATION_NAME.SALES_ORDER,
      to: LOCATIONS.SALES_ORDER_ROUTES.ROOT,
    },
    {
      label: MODULE_TITLES.QUOTE_GENERATION_NAME.SALES_ORDER_DETAIL,
      to: LOCATIONS.SALES_ORDER_ROUTES.ORDER_DETAILS.replace(
        ":sellerUserId",
        params?.sellerUserId
      )
        .replace(":salesOrderId", params.salesOrderId)
        .replace(":manualQuotationOrderId", params.manualQuotationOrderId),
    },
  ];

  const tableColumns = [
    {
      title: " Id",
      dataIndex: "ID",
      render: (_text, record) =>
        record?.subOrderNumberId ? record?.subOrderNumberId : "-",
      align: "center",
    },
    {
      title: " Order Details",
      dataIndex: "details",
      render: (_text, record) => record?.productName || "-",
      align: "center",
    },
    {
      title: "Created At",
      dataIndex: "date",
      render: (_text, record) => {
        function formateDate(date) {
          let formatedDate = moment.unix(date / 1000).format("Do MMM YYYY");
          return formatedDate;
        }
        return record?.createdAt && formateDate(record?.createdAt);
      },
    },
    {
      title: "Updated At",
      dataIndex: "date",
      render: (_text, record) => {
        function formateDate(date) {
          let formatedDate = moment.unix(date / 1000).format("Do MMM YYYY");
          return formatedDate;
        }
        return record?.updatedAt && formateDate(record?.updatedAt);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_text, record) => record?.orderVerificationStatus || "-",
      align: "center",
    },
    {
      title: "Actions",
      align: "center",
      render: (_text, record) => {
        let viewManualQuotationURL =
          LOCATIONS.SALES_ORDER_ROUTES.PROCESS_ORDER_DETAILS.replace(
            ":sellerUserId",
            params.sellerUserId
          )
            .replace(":salesOrderId", params.salesOrderId)
            .replace(":manualQuotationOrderId", params.manualQuotationOrderId)
            .replace(":subOrderId", record?._id); // .replace(':manualQuotationOrderId', record._id);
        return (
          <Space size={10}>
            <Button
              type="link"
              shape="circle"
              icon={<EyeOutlined />}
              loading={false}
              onClick={() =>
                navigate(viewManualQuotationURL, {
                  state: { recordId: record?._id },
                })
              }
            />
          </Space>
        );
      },
    },
  ];
  
  const tableData = manualSubOrderList;

  const downloadFile = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = urlObject;
      a.download = "salesOrder"; // Set your desired file name here
      a.click();

      window.URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <Content className="content">
      <div className="page-header">
        <h2 className="page-title">
          {MODULE_TITLES.QUOTE_GENERATION_NAME.SALES_ORDER}
        </h2>
        <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
      </div>
      <div className="content-wrapper">
        <Card
          title={"Sales Order Details"}
          extra={
            <>
              <ConditionalComponent shouldShow={!isQuotationCompleted}>
                <Popconfirm
                  placement="bottomRight"
                  title={UNIQUE_MESSAGES.COMPLETE_POPUP("order")}
                  onConfirm={handleManualQuotationCompailation}
                  okText="Yes"
                  cancelText="No"
                >
                  <Space size={10}>
                    <Button type="primary" loading={false}>
                      Mark as Completed
                    </Button>
                  </Space>
                </Popconfirm>
              </ConditionalComponent>
              <ConditionalComponent shouldShow={isQuotationCompleted}>
                <Space size={10}>
                  <Button type="primary" loading={false} disabled>
                    Marked as Completed
                  </Button>
                </Space>
              </ConditionalComponent>
            </>
          }
          loading={manualSubOrderListLazyQueryLoading}
        >
          <Row className="d-flex justify-content-between direction-column">
            
              <Col sm={24} md={24}>
                <Typography.Title level={5}>
                  Sales Order ID : {salesOrderId && salesOrderId}
                </Typography.Title>
              </Col>
              <Col sm={24} md={10}>
                <Typography.Title level={5}>Buyer Details :</Typography.Title>
                <Typography>
                  {buyerDetails?.buyerName},<br />
                  {buyerDetails?.businessName} ,<br />
                  {buyerDetails?.fullAddress} ,<br />
                  {buyerDetails?.phoneNumber}
                </Typography>
              </Col>
           

            <Col style={{ textAlign: "end" }}>
              <Typography.Title level={5}>
                {`Total Quotation Amount : ₹${
                  orignalQuotationAmount && orignalQuotationAmount
                }`}
              </Typography.Title>
              <Typography.Title level={5}>
                {`Available Credit Balance : ₹${
                  creditbalanceTotalAmount && creditbalanceTotalAmount
                } `}
              </Typography.Title>
              <Typography.Title level={5}>
                {` Total Payment Received : ₹${
                  receivedAmount  && receivedAmount 
                }`}
              </Typography.Title>
            </Col>
          </Row>
          <ConditionalComponent shouldShow={!isQuotationCompleted}>
            <Row align="middle" justify="space-between">
              <Space size={15} className="mt-20 mb-10">
                <Popconfirm
                  placement="bottomRight"
                  title={UNIQUE_MESSAGES.DOWNLOAD_POPUP("sales order")}
                  onConfirm={() => downloadFile(salesOrderDocument)}
                  okText="Yes"
                  cancelText="No"
                  disabled={!salesOrderDocument}
                >
                  <Space size={10}>
                    <Button
                      type="primary"
                      loading={false}
                      disabled={!salesOrderDocument}
                    >
                      Download Sales Order
                    </Button>
                  </Space>
                </Popconfirm>

                <ConditionalComponent shouldShow={!isQuotationCancelled}>
                  <Button
                    onClick={() =>
                      navigate(
                        LOCATIONS.SALES_ORDER_ROUTES.PROCESS_ORDER.replace(
                          ":sellerUserId",
                          params.sellerUserId
                        )
                          .replace(":salesOrderId", params.salesOrderId)
                          .replace(
                            ":manualQuotationOrderId",
                            params.manualQuotationOrderId
                          )
                      )
                    }
                    type="primary"
                  >
                    Process Order
                  </Button>
                </ConditionalComponent>
                <ConditionalComponent shouldShow={!isQuotationCancelled}>
                  <Popconfirm
                    placement="bottomRight"
                    title={UNIQUE_MESSAGES.CANCLE_POPUP("Order")}
                    onConfirm={() =>
                      confirmDeleted(params?.manualQuotationOrderId)
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="primary">Cancel Order</Button>
                  </Popconfirm>
                </ConditionalComponent>

                <Button
                  onClick={() =>
                    navigate(
                      LOCATIONS.SALES_ORDER_ROUTES.MANAGE_PAYMENT.replace(
                        ":sellerUserId",
                        params.sellerUserId
                      )
                        .replace(":salesOrderId", params.salesOrderId)
                        .replace(
                          ":manualQuotationOrderId",
                          params.manualQuotationOrderId
                        )
                        .replace(":buyerUserId", params?.buyerUserId),
                      {
                        state: {
                          receivedAmount: receivedAmount,
                          creditbalanceTotalAmount: creditbalanceTotalAmount,
                          totalQuotationAmount:orignalQuotationAmount
                        },
                      }
                    )
                  }
                  type="primary"
                >
                  Manage Payment
                </Button>
              </Space>
            </Row>
          </ConditionalComponent>

{/* <Table
  dataSource={
    (!isAddOperation
      ? viewProductData?.products
      : productData
    ).map((item, index) => ({ ...item, key: `${index}_${item.someUniqueProperty}` }))
  }
  columns={columns}
  rowClassName="editable-row"
  pagination={false}
/> */}


          {manualSubOrderList.length > 0 ? (
            <Table
              className="table-striped mt-20"
              style={{ overflow: "auto" }}
              columns={tableColumns}
              dataSource={tableData}
              // dataSource={(tableData)?.map((item, index) => {
              //   const modifiedItem = { ...item, key: `${index}_${index + 1}` };
              //   return modifiedItem;  // Ensure you return the modified item
              // })}
              rowKey={getTableRowKey}
              ascend={true}
              showSizeChanger={true}
              loading={manualSubOrderListLazyQueryLoading}
              pagination={{
                total: totalRecords,
                pageSize: pageValues.pageSize,
                current: pageValues.currentPage,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                onChange: handlePageNoChange,
                showSizeChanger: true,
                pageSizeOptions: PAGINATION.DEFAULT_SIZE_OPTIONS,
                onShowSizeChange: handlePageSizeChange,
                itemRender: itemRender,
              }}
            />
          ) : null}
        </Card>
      </div>
    </Content>
  );
};

export default SalesOrderDetail;
