import React, { useState } from 'react';
import { Button, Form, Input, InputNumber, Popconfirm, Table } from 'antd';
const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
const ProductListTable = ({ data, onChangeUpdateRow, selectedRowKeys, setSelectedRowKeys, viewOnly = false }) => {
    const [form] = Form.useForm();
    const [salesOrderList, setSalesOrderList] = React.useState([]);
    const [editingKey, setEditingKey] = useState('');

    React.useEffect(() => {
        const getProductList = () => {
            let orderData = data.map((data, index) => {
                return {
                    ...data,
                    key: data._id,
                    srNo: index + 1
                }
            })
            setSalesOrderList(orderData)
        }
        getProductList();
    }, [data]);

    const isEditing = (record) => record.key === editingKey;
    const edit = (record) => {
        form.setFieldsValue({
            quantity: '',
            unitPrice: '',
            ...record,
        });
        setEditingKey(record.key);
    };
    const cancel = () => {
        setEditingKey('');
    };
    // const save = async (key) => {
    //     try {
    //         const row = await form.validateFields();
    //         const newData = [...salesOrderList];
    //         const index = newData.findIndex((item) => key === item.key);
    //         if (index > -1) {
    //             const item = newData[index];
    //             let updatedRow = {
    //                 ...item,
    //                 ...row,
    //             }
    //             updatedRow.totalAmount = updatedRow.quantity * updatedRow.unitPrice;
    //             newData.splice(index, 1, updatedRow);
    //             setSalesOrderList(newData);
    //             setEditingKey('');
    //             onChangeUpdateRow(updatedRow)
    //         }
    //     } catch (errInfo) {
    //     }
    // };

    const save = async (key) => {
        const row = await form.validateFields().catch(errInfo => ({ error: true }));
        if (row && !row.error) {
            const newData = [...salesOrderList];
            const index = newData.findIndex((item) => key === item.key);
    
            if (index > -1) {
                const item = newData[index];
                let updatedRow = {
                    ...item,
                    ...row,
                }
                updatedRow.totalAmount = updatedRow.quantity * updatedRow.unitPrice;
                newData.splice(index, 1, updatedRow);
                setSalesOrderList(newData);
                setEditingKey('');
                onChangeUpdateRow(updatedRow);
            }
        }
    };
    
    const columns = [
        {
            title: " Sr No.",
            dataIndex: "ID",
            render: (_text, record) => record?.srNo ? record?.srNo : "-",
            align: 'center'
        },
        {
            title: " Product Description",
            dataIndex: "details",
            render: (_text, record) => record?.productName ? record?.productName : "-",
            align: 'center',
        },
        {
            title: "HSN",
            dataIndex: "hsn",
            render: (_text, record) => record?.hsnCode ? record?.hsnCode : "-",
        },
        {
            title: "Qty",
            dataIndex: "quantity",
            render: (_text, record) => record?.quantity ? record?.quantity : "-",
            editable: true,
        },
        {
            title: "Unit",
            dataIndex: "unit",
            render: (_text, record) => record?.unit ? record?.unit : "-",
            align: 'center',
        },
        {
            title: "Price",
            dataIndex: "unitPrice",
            render: (_text, record) => (record?.unitPrice ?? record?.unitPrice.toFixed(2)),
            align: 'center',
            editable: true,
        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (_text, record) => (record?.quantity * record?.unitPrice).toFixed(2),
            align: 'right',
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Button
                            onClick={() => save(record.key)}
                            type="primary mr-5" >
                            Save
                        </Button>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <Button type="primary" >
                                Cancel
                            </Button>
                        </Popconfirm>
                    </span>
                ) : (
                    <Button disabled={editingKey !== ''} onClick={() => edit(record)} type="primary">
                        Edit
                    </Button>

                );
            },
        },
    ];

    const viewOnlyColumn = [
        {
            title: " Sr No.",
            dataIndex: "ID",
            render: (_text, record) => record?.srNo ? record?.srNo : "-",
            align: 'center'
        },
        {
            title: " Product Description",
            dataIndex: "details",
            render: (_text, record) => record?.productName ? record?.productName : "-",
            align: 'center',
        },
        {
            title: "HSN",
            dataIndex: "hsn",
            render: (_text, record) => record?.hsnCode ? record?.hsnCode : "-",
        },
        {
            title: "Qty",
            dataIndex: "quantity",
            render: (_text, record) => record?.quantity ? record?.quantity : "-",
            editable: true,
        },
        {
            title: "Unit",
            dataIndex: "unit",
            render: (_text, record) => record?.unit ? record?.unit : "-",
            align: 'center',
        },
        {
            title: "Price",
            dataIndex: "unitPrice",
            render: (_text, record) => record?.unitPrice ? (record?.unitPrice) : "-",
            align: 'center',
            editable: true,
        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (_text, record) => (record?.quantity * record?.unitPrice).toFixed(2),
            align: 'right',
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'quantity' || col.dataIndex === 'unitPrice' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <Form form={form} component={false}>
            {
                viewOnly ?
                    <Table
                        className='mt-20'
                        bordered
                        dataSource={salesOrderList}
                        columns={viewOnlyColumn}
                        pagination={false}
                    />
                    :
                    <Table
                        className='mt-20'
                        rowSelection={rowSelection}
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        bordered
                        dataSource={salesOrderList}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={false}
                    />
            }
        </Form>
    );
};
export default ProductListTable;