const ROOT = '/';

const LOGIN_ROUTE = `/login`;
const SIGN_UP_ROUTE = `/signup`;
const FORGET_PASSWORD_ROUTE = `/forgot-password`;

const ADMIN_ROUTES = {
    ROOT: `/admin`,
    ADD: `/admin/add`,
    EDIT: `/admin/edit/:adminId`
};

const ROLE_ROUTES = {
    ROOT: `/role`,
    ADD: `/role/add`,
    EDIT: `/role/edit/:roleId`,
};

const CATEGORY_ROUTES = {
    ROOT: `/category`,
    ADD: `/category/add`,
    EDIT: `/category/edit/:categoryId`,
    VIEW: '/category/view/:categoryId',
    SUBCATEGORY: `/category/sub-category/:categoryId`
};

const CATEGORY_RETURN_POLICY_ROUTES ={
    ROOT: `/returnpolicy`,
    VIEW: `/returnpolicy/view/:categoryId`,
    ADD: `/returnpolicy/add`,
    EDIT: `/returnpolicy/edit/:categoryId/:categoryReturnPolicyId`
}

const ATTRIBUTE_ROUTES = {
    ROOT: '/attribute/:categoryId',
    ADD: '/attribute/:categoryId/add',
    EDIT: '/attribute/:categoryId/edit/:attributeId',
}

const ATTRIBUTE_INPUTS_ROUTES = {
    ADD: '/attribute/:categoryId/:attributeId/inputType/manage'
};
const PRODUCT_ROUTES = {
    ROOT: '/products',
    VIEW: '/products/:productId'
}

const BULK_LIST_ROUTES={
    ROOT:'/bulkproductlist',
    VIEW: '/bulkproductlist/view/:bulkSheetId',
    VIEWBULKSTATUS:'/bulkproductlist/view/:bulkSheetId/:productId'
}

const SIZE_ROUTES = {
    ROOT: '/size/:categoryId',
    ADD:'/size/:categoryId/add',
    EDIT: '/size/:categoryId/edit/:sizeGroupId'
}

const BANK_ROUTES = {
    ROOT: `/bank-account`,
    ADD: `/bank-account/add`,
    EDIT: `/bank-account/edit/:bankId`,
    VIEW: '/bank-account/view/:bankId',
};

const DESIGNATION_ROUTES = {
    ROOT: `/designation`
};

const BUSINESSTYPE_ROUTES = {
    ROOT: `/business-type`
};

const COLOR_ROUTES = {
    ROOT: `/color`,
    ADD: `/color/add`,
    EDIT: `/color/edit/:colorId`,
};

const SAMPLE_ROUTES = {
    ROOT: `/sample`,
    FORM: `/sample/form`,
};
const POLICY_ROUTES = {
    ROOT:'/policy',
    EDIT:'/policy/:policyId',
    ADD:'/policy/add',
    VIEW:'/policy/view/:policyId',
}
const SHARE_FEEDBACK_ROUTES = {
    ROOT: '/sharefeedback'
}

const STATIC_PAGES_ROUTES = {
    ROOT:'/static-pages',
    EDIT:'/static-pages/:staticPageId',
    ADD:'/static-pages/add',
    VIEW:'/static-pages/view/:staticPageId',
};

const FAQ_ROUTES = {
    ROOT:'/faq/:faqTypeId',
    ADD:'/faq/:faqTypeId/add',
    EDIT:'/faq/:faqTypeId/edit/:faqId',
    VIEW:'/faq/:faqTypeId/view/:faqId',
    FAQ_TYPE:{
        ROOT:'/faq-type',
        ADD:'/faq-type/add',
        EDIT:'/faq-type/edit/:faqTypeId',
    }
};

const BRAND_SECTIONS_ROUTES = {
    ROOT: '/sectionbrand',
    LIST: '/sectionbrand/:categoryId',
    ADD: '/sectionbrand/add/:categoryId',
    EDIT:'/sectionbrand/edit/:categoryId/:sectionbrandId'
}

const BRAND_ROUTES = {
    ROOT: '/brand',
    SELLERS_OF_BRAND:{
        ROOT: '/brand/:brandId'
    }
}

const USER_ROUTES = {
    ROOT:'/user',
    VIEW:'/user/view/:userId'
};

const DASHBOARD_ROUTE = {
    ROOT: `/dashboard`
};

const HSN_ROUTES = {
    ROOT:'/hsn'
}

const ESCALATE_YOUR_ISSUE = {
    ROOT: '/escalated-issues',
}

const TOP_DEAL_ROUTES = {
    ROOT: '/topdeal',
    LIST: '/topdeal/:categoryId',
    ADD: '/topdeal/add/:categoryId',
    EDIT: `/topdeal/edit/:categoryId/:topDealId`,
}

const POPULAR_STYLE_CODES_ROUTES = {
    ROOT: '/popularstyle',
    LIST: '/popularstyle/:categoryId',
    ADD: '/popularstyle/add/:categoryId',
    EDIT:'/popularstyle/edit/:categoryId/:popularStyleId'
}

const OFFERS_ROUTES = {
    ROOT: '/offers',
    LIST: '/offers/:categoryId',
    ADD: '/offers/add/:categoryId',
    EDIT:'/offers/edit/:categoryId/:offersId'
}

const EXPLORE_STYLES = {
    ROOT: '/explorestyles',
    LIST: '/explorestyles/:categoryId',
    ADD: '/explorestyles/add/:categoryId',
    EDIT:'/explorestyles/edit/:categoryId/:exploreStylesId'
}



const PROMOTE_ADVERTISEMENT_ROUTES={
    ROOT:'/promoteadd',
    LIST: {
        ROOT: '/promoteadd/:categoryId',
        BANNER_ADVERTISEMENT:    '/promoteadd/:categoryId/banner',
        PRODUCT_ADVERTISEMENT:    '/promoteadd/:categoryId/product',
    },
    ADD: {
        ROOT: '/promoteadd/add/:categoryId',
        BANNER_ADVERTISEMENT:'/promoteadd/add/:categoryId/banner',
        PRODUCT_ADVERTISEMENT: '/promoteadd/add/:categoryId/product'
    },
    EDIT:{
        ROOT: '/promoteadd/edit/:categoryId/:promoteaddId',
        BANNER_ADVERTISEMENT:'/promoteadd/edit/:categoryId/:promoteaddId/banner',
        PRODUCT_ADVERTISEMENT: '/promoteadd/edit/:categoryId/:promoteaddId/product'
    },
    VIEW:{
        ROOT: '/promoteadd/view/:categoryId/:promoteaddId',
        BANNER_ADVERTISEMENT: '/promoteadd/view/:categoryId/:promoteaddId/banner',
        PRODUCT_ADVERTISEMENT: '/promoteadd/view/:categoryId/:promoteaddId/product',
    },
    ADDBANNERBYADMIN: {
        ROOT: '/promoteadd/view/:categoryId/:promoteaddId/add',
        BANNER_ADVERTISEMENT:'/promoteadd/view/:categoryId/:promoteaddId/add/banner',
        PRODUCT_ADVERTISEMENT: '/promoteadd/view/:categoryId/:promoteaddId/add/product',
    },   
    VIEWBANNERSTATUS:'/promoteadd/view/:categoryId/:promoteaddId/view/:sellerAdvId', 
}

const ORDERS_AND_RETURN_ROUTES = { 
    // ROOT: '/order&returns',
    ROOT: '/order&returns',
    VIEW: '/order&returns/:ordersandreturndetailId'
}

const OFFERS_AND_DISCOUNT_ROUTES={
    ROOT: '/offeranddiscount',
    ADD:  '/offeranddiscount/add',
    VIEW: '/offeranddiscount/view/:offeranddiscountId',
}

const QUOTE_GENERATION_ROUTES = {
    ROOT:'/quotegeneration',
    ADD: '/quotegeneration/createquotation',
    ADD_PRODUCT_QUOTATION:'/quotegeneration/createquotation/addproductquotation',
    ADD_QUOTATION_FOR_BUYER:'/quotegeneration/view/:quotegenerationId/addproductquotation',
    ADDITIONAL_CHARGES:'/quotegeneration/createquotation/additionalcharges',
    EDIT:'/quotegeneration/view/:quotegenerationId',
    ADD_NEW_SHIPPING_ADDRESS:'/quotegeneration/createquotation/addnewshippingaddress',
    ADD_NEW_BILLING_ADDRESS:'/quotegeneration/createquotation/addnewbillingaddress',
}

const  SALES_ORDER_ROUTES= {
    ROOT:'/salesOrderlist',
    ORDER_DETAILS:'/salesOrderlist/view/:sellerUserId/:salesOrderId/:manualQuotationOrderId/:buyerUserId',
    PROCESS_ORDER:'/salesOrderlist/ProcessOrder/:sellerUserId/:salesOrderId/:manualQuotationOrderId',
    PROCESS_ORDER_DETAILS:'/salesOrderlist/suborder/view/:sellerUserId/:salesOrderId/:manualQuotationOrderId/:subOrderId',
    MANAGE_PAYMENT:'/salesOrderlist/managepayment/:sellerUserId/:salesOrderId/:manualQuotationOrderId/:buyerUserId'
}  

const PROFILE_ROUTES = '/profile';
const SETTING_ROUTES = '/settings'


export const LOCATIONS = Object.freeze({
    ROOT,
    ADMIN_ROUTES,
    DASHBOARD_ROUTE,
    LOGIN_ROUTE,
    SIGN_UP_ROUTE,
    STATIC_PAGES_ROUTES,
    FORGET_PASSWORD_ROUTE,
    PROFILE_ROUTES,
    ROLE_ROUTES,
    SAMPLE_ROUTES,
    FAQ_ROUTES,
    POLICY_ROUTES,
    DESIGNATION_ROUTES,
    BUSINESSTYPE_ROUTES,
    CATEGORY_ROUTES,
    BANK_ROUTES,
    USER_ROUTES,
    COLOR_ROUTES,
    BRAND_ROUTES,
    ATTRIBUTE_ROUTES,
    ATTRIBUTE_INPUTS_ROUTES,
    PRODUCT_ROUTES,
    HSN_ROUTES,
    CATEGORY_RETURN_POLICY_ROUTES,
    ESCALATE_YOUR_ISSUE,
    SHARE_FEEDBACK_ROUTES,
    SIZE_ROUTES,
    TOP_DEAL_ROUTES,
    POPULAR_STYLE_CODES_ROUTES,
    OFFERS_ROUTES,
    EXPLORE_STYLES,
    BRAND_SECTIONS_ROUTES,
    PROMOTE_ADVERTISEMENT_ROUTES,
    BULK_LIST_ROUTES,
    ORDERS_AND_RETURN_ROUTES,
    OFFERS_AND_DISCOUNT_ROUTES,
    SETTING_ROUTES,
    QUOTE_GENERATION_ROUTES,
    SALES_ORDER_ROUTES,
});
