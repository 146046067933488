import React from 'react'
import { useNavigate, useParams } from 'react-router';
import * as Yup from "yup";
import { PlusOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { MODULE_TITLES } from '../../../../config/moduleConfig';
import { LOCATIONS } from '../../../../config/routeConfig';
import { AWSHelper } from '../../../../helper/S3helper';
import { IMAGE_TYPE, OPERATIONS, UPLOAD_PATH } from '../../../../config/constants';
import { UNIQUE_MESSAGES, commonValidationMessage } from '../../../../helper/MessagesHelper';
import { Card, Modal, Upload, notification } from 'antd';
import { DIMENSIONS_LIST, ImageNote, validateImageDimensions } from '../../../../helper/imageHelper';
import { usePopularStyleCodesDetailsByIdLazyQuery, useBrandListByProductCategoryLazyQuery, useCreateBrandSectionMutation } from "../../../../graphql/generated/index.tsx";
import { Form, Select, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import ImageCrop from '../../../common/ImageCrop/ImageCrop';
import { checkIfImageExists, generateRandomFileName } from '../../../../helper/GeneralHelper';
import BreadcrumbComponent from '../../../common/Breadcrumb';
import { default_s } from '../../../../helper/importHelper/image';
import { usePagination } from '../../../../context/PaginationContext.jsx';


const IMAGE_RATIO = DIMENSIONS_LIST.POPULAR_STYLES.MIN_WIDTH/DIMENSIONS_LIST.POPULAR_STYLES.MIN_HEIGHT ;
const AddEditBrandSelection = (props) => {

  const { operationType } = props
  const isEditOperation = operationType === OPERATIONS.EDIT
  const params = useParams()
  const navigate = useNavigate()
  const [rawPopularStyleImage, setRawPopularStyleImage] = React.useState();
  const [isCroppedImageValid, setIsCroppedImageValid] = React.useState(false);
  const [croppedImage, setCropImage] = React.useState(null);
  const [brandSelectionImage, setBrandSelectionImage] = React.useState([]);
  const [isModalVisible, setModalVisiblity] = React.useState(false);
  const [brandSectionList, setBrandSectionList] = React.useState(null)
  const { search, pageValues } = usePagination();

  /* Graphql Operations */
  const [createBrandSectioMutation] = useCreateBrandSectionMutation()
  const [getPopularStyleCodesDetailsByIdLazyQuery] = usePopularStyleCodesDetailsByIdLazyQuery()
  const [getBrandListByProductCategoryLazyQuery, { loading: brandListByProductCategoryLazyQueryLoading }] = useBrandListByProductCategoryLazyQuery()
  const getBrandSelectionList = React.useCallback((searchArg) => {
    let variables = {
      categoryId: params?.categoryId
    };
    searchArg && (variables.search = searchArg);
    getBrandListByProductCategoryLazyQuery({
      variables: variables,
      onCompleted(data) {
        setBrandSectionList(data?.brandListByProductCategory)
      },
      onError(error) {
        notification.error({
          message: error.message,
        });
      },
      fetchPolicy: "network-only"
    })
  }, [getBrandListByProductCategoryLazyQuery, params]);

  React.useEffect(() => {
    getBrandSelectionList(search);
  }, [params, pageValues, search]);

  React.useEffect(() => {
    if (isEditOperation) {
      getPopularStyleCodesDetailsByIdLazyQuery({
        variables: {
          id: params.popularStyleId
        },
        async onCompleted(data) {
          formik.setFieldValue('id', data?.popularStyleCodesDetailsById?._id, true);
          formik.setFieldValue('categoryId', data?.popularStyleCodesDetailsById?.categoryId, true);
          formik.setFieldValue('brandId', data?.popularStyleCodesDetailsById?.brandId, true)
          let newPopularStyleImage = []
          let isImageExists;
          await checkIfImageExists(data.popularStyleCodesDetailsById.image).then(() => {
            isImageExists = true
          })
            .catch(() => {
              isImageExists = false
            })
          newPopularStyleImage.push({
            url: data.popularStyleCodesDetailsById.image ? data.popularStyleCodesDetailsById.image : default_s,
            uid: data.popularStyleCodesDetailsById._id,
            name: data.popularStyleCodesDetailsById.image.split('/').pop(),
            thumbUrl: !isImageExists ? default_s : ""
          })
          formik.setFieldValue('image', newPopularStyleImage, true)
          setBrandSelectionImage(newPopularStyleImage)
        },
        onError(error) {
          notification.error({ message: error.message });
          navigate(LOCATIONS.BRAND_SECTIONS_ROUTES.ROOT)
        },
        fetchPolicy: "network-only"
      })
    }
  }, [])

  const breadcrumb_items = [
    { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
    { label: MODULE_TITLES.BRAND_SECTIONS.ROOT, to: LOCATIONS.BRAND_SECTIONS_ROUTES.ROOT },
    { label: MODULE_TITLES.BRAND_SECTIONS.LIST, to: LOCATIONS.BRAND_SECTIONS_ROUTES.LIST.replace(':categoryId', params?.categoryId) },
    { label: isEditOperation ? MODULE_TITLES.BRAND_SECTIONS.EDIT : MODULE_TITLES.BRAND_SECTIONS.ADD }
  ];

  const BrandSelectionValidationSchema = Yup.object().shape({
    id: Yup.string().when("categoryId", {
      is: () => isEditOperation,
      then: Yup.string().required(commonValidationMessage("Popular Style Id"))
    }),
    categoryId: Yup.string().required(commonValidationMessage('Category Id')),
    brandId: Yup.string().required(commonValidationMessage('Select Brand')),
    brandImage: Yup.array().min(1, "Brand Image is Must").required(commonValidationMessage("Brand Image"))
  });

  React.useEffect(() => {
    formik.setFieldValue('brandImage', brandSelectionImage)
  }, [brandSelectionImage])

  const formik = useFormik({
    initialValues: {
      categoryId: params.categoryId,
      brandImage: [],
      brandId: ''
    },
    validationSchema: BrandSelectionValidationSchema,
    onSubmit: (values, actions) => {
      let imagePromise = [];
      let variables = {
        ...values,
      }
      const images = values.brandImage?.[0]?.originFileObj ? [values.brandImage?.[0]?.originFileObj] : [];
      let imageName = values.brandImage?.[0]?.originFileObj ? generateRandomFileName("brandsection") + "." + values.brandImage?.[0]?.originFileObj.type.split("/").pop() : values.brandImage[0].name
      if (images.length > 0) {
        imagePromise.push(AWSHelper.upload(images[0], imageName, UPLOAD_PATH.BRAND_SECTION.replace(':categoryId', params?.categoryId)))
      }
      variables.brandImage = imageName
      if (variables.brandImage) {
        delete variables.image
      }
      Promise.all(imagePromise).then(_response => {
        performOperation(isEditOperation, {
          variables: isEditOperation ? variables : {
            input: variables
          },
          onCompleted(data) {
            actions.setSubmitting(false)
            notification.success({
              message: isEditOperation ? data.updatePopularStyleCode.message : data.createBrandSection.message
            })
            navigate(LOCATIONS.BRAND_SECTIONS_ROUTES.LIST.replace(':categoryId', params.categoryId));
          },
          onError(error) {
            notification.error({ message: error.message });
            actions.setSubmitting(false);
          },
        })
      }).catch(error => {
        notification.error({ message: error.message })
        actions.setSubmitting(false)
      })
    }
  });

  function performOperation(isEdit, options) {
    createBrandSectioMutation(options)
  }

  const photoUpload = React.useCallback(async ({ fileList: newFileList }) => {
    let currentFile = newFileList[0];
    let fileType = currentFile.type.split('/').pop()
    if (!(fileType === IMAGE_TYPE.JPEG || fileType === IMAGE_TYPE.JPEG_2 || fileType === IMAGE_TYPE.JPG_2 || fileType === IMAGE_TYPE.JPG || fileType === IMAGE_TYPE.PNG || fileType === IMAGE_TYPE.PNG_2)) {
      return notification.error({ message: UNIQUE_MESSAGES.IMAGE.ERROR.FILE_TYPE })
    }
    if ((currentFile.size >= 1024 * 1024 * 5)) {
      return notification.error({ message: UNIQUE_MESSAGES.IMAGE.ERROR.SIZE('5 MB') })
    }
    setRawPopularStyleImage(URL.createObjectURL(currentFile.originFileObj))
    setModalVisiblity(true)
  }, [setRawPopularStyleImage, setModalVisiblity,])

  async function handleValidateAfterCrop(blobImage) {
    const result = await validateImageDimensions(
      { originFileObj: blobImage },
      DIMENSIONS_LIST.POPULAR_STYLES.validator
    );
    if (!result.isValid) {
      setIsCroppedImageValid(false);
      setCropImage(null);
      notification.error({ message: result.message });
      return;
    }
    setIsCroppedImageValid(true);
    setCropImage(blobImage);
  }

  function handleCancelCrop() {
    setRawPopularStyleImage(null);
    setModalVisiblity(false);
  }

  function handleCropComplete() {
    if (croppedImage === null) return;
    let file = new File([croppedImage], 'brandSelectionImage', { type: croppedImage.type })
    setBrandSelectionImage([{ ...file, originFileObj: file }]);
    setIsCroppedImageValid(false);
    setModalVisiblity(false);
  }

  return (
    <Content className="content">
      <div className="page-header">
        <h2 className="page-title"> {MODULE_TITLES.BRAND_SECTIONS.ROOT} </h2>
        <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
      </div>
      <div className="content-wrapper">
        <Card title={isEditOperation ? MODULE_TITLES.BRAND_SECTIONS.EDIT : MODULE_TITLES.BRAND_SECTIONS.ADD} >
          <FormikProvider value={formik}>
            <Form labelWrap={true} name="add-offers-form" layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} >
              <Form.Item label="Brands" name="brandId" required>
                <Select
                  id="brandId"
                  name="brandId"
                  options={brandSectionList?.map(item => ({ label: item.brandName, value: item._id })) || []}
                  loading={brandListByProductCategoryLazyQueryLoading}
                  dropdownStyle={{ zIndex: "10000" }}
                  disabled={isEditOperation}
                />
              </Form.Item>
              <Form.Item label="Image" id="brandImage" name="brandImage" tooltip={`Image Dimensions should be minimum ${DIMENSIONS_LIST.POPULAR_STYLES.MIN_WIDTH}x${DIMENSIONS_LIST.POPULAR_STYLES.MIN_HEIGHT} .`} required >
                <Upload
                  accept=".png, .jpg, .jpeg"
                  id="brandImage"
                  name="brandImage"
                  action="/upload.do"
                  listType="picture-card"
                  maxCount={1}
                  beforeUpload={() => false}
                  onChange={photoUpload}
                  fileList={brandSelectionImage}
                  onPreview={() => false}
                  onRemove={() => false}
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8, }}>
                      {MODULE_TITLES.COMMON.UPLOAD}
                    </div>
                  </div>
                </Upload>
                <ImageNote width={DIMENSIONS_LIST.POPULAR_STYLES.MIN_WIDTH} height={DIMENSIONS_LIST.POPULAR_STYLES.MIN_HEIGHT} />
              </Form.Item>
              <Form.Item name="submit" wrapperCol={{ offset: 6, span: 14 }}>
                <SubmitButton loading={formik.isSubmitting} type="primary">
                  {MODULE_TITLES.COMMON.SAVE}
                </SubmitButton>
              </Form.Item>
            </Form>
          </FormikProvider>
        </Card>
      </div>
      <Modal
        title="Crop Image"
        centered
        visible={isModalVisible}
        maskClosable={false}
        mask={true}
        onOk={handleCropComplete}
        onCancel={handleCancelCrop}
        width="100%"
        okButtonProps={{
          disabled: !isCroppedImageValid
        }}
      >
        <ImageCrop imgSrc={rawPopularStyleImage} onValidate={handleValidateAfterCrop} aspectRatio={IMAGE_RATIO}  />
      </Modal>
    </Content>
  );
}

export default AddEditBrandSelection