import { Col, Row, Typography } from "antd";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import ReactCrop, {centerCrop, makeAspectCrop} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import 'react-image-crop/dist/ReactCrop.css';

const ImageCrop = ({imgSrc, onValidate, aspectRatio = 1,locked,isCroppedWithDimension=false,isImageForWeb=false}) => {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [aspect,setAspect] = useState(aspectRatio);
  const [completedCrop, setCompletedCrop] = useState();

  function onImageLoad(e) {
      const { width, height } = e.currentTarget
      if(isCroppedWithDimension){
        setCrop(centerAspectCropAdv(width, height, aspect,isImageForWeb))
      } else{
        setCrop(centerAspectCrop(width, height, aspect))
      }    
    
  }

  useEffect(()=>{
    if (imgRef.current){
      const { width, height } = imgRef.current
      setAspect(aspectRatio)
      if(isCroppedWithDimension){
        setCrop(centerAspectCropAdv(width, height, aspectRatio,isImageForWeb))
      } else {
        setCrop(centerAspectCrop(width, height, aspect))
      }    
    }
  },[imgSrc])

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
        ).then((blobResult)=>{
          
          typeof onValidate === "function" && onValidate(blobResult)
        })
      }
    },
    100,
    [completedCrop],
  )
  
  return (
    <Row>
      <Col>
      <Typography.Title level={3} >Original Image</Typography.Title>
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            keepSelection={true}
            locked={locked}       
          >
            <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
          </ReactCrop>
        )}
        </Col>
        <Col>
        <Typography.Title level={3}>Result Image</Typography.Title>
          {!!completedCrop && (
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height
              }}
            />
          )}
        </Col>
    </Row>

  );
};

function centerAspectCropAdv( mediaWidth, mediaHeight, aspect,isImageForWeb) {
  const truncatedAspectNumberNumber = Math.trunc(aspect * 100) / 100;
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width:isImageForWeb?1440:1024,
        height:isImageForWeb?480:576
      },
      aspect = truncatedAspectNumberNumber || aspect ,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

function centerAspectCrop( mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}
export default ImageCrop;
