import React, { useEffect } from 'react'
import { Button, Card, Col, Row, Typography, Divider, notification } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useNavigate, useParams } from 'react-router';
import BreadcrumbComponent from '../../../common/Breadcrumb.jsx';
import { LOCATIONS } from '../../../../config/routeConfig.js';
import { MODULE_TITLES } from '../../../../config/moduleConfig.js';
import { FormikProvider, useFormik } from 'formik';
import ProductListTable from './ProductListTable.jsx';
import AdditionalChargeTable from './AdditionalChargeTable.jsx';
import { useManualQuotationDetailLazyQuery, useAddManualQuotationSubOrderMutation, useManualQuotationGstDetailsLazyQuery } from '../../../../graphql/generated/index.tsx';
import { ConditionalComponent } from "../../../common/GeneralComponents";
import { Input, Form } from 'formik-antd';


const ProcessOrder = (props) => {
    const params = useParams()
    const navigate = useNavigate()
    const [additionalCharges, setAdditionalCharges] = React.useState([]);
    const [manualQuotationDetail, setManualQuotationDetail] = React.useState([]);
    const [gstDetails, setGstDetails] = React.useState(null)
    const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
    const [selectedAdditionalChargesRow, setSelectedAdditionalChargesRow] = React.useState([])
    const [productList, setProductList] = React.useState([]);
    const [addManualQuotationSubOrderMutation, { loading: addManualQuotationSubOrderLoading }] = useAddManualQuotationSubOrderMutation();
    const [getManualQuotationGstDetailsLazyQuery, { loading: manualQuotationGstDetailsLoading }] = useManualQuotationGstDetailsLazyQuery();
    const [getManualQuotationDetailsLazyQuery, { loading: getManualQuotationDetailsLoading }] = useManualQuotationDetailLazyQuery();

    const filterProducts = productList.filter((value) => selectedRowKeys.includes(value._id))
    const modifiedProductListData = filterProducts?.map(({ __typename, _id, hsnCode, key, srNo, ...rest }) => rest);


    const filterAdditionalCharges = additionalCharges.filter((value) => selectedAdditionalChargesRow.includes(value._id))
    const modifiedAditionalChargesData = filterAdditionalCharges?.map(({ _id,key,srNo, __typename, ...rest }) => rest);


    const formik = useFormik({
        initialValues: {
            transportName: '',
            vehicleNumber: '',
            termsAndConditions: '',
            eWayBillNumber: ''
        },
        validateOnChange: true,
        onSubmit: (values, actions) => {
            if (modifiedProductListData.length > 0) {
                let variables = {
                    input: {
                        // salesOrderId: params?.salesOrderId,
                        salesOrderId: manualQuotationDetail?.salesOrderId,
                        manualQuotationId: params?.manualQuotationOrderId,
                        categoryId: manualQuotationDetail?.categoryId,
                        products: modifiedProductListData,
                        additionalCharges: modifiedAditionalChargesData,
                        transportDetails: {},
                        termsAndConditions: values?.termsAndConditions,
                        quotationDescription: manualQuotationDetail?.quotationDescription,
                        gstPercentage: gstDetails?.gstPercentage,
                        gstAmount: gstDetails?.gstAmount,
                        cgstPercentage: gstDetails?.cgstPercentage,
                        cgstAmount: gstDetails?.cgstAmount,
                        sgstPercentage: gstDetails?.sgstPercentage,
                        sgstAmount: gstDetails?.sgstAmount,
                        igstPercentage: gstDetails?.igstPercentage,
                        igstAmount: gstDetails?.igstAmount,
                        totalOrderAmount: gstDetails?.totalOrderAmount,
                        roundOffType: gstDetails?.roundOffType,
                        roundOffAmount: gstDetails?.roundOffAmount,
                    },
                };
                if (variables.input.transportDetails && values.transportName) {
                    variables.input.transportDetails.transportName = values.transportName
                }
                if (variables.input.transportDetails && values.vehicleNumber) {
                    variables.input.transportDetails.vehicleNumber = values.vehicleNumber
                }
                if (
                    variables.input.transportDetails &&
                    !values.transportName &&
                    !values.vehicleNumber
                ) {
                    delete variables.input.transportDetails;
                }
                if(values?.eWayBillNumber ){
                    variables.input.eWayBillNumber = values?.eWayBillNumber
                }

                addManualQuotationSubOrderMutation({
                    variables: variables,
                    onCompleted(data) {
                        notification.success({
                            message: data.addManualQuotationSubOrder.message,
                        });
                        navigate(LOCATIONS.SALES_ORDER_ROUTES.ORDER_DETAILS.replace(':sellerUserId', params.sellerUserId).replace(':salesOrderId', params.salesOrderId).replace(':manualQuotationOrderId', params.manualQuotationOrderId)
                        .replace(':buyerUserId', manualQuotationDetail.buyerId));
                        actions.setSubmitting(false);
                    },
                    onError(error) {
                        notification.error({
                            message: error.message,
                        });
                        actions.setSubmitting(false);
                        actions.validateForm();
                    },
                });

            } else {
                notification.error({
                    message: "Please select product",
                });
            }
        },
    });

    React.useEffect(() => {
        getManualQuotationDetailsLazyQuery({
            variables: { id: params.manualQuotationOrderId },
            onCompleted(data) {
                formik.setFieldValue("termsAndConditions", data?.manualQuotationDetail?.termsAndConditions, true);
                formik.setFieldValue("transportName", data?.manualQuotationDetail?.transportDetails?.transportName, true);
                formik.setFieldValue("vehicleNumber", data?.manualQuotationDetail?.transportDetails?.vehicleNumber, true);
                setManualQuotationDetail(data?.manualQuotationDetail)
                setAdditionalCharges(data?.manualQuotationDetail?.additionalCharges)
                setProductList(data?.manualQuotationDetail?.products)
            },
            onError(error) {
                notification.error({ message: error.message });
            },
            fetchPolicy: "network-only",
        });
    }, []);

    const breadcrumb_items = [
        { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
        { label: MODULE_TITLES.QUOTE_GENERATION_NAME.SALES_ORDER, to: LOCATIONS.SALES_ORDER_ROUTES.ROOT },
        { label: MODULE_TITLES.QUOTE_GENERATION_NAME.SALES_ORDER_DETAIL, to: LOCATIONS.SALES_ORDER_ROUTES.ORDER_DETAILS.replace(':sellerUserId', manualQuotationDetail?.sellerId).replace(':salesOrderId', params.salesOrderId).replace(':manualQuotationOrderId', params.manualQuotationOrderId).replace(':buyerUserId', manualQuotationDetail?.buyerId) },
        { label: MODULE_TITLES.QUOTE_GENERATION_NAME.PROCESS_SALES_ORDER }
    ];
    let sumOfProductAmount = "0";
    let quotationAdditionalAmount = 0;
    let totalAddAdditionalCharge = 0;
    let totalLessAdditionalCharge = 0;


    if (selectedRowKeys.length > 0) {
        selectedRowKeys.map((id) => {
            productList.map((val) => {
                if (val._id === id) {
                    let productTotalAmount = parseFloat(String(sumOfProductAmount)) + parseFloat(String(val.totalAmount));
                    sumOfProductAmount = productTotalAmount;
                }
            })
        })
    } else {
        sumOfProductAmount = productList?.reduce((accumulator, item) => {
            return accumulator + (item?.quantity * item?.unitPrice);
        }, 0);
    }

    if (selectedAdditionalChargesRow.length > 0) {
        selectedAdditionalChargesRow.map((id) => {
            additionalCharges.map((val) => {
                if (val._id === id) {
                    if (val.type === "ADD") {
                        totalAddAdditionalCharge = totalAddAdditionalCharge + val.amount
                    } else {
                        totalLessAdditionalCharge = totalLessAdditionalCharge + val.amount
                    }

                }
            })
        })
    } else {
        additionalCharges.map((val) => {
            if (val.type === "ADD") {
                totalAddAdditionalCharge = totalAddAdditionalCharge + val.amount
            } else {
                totalLessAdditionalCharge = totalLessAdditionalCharge + val.amount
            }

        })
    }

    quotationAdditionalAmount = totalAddAdditionalCharge - totalLessAdditionalCharge;
    let totalAmountExclusingGST = sumOfProductAmount;

    if (quotationAdditionalAmount > 0) {
        totalAmountExclusingGST += quotationAdditionalAmount
    } else {
        totalAmountExclusingGST -= Math.abs(quotationAdditionalAmount)
    }

    if (selectedRowKeys.length > 0) {
        totalAmountExclusingGST = sumOfProductAmount
    }

    if (selectedAdditionalChargesRow.length > 0) {
        if (quotationAdditionalAmount > 0) {
            totalAmountExclusingGST += quotationAdditionalAmount
        } else {
            totalAmountExclusingGST -= Math.abs(quotationAdditionalAmount)
        }
    }

    const fetchUpdatedAmount = React.useMemo(() => {
        if (totalAmountExclusingGST) {
            getManualQuotationGstDetailsLazyQuery({
                variables: {
                    productId: productList[0]?.productId,
                    totalAmount: totalAmountExclusingGST,
                    isSellerBuyerAddressSame: manualQuotationDetail?.isSellerBuyerAddressSame
                },
                onCompleted(data) {
                    setGstDetails(prevValue => ({ ...prevValue, ...data?.manualQuotationGSTDetails }))
                },
                onError(error) {
                    notification.error({ message: error.message });
                },
                fetchPolicy: "network-only",
            });
        }
    }, [totalAmountExclusingGST, sumOfProductAmount, quotationAdditionalAmount])

    const onEditChangeProductList = (product) => {
        setProductList(prevValue =>
            [...prevValue].map(el => {
              let productObj = {
                hsnCode:product?.hsnCode,
                hsnCodeId:product?.hsnCodeId,
                productDescription:product?.productDescription,
                productId:product?.productId,
                productName:product?.productName,
                quantity:Number(product?.quantity.toFixed(2)),
                totalAmount:product?.totalAmount.toFixed(2).toString(),
                unit:product?.unit,
                unitPrice:Number(product?.unitPrice)?.toFixed(2).toString(),
                __typename:product?.__typename,
                _id:product?._id
              }
            //   let totalAmount =  product.totalAmount.toS
            //   el.totalAmount=totalAmount
                return el._id === product._id ? (productObj) : el
            })
        )
        fetchUpdatedAmount()
    }
    const onEditChangeAdditionalCharges = (data) => {
        setAdditionalCharges(prevValue =>
            [...prevValue].map(el =>
                el._id === data._id ? ({ ...data }) : el)
        )
        fetchUpdatedAmount()
    }

    useEffect(() => {
        if (gstDetails?.totalOrderAmount <= 0 && selectedRowKeys.length > 0) {

            notification.error({
                message: "Final amount must be positive"
            });
        }
    }, [gstDetails?.totalOrderAmount])

    return (
        <Content className='content'>
            <div className='page-header'>
                <h2 className='page-title'>{MODULE_TITLES.QUOTE_GENERATION_NAME.SALES_ORDER}</h2>
                <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
            </div>
            <div className='content-wrapper'>
                <Card title={"Sales Order Details"} loading={getManualQuotationDetailsLoading || addManualQuotationSubOrderLoading}>
                    <Row>
                        <Col sm={24} md={10}>
                            <Typography.Title level={5}>Buyer Details :</ Typography.Title>
                            <Typography>
                                {manualQuotationDetail?.buyerDetails?.buyerName},<br />
                                {manualQuotationDetail?.buyerDetails?.businessName},<br />
                                {manualQuotationDetail?.buyerDetails?.fullAddress},<br/>
                                {manualQuotationDetail?.buyerDetails?.phoneNumber}
                            </Typography>
                        </Col>
                    </Row>

                    <ProductListTable data={productList} onChangeUpdateRow={onEditChangeProductList} setSelectedRowKeys={setSelectedRowKeys} />
                   
                    <Col align="end" style={{ margin: "12px 15px 0px 0px" }}>
                        <strong>
                            Gross Amount : {sumOfProductAmount ? sumOfProductAmount?.toFixed(2) : 0}{" "}
                            RS
                        </strong>
                    </Col>
                    <Divider />

                    <Typography.Title level={5} strong>Additional Charges</ Typography.Title>

                    <AdditionalChargeTable data={additionalCharges} onChangeUpdateRow={onEditChangeAdditionalCharges} setSelectedAdditionalChargesRow={setSelectedAdditionalChargesRow} />
                    
                    <ConditionalComponent
                        shouldShow={additionalCharges.length > 0}
                    >

                        <Col align="end" style={{ margin: "12px 15px 12px 0px" }}>
                            <strong>
                                Additional Amount :{" "}
                                {quotationAdditionalAmount && quotationAdditionalAmount} RS
                            </strong>
                            <br />
                        </Col>
                    </ConditionalComponent>

                    <ConditionalComponent
                        shouldShow={gstDetails?.igstPercentage > 0}
                    >
                        <Col align="end" style={{ margin: "12px 15px 0px 0px" }}>
                            <strong>
                                {`IGST ${gstDetails?.igstPercentage}% : ${gstDetails?.igstAmount} RS`}
                            </strong>
                        </Col>
                    </ConditionalComponent>

                    <ConditionalComponent
                        shouldShow={gstDetails?.cgstPercentage > 0}
                    >
                        <Col align="end" style={{ margin: "12px 15px 0px 0px" }}>
                            <strong>
                                {`CGST ${gstDetails?.cgstPercentage}% : ${gstDetails?.cgstAmount} RS`}
                            </strong>
                        </Col>
                    </ConditionalComponent>

                    <ConditionalComponent
                        shouldShow={gstDetails?.sgstPercentage > 0}
                    >
                        <Col align="end" style={{ margin: "12px 15px 0px 0px" }}>
                            <strong>
                                {`SGST ${gstDetails?.sgstPercentage}% : ${gstDetails?.sgstAmount} RS`}
                            </strong>
                        </Col>
                    </ConditionalComponent>

                    <Col align="end" style={{ margin: "12px 15px 0px 0px" }}>
                        <strong>
                            Total Amount : {gstDetails?.totalOrderAmount} RS
                        </strong>
                    </Col>
                    <Divider />
                    <FormikProvider value={formik} >
                        <Form
                            labelAlign="left"
                            labelWrap={true}
                            name="add-edit-category-page-form"
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 10 }}
                        >
                            <Form.Item label="Terms & Conditions" name="termsAndConditions">
                                <Input.TextArea
                                    name="termsAndConditions"
                                    value={formik?.values?.termsAndConditions}
                                    rows={6}
                                    maxLength={400}
                                    placeholder="Terms & Conditions..."
                                    showCount
                                    onChange={(e) => formik.setFieldValue("termsAndConditions", e.target.value.slice(0, 400), true)}
                                />
                            </Form.Item>

                            <Typography.Title level={5} strong>Transport Detail</ Typography.Title>
                            <Form.Item label="Transport Name" name="transportName">
                                <Input
                                    value={formik?.values?.transportName}
                                    type="text"
                                    id="transportName"
                                    name="transportName"
                                    placeholder="Transport Name..."
                                    suffix={<span />}
                                    onChange={(e) =>
                                        formik.setFieldValue("transportName", e.target.value, true)
                                    }
                                />
                            </Form.Item>

                            <Form.Item label="Vehicle Number" name="vehicleNumber">
                                <Input
                                    value={formik?.values?.vehicleNumber}
                                    type="text"
                                    id="vehicleNumber"
                                    name="vehicleNumber"
                                    placeholder="Vehicle Number..."
                                    suffix={<span />}
                                    onChange={(e) =>
                                        formik.setFieldValue("vehicleNumber", e.target.value, true)
                                    }
                                />
                            </Form.Item>

                            <Form.Item label="E-way Bill" name="eWayBillNumber">
                                <Input
                                    type="text"
                                    id="eWayBillNumber"
                                    name="eWayBillNumber"
                                    placeholder="E-way Bill..."
                                    suffix={<span />}
                                    onChange={(e) =>
                                        formik.setFieldValue("eWayBillNumber", e.target.value.toUpperCase(), true)
                                    }
                                />
                            </Form.Item>
                        </Form>
                        <div className="text-center mt-12">
                            <Button type="primary" onClick={formik.submitForm} disabled={gstDetails?.totalOrderAmount <=0 || manualQuotationGstDetailsLoading }>Save</Button>
                        </div>
                    </FormikProvider>

                </Card>
            </div>
        </Content>
    )
}

export default ProcessOrder;
