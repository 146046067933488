import React from 'react'
import { Card, Col, Row, Typography, notification, Form, Select, Tag } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useNavigate, useParams } from 'react-router'
import { MODULE_TITLES } from '../../../config/moduleConfig';
import { LOCATIONS } from '../../../config/routeConfig';
import BreadcrumbComponent from '../../common/Breadcrumb';
import BusinessDetails from './BusinessDetails';
import OrderDetails from './OrderDetails';
import ShippingDetails from './ShippingDetails';
import { useOrderDetailsByIdLazyQuery, useUpdateTrackingOrderStatusAdminMutation } from '../../../graphql/generated/index.tsx';
import InvoiceDetails from './InvoiceDetails';
import PaymentDetails from './PaymentDetails';
import { SHIPMENT_MANAGEMENT_TYPE, TRACKING_ORDER_STATUS } from '../../../config/constants';
import { ConditionalComponent } from '../../common/GeneralComponents';
import SampleDetail from './SampleDetail';

const breadcrumb_items = [
    { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
    { label: MODULE_TITLES.ORDER_AND_RETURNS.ROOT,to: LOCATIONS.ORDERS_AND_RETURN_ROUTES.ROOT },
    { label:MODULE_TITLES.ORDER_AND_RETURNS.VIEW}
];

const ViewOrderAndReturnDetails = (props) => {
    const params = useParams()
    const navigate = useNavigate()
    const [orderDetailData,setOrderDetailData]=React.useState([])
    const [updateTrackingOrderStatus, setUpdateTrackingOrderStatus] = React.useState()
    const [getOrderDetailsById,{loading:orderDetailsByIdloading}]=useOrderDetailsByIdLazyQuery()
    const [updateTrackingOrderStatusAdminMutation,{loading:updateTrackingOrderStatusAdminLoading}] = useUpdateTrackingOrderStatusAdminMutation()
    // const isTrackingOrderStatus = orderDetailData?.trackingOrderStatus === "NOT_SHIPPED"


    const getBulkUploadList = React.useCallback(() => {
        let variables = {
            id: params?.ordersandreturndetailId
        };

        getOrderDetailsById({
            variables: variables,
            onCompleted(data) {
                setOrderDetailData(data?.orderDetailsById)
            },
            onError(error) {
                notification.error({
                    message: error.message,
                });
            },
            fetchPolicy: "network-only"
        })
    }, [setOrderDetailData,getOrderDetailsById]);

    React.useEffect(() => {
        getBulkUploadList();
    }, []);

    const sendTrackingOrderStatus = React.useCallback((updateTrackingOrderStatusArg) => {
        let variables = {
            id: params?.ordersandreturndetailId,
            trackingOrderStatus: updateTrackingOrderStatusArg && updateTrackingOrderStatusArg
        };
        updateTrackingOrderStatusAdminMutation({
            variables: variables,
            onCompleted(data) {
                notification.success({
                    message: data.updateTrackingOrderStatusAdmin.message
                })
                navigate(LOCATIONS.ORDERS_AND_RETURN_ROUTES.VIEW);
            },
            onError(error) {
                notification.error({
                    message: error.message,
                    onClose:notification.destroy()
                });
            },
        })
    }, [setUpdateTrackingOrderStatus, updateTrackingOrderStatusAdminMutation])

    const handleTrackingUpdateChange=React.useCallback((selectedValue)=>{
        setUpdateTrackingOrderStatus(selectedValue)
        sendTrackingOrderStatus(selectedValue)
    })

    return (
        <Content className='content'>
        <div className='page-header'>
            <h2 className='page-title'>{MODULE_TITLES.ORDER_AND_RETURNS.ROOT}</h2>
            <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
        </div>
        <div className='content-wrapper'>
                <Row>
                    <Col sm={24}>
                        <Card title={MODULE_TITLES.ORDER_AND_RETURNS.VIEW} loading={orderDetailsByIdloading || updateTrackingOrderStatusAdminLoading}>
                            <Row justify='space-between'>
                                <Row gutter={24}>
                                    <Col >
                                        <Typography.Title level={5}>ID : {orderDetailData?.orderNumberId}</Typography.Title>
                                    </Col>
                                   { orderDetailData?.isSampleOrder && <Col>
                                        <Tag>
                                           <strong>{orderDetailData?.isSampleOrder && "Sample Order"}</strong> 
                                        </Tag>
                                    </Col>}
                                </Row>
                                
                                {/* <ConditionalComponent shouldShow={!isTrackingOrderStatus}> */}
                                    <Form
                                        id="updateTrackingOrderStatusAdmin"
                                        name="updateTrackingOrderStatusAdmin"
                                        layout="inline"
                                        initialValues={orderDetailData?.trackingOrderStatus &&
                                            orderDetailData?.trackingOrderStatus.replace(/_/g, " ")}
                                    >
                                        <Form.Item label={<strong>
                                            { orderDetailData?.shipmentManagementType === SHIPMENT_MANAGEMENT_TYPE.DELHIVERY ? "Tracking status" : "Update Tracking Order Status To"     }
                                        
                                        </strong>}>
                                            <Select
                                                allowClear={true}
                                                style={{ width: 230 }}
                                                placeholder="Update Delivery Status"
                                                name="updateTrackingOrderStatusAdmin"
                                                defaultValue={orderDetailData?.trackingOrderStatus && orderDetailData?.trackingOrderStatus.replace(/_/g, " ")}
                                                value={updateTrackingOrderStatus}
                                                loading={updateTrackingOrderStatusAdminLoading}
                                                onChange={handleTrackingUpdateChange}
                                                options={Object.values(TRACKING_ORDER_STATUS).map(val => ({ label: val.replace(/_/g, " "), value: val, }))}
                                                disabled={orderDetailData?.shipmentManagementType === SHIPMENT_MANAGEMENT_TYPE.DELHIVERY}
                                            />
                                        </Form.Item>
                                    </Form>
                                {/* </ConditionalComponent> */}

                            </Row>
                            <BusinessDetails sellerUserDetails={orderDetailData?.sellerUserDetails} buyerUserDetails={orderDetailData?.buyerUserDetails} />
                            {
                                orderDetailData?.subOrderList?.length !== 0 ? <OrderDetails subOrderList={orderDetailData?.subOrderList} /> : <SampleDetail productImage={orderDetailData?.productImage} productName={orderDetailData?.productName}
                                    unit={orderDetailData?.sampleOrderMeasurementUnit} quantity={orderDetailData?.sampleOrderQuantity}
                                />
                            }
                            {/* <PackageAndChargesDetails orderId={params?.ordersandreturndetailId} isDeliveryChargeApplied={orderDetailData?.isDeliveryChargeApplied} totalPackageWeight={orderDetailData?.totalPackageWeight} isCouponApplied={orderDetailData?.isCouponApplied}
                            couponType={orderDetailData?.couponType} trackingOrderStatus={orderDetailData?.trackingOrderStatus}
                            /> */}
                            <ShippingDetails sourceAddress={orderDetailData?.sourceAddress} destinationAddress={orderDetailData?.destinationAddress} />
                            <InvoiceDetails invoiceOrderPaymentDetails
                                ={orderDetailData?.invoiceOrderPaymentDetails} invoiceNumberId={orderDetailData?.invoiceNumberId} expectedDeliveryDate={orderDetailData?.expectedDeliveryDate}  
                                deliveredDate={orderDetailData?.deliveredDate}
                                sampleOrderType={orderDetailData?.sampleOrderType} />
                            <PaymentDetails razorPayPaymentStatus={orderDetailData?.razorPayPaymentStatus} orderNumberId={orderDetailData?.orderNumberId} />
                        </Card>
                    </Col>
                </Row>              
        </div>
    </Content>
    )
}

export default ViewOrderAndReturnDetails