export const AUTH_TYPES = {
    LOGIN: 'LOGIN',
    EDIT: 'EDIT',
    LOGOUT: 'LOGOUT',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    UPDATE_SELLER_REGISTRATION: 'UPDATE_SELLER_REGISTRATION',

    // manual quotation order 
    QUOTATION_GENERATION_PRODUCT_FORM:'QUOTATION_GENERATION_PRODUCT_FORM',
    QUOTATION_GENERATION_ADDITIONAL_CHARGES:'QUOTATION_GENERATION_ADDITIONAL_CHARGES',
    QUOTATION_NEW_SHIPPING_ADDRESS:'QUOTATION_NEW_SHIPPING_ADDRESS',
    QUOTATION_NEW_BILLING_ADDRESS:'QUOTATION_NEW_BILLING_ADDRESS',
    UPDATE_SELECTED_CATEGORY:'UPDATE_SELECTED_CATEGORY',
    UPDATE_SELECTED_BUYER:'UPDATE_SELECTED_BUYER',

    UPDATE_SELECTED_SHIPPING_ADDRESS:'UPDATE_SELECTED_SHIPPING_ADDRESS',
    UPDATE_BROKER_NAME:'UPDATE_BROKER_NAME',
    UPDATE_BROKER_CONTACT_NUMBER:'UPDATE_BROKER_CONTACT_NUMBER',
    UPDATE_QUOTATION_DESCRIPTION:'UPDATE_QUOTATION_DESCRIPTION',
    UPDATE_QUOTATION_SHIPPING_ADDRESS_RADIO_BTN : 'UPDATE_QUOTATION_SHIPPING_ADDRESS_RADIO_BTN',
    UPDATE_QUOTATION_BILLING_ADDRESS_CHECKBOX : 'UPDATE_QUOTATION_BILLING_ADDRESS_CHECKBOX',
    UPDATE_QUOTATION_BILLING_ADDRESS_RADIO_BTN:'UPDATE_QUOTATION_BILLING_ADDRESS_RADIO_BTN',
    // RESET_ALL_STATES:'RESET_ALL_STATES'
}