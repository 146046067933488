import { Card, Col, Row, Space, Tabs, Typography } from "antd"
import OnlinePaymentQuotation from "./OnlinePaymentQuotation"
import OflinePaymentQuotation from "./OflinePaymentQuotation"
import { PAYMENT_MODE } from "../../../../config/constants"
import { useEffect, useState } from "react"
import QuotationPaymentHistory from "./QuotationPaymentHistory"
import { Content } from "antd/lib/layout/layout"
import { MODULE_TITLES } from "../../../../config/moduleConfig"
import BreadcrumbComponent from "../../../common/Breadcrumb"
import { LOCATIONS } from "../../../../config/routeConfig"
import { useLocation, useParams } from "react-router"

const PaymentModeTab = () => {
    const params = useParams()
    const location = useLocation()
    const receivedOrderAmount = location?.state?.receivedAmount
    const creditbalanceTotalAmount = location?.state?.creditbalanceTotalAmount
    const orignalQuotationAmount = location?.state?.totalQuotationAmount
    const [tabState, setTabState] = useState(PAYMENT_MODE.ONLINE);
    const [receivedOrderAmountData, setReceivedOrderAmountData] = useState(receivedOrderAmount)
    const [quotationPaymentDetails, setQuotationPaymentDetails] = useState('')
    const [totalPayment, setTotalPayment] = useState(0);

    useEffect(() => {
        setTotalPayment(receivedOrderAmountData);
    }, [
        receivedOrderAmountData,
        receivedOrderAmount,
        tabState,
        quotationPaymentDetails,
    ]);

    const breadcrumb_items = [
        { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
        { label: MODULE_TITLES.QUOTE_GENERATION_NAME.SALES_ORDER, to: LOCATIONS.SALES_ORDER_ROUTES.ROOT },
        {
            label: MODULE_TITLES.QUOTE_GENERATION_NAME.SALES_ORDER_DETAIL, to: LOCATIONS.SALES_ORDER_ROUTES.ORDER_DETAILS.replace(':sellerUserId', params.sellerUserId).replace(':salesOrderId', params.salesOrderId).replace(':manualQuotationOrderId', params.manualQuotationOrderId)
                .replace(':buyerUserId', params.buyerUserId)
        }, { label: MODULE_TITLES.QUOTE_GENERATION_NAME.MANAGE_PAYMENT }
    ];

    return (
        <Content className='content'>
            <div className='page-header'>
                <h2 className='page-title'>{MODULE_TITLES.QUOTE_GENERATION_NAME.MANAGE_PAYMENT}</h2>
                <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
            </div>

            <div className='content-wrapper'>
                <Card title={MODULE_TITLES.QUOTE_GENERATION_NAME.MANAGE_PAYMENT} extra={
                    <Space size={10}>
                        <Typography.Title level={5}>
                            Total Payment Received : ₹{totalPayment} 
                        </Typography.Title >
                        <br />
                        <Typography.Title level={5}>
                            Available Credit balance : ₹{creditbalanceTotalAmount} 
                        </Typography.Title>
                    </Space>
                } >
                    <Col>
                        <Row justify="end">
                            <Typography.Title level={5}>
                                {`Total Quotation Amount : ₹${orignalQuotationAmount && orignalQuotationAmount
                                    }`}
                            </Typography.Title>
                        </Row>
                        <Tabs type="card" activeKey={tabState} onChange={setTabState}>
                            <Tabs.TabPane tab={MODULE_TITLES.QUOTE_GENERATION_NAME.ONLINE_PAYMENT} key={PAYMENT_MODE.ONLINE}>
                                <OnlinePaymentQuotation tabState={tabState} setTabState={setTabState} userId={params?.buyerUserId && params?.buyerUserId} manualQuotationId={params?.manualQuotationOrderId && params?.manualQuotationOrderId} setReceivedOrderAmountData={setReceivedOrderAmountData}
                                setQuotationPaymentDetails={setQuotationPaymentDetails}
                                quotationPaymentDetails={quotationPaymentDetails}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={MODULE_TITLES.QUOTE_GENERATION_NAME.OFLINE_PAYMENT} key={PAYMENT_MODE.OFFLINE} >
                                <OflinePaymentQuotation tabState={tabState} setTabState={setTabState} userId={params?.buyerUserId && params?.buyerUserId} manualQuotationId={params?.manualQuotationOrderId && params?.manualQuotationOrderId} setReceivedOrderAmountData={setReceivedOrderAmountData} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={MODULE_TITLES.QUOTE_GENERATION_NAME.PAYMENT_HISTORY} key={PAYMENT_MODE.HISTORY} >
                                <QuotationPaymentHistory tabState={tabState} manualQuotationId={params?.manualQuotationOrderId && params?.manualQuotationOrderId} receivedOrderAmountData={receivedOrderAmountData} />
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                </Card>
            </div>
        </Content>
    )
}
export default PaymentModeTab
