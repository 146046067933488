import { PERMISSION_SLUG } from "../config/constants";
import { store } from '../redux/store/store'

export function getProcessedRoles(userData) {
    let processedRoles = {}

    userData?.roles?.forEach(value => {
        processedRoles[value?.moduleSlug] = value?.permissionTypes?.map(permission => permission?.permissionTypeSlug)
    })

    return processedRoles
}

export function makePermissionChecker(processedRoles = {}) {

    function hasModulePermission(moduleSlug) {
        /* Comment This default true once we have to use Permissions  */
        // return true;

        /* Remove Comment once we have to use Permissions  */
        return processedRoles.hasOwnProperty(moduleSlug) ? true : false;
    }

    function hasTypePermission(moduleSlug, permissionTypeSlug) {
        /* Comment This default true once we have to use Permissions  */
        // return true;

        /* Remove Comment once we have to use Permissions  */
        if (hasModulePermission(moduleSlug)) {
            return processedRoles?.[moduleSlug]?.includes(permissionTypeSlug)
        }else{
            return false
        }
    }

    return {
        hasModulePermission,
        hasTypePermission
    }
}

export class PermissionHelper {
    _permissions;
    processedRoles;

    constructor(processedRoles , permissionSlug = { ...PERMISSION_SLUG }) {
        this._permissions = Object.keys(permissionSlug).reduce((prev, current) => ({ ...prev, [current]: false }), {});
        this.processedRoles = Object(processedRoles);
    }
    getPermissionsForModule = (moduleSlug) => {
        if (this.processedRoles.hasOwnProperty(moduleSlug)) {
            
            let permissions = {...this._permissions}
            this.processedRoles[moduleSlug].forEach((permissionType) => {
                permissions[permissionType] = true 
            })

            return permissions;
        } else {
            return this._permissions
        }
    }

}
export const permissionHelper = new PermissionHelper();