import React from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu, Layout, Switch, Row, notification } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { userActions } from "../../redux/actions/user.actions";
import { LOCATIONS } from "../../config/routeConfig";
import { Full_Logo_Monolined_White_Svg } from "../../helper/importHelper/svg";
import { MODULE_TITLES } from "../../config/moduleConfig";
import { useState, useEffect, useCallback } from "react";
import { useUpdateGeneralSettingMutation } from "../../graphql/generated/index.tsx";
import { TOGGLE } from "../../config/constants";

const { Header: AntdHeader } = Layout;

const Header = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState([]);

  const logout = () => {
    props.logout();
  };

  const menubar_items = [
    {
      icon: <UserOutlined />,
      label: props.userData.fullName,
      key: "1",
      children: [
        {
          key: LOCATIONS.PROFILE_ROUTES,
          icon: <UserOutlined />,
          label: MODULE_TITLES.PROFILE.ROOT,
          onClick: () => navigate(LOCATIONS.PROFILE_ROUTES),
          style: { display: "flex", alignItems: "center" },
        },
        {
          icon: <LogoutOutlined />,
          key: "logout",
          onClick: logout,
          label: MODULE_TITLES.COMMON.LOGOUT,
          style: { display: "flex", alignItems: "center" },
        },
        {
          key: "settings",
          icon: <SettingOutlined />,
          label: MODULE_TITLES.COMMON.SETTING,
          onClick: () => navigate(LOCATIONS.SETTING_ROUTES),
          style: { display: "flex", alignItems: "center" },
        }
      ],
    },
  ];
  const handleMenuChangeEffect = useCallback(() => {
    let currentModule =
      menubar_items[0].children.find((menu_item) =>
        location.pathname.includes(menu_item.key)
      ) || "";
    if (currentModule) {
      let currentPath = [currentModule.key];
      selectedKey.toString() !== currentPath.toString() &&
        setSelectedKey(currentPath);
    } else {
      setSelectedKey("");
    }
  }, [selectedKey, setSelectedKey, location]);

  useEffect(handleMenuChangeEffect, [location.pathname]);

  return (
    <AntdHeader className="header fixed-header">
      <div className="logo d-flex align-items-center justify-content-center px-5">
        <Link to={LOCATIONS.DASHBOARD_ROUTE.ROOT}>
          <img
            src={Full_Logo_Monolined_White_Svg}
            alt="Full Logo Monolined"
            className="img-fluid"
          />
        </Link>
      </div>
      <Menu
        theme="dark"
        mode="horizontal"
        items={menubar_items}
        className="flex-row-reverse"
        defaultSelectedKeys={[]}
        selectedKeys={selectedKey}
      ></Menu>
    </AntdHeader>
  );
};



const mapStateToProps = (state) => ({
  userData: state.authentication.userData,
});

const mapDispatchToProps = {
  logout: userActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
