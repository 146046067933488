import React  from 'react';
import { Layout, Modal, notification } from 'antd';
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { Form, Input,Select } from "formik-antd";
import { useAddCreditDebitBalanceMutation} from '../../../../graphql/generated/index.tsx';
import { commonValidationMessage } from '../../../../helper/MessagesHelper.js';
import { BALANCE_TYPES, OPERATIONS } from '../../../../config/constants.js';
import { MODULE_TITLES } from '../../../../config/moduleConfig.js';

const { Content } = Layout;

const AMOUNT_TYPE = [
    { label: BALANCE_TYPES.CREDIT, value: BALANCE_TYPES.CREDIT},
    { label: BALANCE_TYPES.DEBIT, value: BALANCE_TYPES.DEBIT },
]

const AddCredit = ({ _id, isModalVisible, closeModal, operationType, refetch, ...props }) => {
    const[addCreditDebitBalance]=useAddCreditDebitBalanceMutation()
    const isAddOperation = operationType === OPERATIONS.ADD;

    const DesignationValidationSchema = Yup.object().shape({
        amountType: Yup.string()
            .required(commonValidationMessage('Amount Type'))
            .oneOf(['CREDIT', 'DEBIT'], 'Invalid Amount type. Please select either CREDIT or  DEBIT'),
        amount:Yup.number().required(commonValidationMessage("Amount")),
        creditDescription:Yup.string().required(commonValidationMessage("Description"))
    });

    const formik = useFormik({
        initialValues: {
            _id: "",
            amount:'',
            creditDescription:'',
            amountType:''
        },
        validationSchema: DesignationValidationSchema,
        onSubmit: async (values, actions) => {
            const variables = {
                    input: {
                        userId :_id &&  _id,
                        description : values?.creditDescription &&  values?.creditDescription,
                        amount : values?.amount && values?.amount,
                        amountType : values?.amountType && values?.amountType 
                    },
            };
            try {
                const { data } = await addCreditDebitBalance({
                    variables,
                });        
                notification.success({
                    message: isAddOperation ? data.addCreditDebitBalance.message : data.designationUpdate.message,
                });
        
                actions.setSubmitting(false);
                closeModal();
                formik.resetForm()
                refetch();
            } catch (error) {
                notification.error({
                    message: error.message,
                });
                actions.setSubmitting(false);
            }
        },
    });
   
    function handleOk() {        
        formik.submitForm().then(()=>closeModal())
    }
    function handleClose() {
        formik.resetForm();
        closeModal();
    }
    return (
        <Content className='content'>
            <Modal title={ MODULE_TITLES.USER.MANAGE_PAYMENT} visible={isModalVisible} 
            onOk={handleOk}
            onCancel={handleClose}
            okButtonProps={{ loading: formik.isSubmitting, disabled: !(formik.isValid && formik.dirty) }}
             >
                <FormikProvider value={formik} >
                    <Form id='creditBalanceForm' name="add-edit-credit" layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} labelWrap={true}>
                       
                    <Form.Item label="Amount Type"
                                name="amountType"
                                required>
                                <Select
                                    name="amountType"
                                    style={{ minWidth: 200 }}
                                    allowClear
                                    placeholder="Amount Type..."
                                    options={AMOUNT_TYPE}
                                />
                            </Form.Item>

                        <Form.Item label="Amount" name="amount" required>
                            <Input
                                type="number"
                                id="amount"
                                name="amount"
                                placeholder="Add Amount..."
                                prefix={<span/>}
                            />
                        </Form.Item>

                        <Form.Item label="Description" name="creditDescription" required>
                            <Input
                                type="text"
                                id="creditDescription"
                                name="creditDescription"
                                placeholder="Add Description..."
                                prefix={<span/>}
                            />
                        </Form.Item>
                    </Form>
                </FormikProvider>
            </Modal>
        </Content>
    )
}

export default AddCredit