function formatDate(date) {

    //convert utc to local
    let yourDate = new Date(date)

    //format date as per figma
    const formattedDate = yourDate.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'long', year: 'numeric'
    })

    return formattedDate
}

export {
    formatDate
}

export function isValidDate(date) {
    return date instanceof Date && !isNaN(date);
   }