

import React from 'react';
import { Layout, Card, List } from 'antd';
import BreadcrumbComponent from '../../common/Breadcrumb';
import { LOCATIONS } from '../../../config/routeConfig';
import { useCouponCodeDetailsByIdLazyQuery } from '../../../graphql/generated/index.tsx';
import { useLocation, useParams } from 'react-router-dom';
import { MODULE_TITLES } from '../../../config/moduleConfig';
import { ListItem } from '../Product/DetailSections/common';
import { isValidDate } from '../../../helper/DateFormatter';

const { Content } = Layout;

const options = { year: 'numeric', month: 'long', day: 'numeric' };
const breadcrumb_items = [
    { label: MODULE_TITLES.DASHBOARD.ROOT, to: LOCATIONS.DASHBOARD_ROUTE.ROOT },
    { label: MODULE_TITLES.OFFERS_DISCOUNT.LIST, to: LOCATIONS.OFFERS_AND_DISCOUNT_ROUTES.ROOT },
    { label: MODULE_TITLES.OFFERS_DISCOUNT.VIEW }
];
const ViewOfferAndDiscount = () => {
    const params = useParams();
    const location = useLocation()
    const categorysName = location?.state
    const [offerDetailData, setOfferDetailData] = React.useState({})
    /* graphql */
    const [getCouponCodeDetailsByIdLazyQuery, { loading: getCouponCodeDetailsByIdLazyQueryLoading,refetch }] = useCouponCodeDetailsByIdLazyQuery()
    React.useEffect(() => {
        getCouponCodeDetailsByIdLazyQuery({
            variables: {
                id: params.offeranddiscountId
            },
            onCompleted(data) {
                setOfferDetailData(data)
                refetch()
            },     
        })
    }, [])

    const bannerAdvList =  [
        { label: "Category", value: categorysName && categorysName.join(" - ") },
        { label: "Coupon Code", value: offerDetailData?.couponCodeDetailsById?.couponCode },
        { label: "Coupon Status", value: offerDetailData?.couponCodeDetailsById?.couponStatus },
        { label: "Title", value: offerDetailData?.couponCodeDetailsById?.title },
        { label: "Description", value: offerDetailData?.couponCodeDetailsById?.description },
        { label: "Start Date", value: offerDetailData?.couponCodeDetailsById?.startDate && isValidDate(new Date(offerDetailData?.couponCodeDetailsById?.startDate)) ? new Date(offerDetailData?.couponCodeDetailsById?.startDate).toLocaleDateString('en-US', options) : "-" },
        { label: "End Date", value: offerDetailData?.couponCodeDetailsById?.endDate && isValidDate(new Date(offerDetailData?.couponCodeDetailsById?.endDate)) ? new Date(offerDetailData?.couponCodeDetailsById?.endDate).toLocaleDateString('en-US', options) : "-" },
        { label: "Coupon Type", value: offerDetailData?.couponCodeDetailsById?.couponType?.replace(/_/g, " ") },
        { label: "DD Coins", value: offerDetailData?.couponCodeDetailsById?.ddCoins},    
        { label: "Discount In Percentage", value: offerDetailData?.couponCodeDetailsById?.discountPercentage ?
        <>
            {offerDetailData?.couponCodeDetailsById?.discountPercentage } <strong name="%">{"%"} </strong></> : "-" },
        { label: "Discount In Amount", value: offerDetailData?.couponCodeDetailsById?.discountAmount ?
        <><strong name="Rs">{"\u20B9"} </strong>
            {offerDetailData?.couponCodeDetailsById?.discountAmount}</> : "-"},
        { label: "UP To Discount Amount", value: offerDetailData?.couponCodeDetailsById?.uptoDiscountAmount ?
        <><strong name="Rs">{"\u20B9"} </strong>
            {offerDetailData?.couponCodeDetailsById?.uptoDiscountAmount}</> : "-"},     
        { label: "Restrict Number Of Users", value: offerDetailData?.couponCodeDetailsById?.restrictNumberOfUsers },
        { label: "Restrict Users Count", value: offerDetailData?.couponCodeDetailsById?.restrictedUsersCount },
        { label: "Applied Order Amount", value: offerDetailData?.couponCodeDetailsById?.appliedOrderAmount ?
        <><strong name="Rs">{"\u20B9"} </strong>
            {offerDetailData?.couponCodeDetailsById?.appliedOrderAmount }</> : "-"
            },
    ]

    return (
        <Content className='content'>
            <div className='page-header'>
                <h2 className='page-title'>{MODULE_TITLES.OFFERS_DISCOUNT.ROOT}</h2>
                <BreadcrumbComponent breadcrumb_items={breadcrumb_items} />
            </div>
            <div className='content-wrapper'>
                <Card title={MODULE_TITLES.CATEGORY.VIEW} loading={getCouponCodeDetailsByIdLazyQueryLoading} >
                    <List
                        dataSource={bannerAdvList}
                        renderItem={(item) => <ListItem item={item} labelStrong />}
                    />
                </Card>
            </div>
        </Content>

    )
}

export default ViewOfferAndDiscount